.AudioGenerator {
  margin-top: 3.5rem;
  padding-top: 2rem;
  border-top: 1px solid $color-border-bis;
  
  &__Title {
    font-size: 1.6rem;
    font-weight: $weight-bold;
    color: $color-font-main;
    margin-bottom: 1.5rem;
  }
  
  &__Error {
    color: $color-red;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
    background: rgba($color-red, 0.1);
    border-radius: 0.4rem;
  }
  
  &__Form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  &__InputGroup {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    
    label {
      font-size: 1.4rem;
      font-weight: $weight-semi;
      color: $color-font-main;
    }
    
    input, textarea {
      padding: 1.2rem;
      border-radius: 0.8rem;
      background: $color-input-background;
      border: 1px solid $color-border-bis;
      color: $color-font-main;
      font-size: 1.4rem;
      
      &:focus {
        outline: none;
        border-color: $color-green;
      }
      
      &::placeholder {
        color: $color-font-sub;
      }
      
      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
    
    textarea {
      min-height: 10rem;
      resize: vertical;
    }
  }
  
  &__Button {
    align-self: flex-end;
    margin-top: 1rem;
  }
  
  @include breakpoint('mobile') {
    margin-top: 2.5rem;
    
    &__Button {
      align-self: stretch;
    }
  }
}

// Add styles for the loading state in AudioInput
.AudioInput {
  &__Loading {
    width: 40rem;
    border-radius: 0.8rem;
    background: $color-content-background;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33rem;

    &__Content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      gap: 1.5rem;
    }
    
    &__Text {
      color: $color-green;
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
      font-weight: $weight-semi;
    }
  }
  
  @include breakpoint('mobile') {
    &__Loading {
      width: 100%;
      max-width: 55rem;
    }
  }
} 