.ShowcaseImageToImage {
  width: 100%;
  margin-top: 2rem;

  &__Tabs {
    width: 100%;
    border-bottom: 2px solid #2C334F;
    margin-bottom: 0;

    .Tab {
      border-bottom: 0;
      margin-bottom: 0;

      &--selected{

        &:after{
          border-bottom: 2px solid #9b1bdf;
        }
      }
    }
  }

  &__SelectLabel {
    font-weight: $weight-bold;
    font-size: 1.4rem;
    margin-bottom: 1.6rem;
  }

  @include breakpoint('mobile') {
    margin-top: 1.9rem;
    width: calc(100%);

    &__Header {
      flex-direction: column;
      align-items: flex-start;
    }

    &__Subtitle {
      padding-bottom: 1rem;
      margin-bottom: 2rem;
    }

    &__MobileTabs {
      width: 100%;

      .dropdown-icon {
        bottom: 1.4rem;
      }
    }

    .Tabs {
      justify-content: center;

      .Tab {
        padding-left: 2rem;
        padding-right: 2rem;

        &--selected{

          &:after{
            left: 2rem;
            right: 2rem;
          }
        }
      }
    }
  }
}