.AiShowcasesTermsPage {
    margin: 0 auto;
    max-width: 80rem;
    font-size: 1.4rem;
    font-weight: $weight-medium;
    margin-bottom: 3rem;

    h1 {
        font-size: 2.4rem;
        font-weight: $weight-bold;
        margin-bottom: 0.5rem;
        margin-top: 3rem;
    }

    .lastUpdated {
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom: 2rem;
    }

    h2 {
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
        margin-top: 2rem;
    }

    p {
        margin-bottom: 1rem;
    }

    ul {
        margin-bottom: 1rem;
    }

    li {
        margin-bottom: 0.5rem;
        margin-left: 2rem;
        list-style-type: disc;
    }

    br {
        margin-bottom: 1rem;
    }

    strong {
        margin-top: 1rem;
    }
}