.ShowcaseTalkingHead {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &__container {
    display: flex;
    flex-grow: 1;
  }

  &__LeftSide {
    border-right: 1px solid $color-border-bis;
  }

  &__RightSide {
    width: 100%;
  }

  .ErrorMessage {
    color: $color-red;
    font-weight: $weight-semi;
    margin: 3.5rem 4rem 4rem 3.8rem;
  }

  .ResultBox {
    background: #191D29;
    border-radius: 1rem;
    width: 42rem;
    margin: 3.5rem 4rem 4rem 3.8rem;

    &__Idle {
      display: flex;
      padding: 1.4rem 2rem 3rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 25rem;
      color: #3D4463;
      font-weight: $weight-bold;

      .g-icon {
        margin-bottom: 2rem;
      }
    }

    &__Loading {
      position: relative;
      display: flex;
      padding: 1.4rem 2rem 3rem;
      justify-content: center;
      align-items: center;
      height: 25rem;

      &__TopWrapper {
        position: absolute;
        top: 1.4rem;
        right: 2rem;
        text-align: center;
        color: $color-font-main;
        font-weight: $weight-medium;
        font-size: 1.2rem;
      }

      &__CenterWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        color: $color-green;
        font-weight: $weight-medium;
        font-size: 1.4rem;
        gap: 1rem;
      }

      &__BottomWrapper {
        position: absolute;
        bottom: 2.5rem;
        left: 0;
        right: 0;
        text-align: center;
        color: $color-font-sub;
        font-weight: $weight-medium;
        font-size: 1.4rem;
      }
    }

    &__Video {
      position: relative;
      display: flex;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      video {
        width: 100%;
        height: 100%;
        max-height: 40rem;
        border-radius: 0.4rem;
      }
    }

    &__DownloadWrapper {
      display: flex;
      justify-content: flex-end;
      padding: 1rem 1.6rem 1.6rem;

      .g-icon {
        cursor: pointer;
        color: $color-font-sub;

        &:hover {
          color: $color-font-main;
        }
      }
    }
  }

  .MessagesWrapper {
    display: flex;
    flex-direction: column;
  }

  .SidePanel {
    margin-top: 2rem;
    width: 71rem;
    height: fit-content;
    margin-right: 3.8rem;
    margin-bottom: 4rem;
    
    &__ImageInputs {
      display: flex;
      gap: 3.4rem;
    }

    &__Examples {
      display: flex;
      gap: 3.4rem;
    }

    &__ActionButtons {
      display: flex;
      justify-content: space-between;
      margin-left: -0.8rem;
      margin-right: -0.8rem;
      margin-top: 2rem;
      
      &__Button {
        padding: 0.5rem 1rem;
        cursor: pointer;
        flex: 1;
        margin-left: 0.8rem;
        margin-right: 0.8rem;

        &:disabled {
          opacity: 0.5;
          cursor: default;
        }

        &--Primary {
          flex: 3;
        }
      }
    }
    
    &__OutputOptions {
      display: flex;
      align-items: center;
      color: $color-font-main;
      font-weight: $weight-bold;
      cursor: pointer;
      margin-top: 3.5rem;
      
      &.open {
        .g-icon {
          transform: rotate(180deg);
        }
      }

      .g-icon {
        margin-left: 0.7rem;
        fill: #8A8FB5;
      }
    }
    
    &__AudioGenerator {
      margin-top: 2rem;
    }
  }

  .AudioInput, .ImageInput {
    width: 50%;
    background: #1f2231;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &__AudioUploaded {
      max-width: 100%;
      width: 100%;
      height: 100%;
      max-height: 33rem;
      border-radius: 0.8rem;
    }

    &__ImageUploaded {
      max-width: 100%;
      max-height: 33rem;
      border-radius: 0.8rem;
    }

    input {
      width: auto;
      height: 33rem;
      cursor: pointer;
    }

    &__Label {
      flex-direction: column;
      gap: 0.5rem;
    }

    &__LeftBadge {
      position: absolute;
      background: $color-dark-grey;
      color: $color-font-sub;
      font-size: 1.1rem;
      font-weight: $weight-semi;
      padding: 0.3rem 0.6rem;
      border-radius: 0.4rem;
      left: 1rem;
      top: 1rem;
    }
  }

  .SettingsPanel__Description {
    margin-top: 2rem;
    color: $color-font-sub;
    font-size: 1.4rem;
    line-height: 1.6;
  }

  .ShowcaseGallery {
    width: 50%;
  }

  .AudioWarning {
    color: $color-red;
    margin-top: 1.4rem;
    margin-bottom: 1.6rem;
    padding: 0.8rem 1.2rem;
    background-color: rgba($color-red, 0.1);
    border-radius: 1rem;
    display: flex;
    align-items: flex-start;
    font-weight: $weight-medium;
    line-height: 1.2;

  }

  @include breakpoint('mobile') {
    &__container {
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;
    }

    &__LeftSide {
      margin-bottom: 0;
      margin-top: 0;
      border: none;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__RightSide {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .ImageInput {
      &__ImageUploaded {
        width: auto;
      }
    }

    .AudioInput {
      input {
        height: 17rem;
      }
    }

    .SidePanel {
      margin-right: 0;
      width: 100%;
      max-width: 55rem;
      margin-top: 0;
      margin-bottom: 0;
      padding: 2rem;

      &__ActionButtons {
        .Button--medium {
          max-width: 50%;
          min-width: auto;
        }
      }

      &__ImageInputs,
      &__Examples {
        flex-direction: column;
        gap: 2rem;
      }

      .ImageInput,
      .AudioInput,
      .ShowcaseGallery {
        width: 100%;
      }
    }

    .ResultBox {
      margin: 2rem 0 0;
      width: 100%;
      max-width: 42rem;

      &__Video {
        video {
          max-width: 100%;
        }
      }
    }

    .MessagesWrapper {
      display: flex;
      width: 100%;
      flex-direction: column-reverse;
    }
  }
} 