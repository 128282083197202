.UserListRowItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1rem;
  gap: 0.8rem;

  &__More {
    font-size: 1.4rem;
    font-weight: $weight-medium;
    color: $color-font-sub;
    text-decoration: underline;
    margin-left: 1rem;
  }

}