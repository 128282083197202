.EmptyState {
  $self: &;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;

  &.note,
  &.model-detail {
    padding-top: 50px;
    .EmptyState__title {
      color: #8a8fb5;
      margin-bottom: 40px;
    }
  }
  &.api_key {
    padding: 0;
    padding-bottom: 30px;
    margin-top: 0;
    .EmptyState__subtitle {
      color: $color-red;
      text-align: center;
      padding: 0 100px;
      font-size: 16px;
    }
    .EmptyState__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 0;
      &--key {
        font-size: 18px;
        color: $color-green;
      }
    }
  }

  &__title {
    font-size: 21px;
    font-weight: 600;
    color: white;
    line-height: 28px;
  }

  &__subtitle {
    font-size: 15px;
    color: $color-text-dark;
    line-height: 21px;
    margin-bottom: 20px;
    text-align: center;
  }

  &__content {
    font-size: 15px;
    color: $color-text-dark;
    line-height: 21px;
    margin-top: 30px;
    margin-bottom: 40px;
  }
}
