.ProfilePage {
  display: flex;
  flex-direction: column;
  width: 100%;

  .PageTitleBar {
    max-width: 57rem;
  }

  &__Label {
    font-size: 1.6rem;
    font-weight: $weight-bold;
    color: $color-font-main;
    margin-top: 1.6rem;
  }

  &__UserBlock {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    max-width: 57rem;
    position: relative;
    background: #1F2331;
    padding: 2.5rem 2rem;
    margin-top: 3.5rem;
    border-radius: 1rem;

    &__Info {
      margin-left: 1.6rem;
      font-size: 1.4rem;
      font-weight: $weight-medium;

      &__name {
        color: $color-font-main;
      }

      &__email {
        color: $color-font-sub;
        margin-top: 0.4rem;
      }
    }

    &__Edit {
      position: absolute;
      right: 2rem;
      cursor: pointer;
      border-radius: 1rem;
      padding: 0.4rem;

      &:hover {
        background: $color-content-background;
      }
    }
  }

  &__Block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
    max-width: 57rem;
    position: relative;
    background: #1F2331;
    margin-top: 2rem;
    border-radius: 1rem;

    &--last {
      margin-bottom: 6rem;
    }

    &__Title {
      font-size: 1.6rem;
      font-weight: $weight-bold;
      color: $color-font-main;
      margin-bottom: 1.6rem;
    }

    &__Items {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__Item {
      display: flex;
      flex-direction: column;
      font-size: 1.4rem;
      position: relative;
      font-weight: $weight-medium;
      color: white;
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
      padding-left: 2rem;
      padding-right: 2rem;
      border-bottom: 1px solid $color-border-bis;

      &--Header {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        font-size: 1.6rem;
        font-weight: $weight-semi;
      }

      &--Project {
        padding-left: 4rem;
      }

      &--Label {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        font-weight: $weight-bold;
        text-transform: uppercase;
        font-size: 1.2rem;
      }
      &--last {
        border-bottom: none;
      }

      &__Id {
        color: $color-font-sub;
        margin-top: 0.4rem;
        font-size: 1.4rem;
      }

      &__Id, &__Name {
        padding-right: 6rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__Edit {
        position: absolute;
        right: 2rem;
        cursor: pointer;
        border-radius: 1rem;
        padding: 0.4rem;

        &:hover {
          background: $color-content-background;
        }
      }
    }
  }


}