.BillingPage {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__content {
    margin-top: 35px;
  }

  &__tab-content {
    padding-top: 50px;
    & .EmptyState {
      margin-top: 0;
      padding-top: 0;
      &__title {
        color: #8a8fb5;
      }
    }
  }
  &__text {
    margin-left: 15px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: $weight-bold;
  }
  &__balance {
    margin-left: 15px;
    margin-bottom: 45px;
    &--title {
      color: #8a8fb5;
    }
    &--credit {
      font-size: 28px;
      font-weight: $weight-bold;
      margin-top: 10px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 65rem;
    background: #1f2331;
    border-radius: 1rem;
    padding: 20px 20px;
    margin-bottom: 20px;
    &--overview-title {
      margin-top: 10px;
      font-size: 16px;
      font-weight: $weight-bold;
    }
    &--overview-description {
      color: #8a8fb5;
      margin-top: 20px;
      max-width: 450px;
      line-height: 18px;
    }
    &--overview-status {
      color: #18c99d;
      font-weight: $weight-semi;
      font-size: 13px;
      margin-top: 30px;
      margin-bottom: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &.red {
        color: $color-red;
      }
      & svg {
        margin-right: 5px;
      }
    }
    &--header {
      display: flex;
      width: 100%;
      align-items: flex-end;
      justify-content: space-between;

      .Button {
        width: 18rem;
        margin-bottom: 2px;
      }
      &--left {
        display: flex;
        flex-direction: column;
        &--top {
          font-size: 14px;
          font-weight: $weight-bold;
          padding: 10px 15px;
          border-radius: 4px;
          margin-bottom: 60px;

          &.purple {
            color: $color-purple;
            background: rgba($color-purple, 0.15);
          }

          &.blue {
            color: $color-blue-usdc;
            background: rgba($color-blue-usdc, 0.15);
          }

          &.orange {
            color: $color-orange;
            background: rgba($color-orange, 0.15);
          }
        }
      }
      &--right {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }
    }
    &--content {
      color: #8a8fb5;
      font-size: 13px;
      display: flex;
      align-items: center;
    }
    &--hr {
      // border-top: 1px solid #3d4463;
      margin-right: 10px;
      height: 1px;
      width: 100%;
      background-color: #3d4463;
    }
    &--icon-wrap {
      display: flex;
    }
    &--icon {
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.tfuel-icon, &.usdc-icon {
        width: auto;
      }
    }
    &--status {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $color-green;
      border-radius: 10px;
      padding: 0 10px;
      &:hover {
        background-color: rgba($color-green, 0.1);
      }
    }
    &--header-logo {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      &.tfuel {
        background-image: url("../../../../assets/icons/icon-tfuel@2x.png");
        height: 30px;
        width: 30px;
      }
      background-repeat: no-repeat;
      &.usdc {
        background-image: url("../../../../assets/icons/icon-usdc.png");
        height: 30px;
        width: 30px;
      }
    }
    &--payment-title {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: $weight-bold;
      margin-left: 15px;
    }
  }

  &__table {
    width: 100%;
    font-size: 14px;
    color: #636b91;
    font-weight: $weight-medium;
    &--header {
      text-align: left;
      font-weight: $weight-medium;
    }
    &--header-row {
      display: flex;
      height: 50px;
      border-bottom: 1px solid #24283b;
    }
    &--row {
      display: flex;
      height: 50px;
      border-bottom: 1px solid #24283b;
      & .title {
        color: #ffffff;
      }
      a {
        display: flex;
        align-items: center;
        flex: 1;
        flex-basis: 20%;
        // padding-left: 24px;
      }
    }
    &--cell {
      flex: 1;
      display: flex;
      align-items: center;
      &.invoice {
        flex-basis: 20%;
      }
      &.status {
        text-transform: capitalize;
        &.failed {
          color: #ff3056;
        }
        &.paid {
          color: #18c99d;
        }
        &.refund {
          color: $color-orange;
        }
      }
      &.amount {
        flex-direction: column;
        justify-content: center;
      }
      &-tfuel {
        font-size: 12px;
      }
    }
  }
  &__buttons {
    margin-top: 20px;
    & .Button {
      margin-right: 15px;
      width: 200px;
    }
  }

  &__pagination {
    margin: 30px 0;
    & .pagination .btn {
      font-size: 16px;
    }
  }

  // Add mobile styles at the bottom
  @include breakpoint('mobile') {
    &__select--label {
      margin: 3rem 0 1rem;
      text-align: left;
      font-size: 1.4rem;
      font-weight: $weight-semi;
      color: #8a8fb5;
    }

    .SelectInput {
      width: 100%;
    }

    &__content {
      margin-top: 2rem;
    }

    &__tab-content {
      padding-top: 3rem;
    }

    &__text {
      margin: 0 0 2rem;
      font-size: 1.4rem;
    }

    &__balance {
      margin: 0 0 3rem;
      padding: 2rem;
      background: #1f2331;
      border-radius: 1rem;

      &--title {
        font-size: 1.4rem;
        
        &-org {
          display: block;
          margin-top: 0.5rem;
        }
      }

      &--credit {
        font-size: 2.4rem;
      }
    }

    &__block {
      margin: 0 0 2rem;
      width: 100%;
      max-width: none;

      &--header {
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;

        &--left {
          width: 100%;

          &--top {
            margin-bottom: 3rem;
          }
        }

        &--right {
          width: 100%;

          .Button {
            width: 100%;
            margin: 0;
          }
        }

        .Button {
          width: 100%;
        }
      }

      &--icon-wrap {
        margin: 1rem 0;
      }
    }

    &__buttons {
      margin: 2rem 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .Button {
        width: 100%;
        margin: 0;
      }
    }

    &__table {
      overflow-x: auto;
      margin: 0;
      padding: 0;
      font-size: 13px;

      &--header-row,
      &--row {
        min-width: unset;
        width: 100%;
      }

      &--header {
        &-row {
          display: flex;
          padding: 0 2rem;
        }
      }

      &--cell {
        padding: 1.2rem 0;

        &.invoice {
          flex: 0 0 25%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.type {
          flex: 0 0 20%;
        }

        &.status {
          flex: 0 0 20%;
        }

        &.amount {
          flex: 0 0 25%;
        }

        &.view {
          flex: 0 0 10%;
        }

        &.created {
          display: none;
        }

        &-tfuel {
          font-size: 11px;
        }
      }
    }

    &__pagination {
      margin: 2rem 0;
      display: flex;
      justify-content: center;

      .pagination {
        .btn {
          font-size: 1.4rem;
          padding: 0.8rem 1.2rem;
        }
      }
    }
  }
}
