.KnowledgeDocument {
    background-color: #1F2331;
    border-radius: 6px;
    margin: 20px 0;
    padding: 2rem;

    &__main {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0px;
    }

    &__info {
        flex: 1;
        padding-right: 15px;
    }

    &__property {
        margin-bottom: 8px;
        display: flex;
        align-items: flex-start;

        .property-label {
            font-weight: $weight-semi;
            color: #636B91;
            min-width: 100px;
            margin-right: 10px;
        }

        .property-value {
            color: #8a8fb5;
        }
    }

    &__actions {
        display: flex;
        gap: 8px;

    }

    &__metadata {
        &-toggle {
            padding: 10px 15px;
            background-color: #1f2331;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 1rem;
            color: #8a8fb5;
            font-weight: 600;
            border-radius: 6px;
            margin-top: 1rem;
        }

        &-content {
            position: relative;
        }

        textarea {
            margin-top: 1rem;
            width: 100%;
            min-height: 120px;
            max-height: 300px;
            padding: 10px 10px 45px;
            background-color: #252936;
            border: 1px solid #252936;
            border-radius: 4px;
            color: #ffffff;
            font-size: 14px;
            resize: vertical;

            &:focus {
                border-color: #18c99d;
                outline: none;
            }
        }

        &-actions {
            position: absolute;
            bottom: 1.2rem;
            right: 2.2rem;
            display: flex;
            gap: 0.5rem;
            padding: 4px;
            border-radius: 4px;

            &--icon {
                cursor: pointer;

                svg {
                    width: 1.8rem;
                    height: 1.8rem;
                }
            }

            .cancel {
                margin-right: 10px;
                color: #8A8FB5;

                &:hover {
                    color: #7A7FA5;
                }
            }

            .submit {
                color: #18C99D;

                &:hover {
                    color: #08B98D;
                }
            }
        }
    }

    &__error {
        color: #ff1744;
        margin: 8px 0;
        font-size: 12px;
    }

    @include breakpoint('mobile') {
        &__main {
            flex-direction: column;
        }

        &__actions {
            margin-top: 15px;
        }

        &__property {
            flex-direction: column;
            align-items: flex-start;

            .property-label {
                min-width: auto;
                margin-bottom: 4px;
            }
        }
    }
}