.MemberItem {
  display: flex;
  position: relative;
  align-items: center;
  width: 36rem;
  padding: 1.5rem 0;
  border-bottom: 1px solid $color-border;
  cursor: default;
  transition: background-color 0.3s;
  margin: 0 2rem;

  &__Left {
    display: flex;
    align-items: center;
  }

  &__Info {
    display: flex;
    flex-direction: column;
    margin-left: 1.1rem;
    font-size: 1.4rem;
    font-weight: $weight-medium;
  }

  &__Name {
    color: $color-font-main;
    text-transform: capitalize;
  }

  &__Email {
    color: $color-font-sub;

  }

  &__Right {
    position: absolute;
    right: 0;
    //justify-self: flex-end;
    cursor: pointer;
    .g-icon {
      fill: #636b91;
    }

    &:hover {
      .g-icon {
        svg {
          fill: $color-font-main;

        }
      }

    }

  }

  @include breakpoint('mobile') {
    width: 100%;
    margin: 0;
    flex-direction: column;

    &__Left {
      display: flex;
      align-items: center;
      width: 100%;

    }

    &__Right {
      position: static;
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100%;
      margin-top: 1rem;
      flex-grow: 1;
    }


  }
}

.MemberItemsList {
  display: flex;
  flex-wrap: wrap;
  margin-left: -2rem;
  margin-right: -2rem;

  @include breakpoint('mobile') {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
    width: 100%;
  }
}
