.step-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}

.step-wrap {
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.step-label {
  font-size: 14px;
  // margin-bottom: 10px;
  text-align: center;
  color: #8a8fb5;
  position: absolute;
  top: 20px;
  width: 100px;
  &.active {
    // color: white;
  }
}

.step-button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #8a8fb5;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  &.active {
    background-color: #18c99d;
  }
}

.step-separator {
  margin-bottom: 10px;
  width: 250px;
  height: 2px;
  background-color: #3d4463;
  transition: background-color 0.3s ease;
  &.active {
    // background-color: white;
  }
  @include breakpoint("mobile") {
    width: 100px;
  }
}
