.DepositTfuelModal {
  width: 100%;
  max-width: 55rem;
  padding: 0 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .warning {
    margin-bottom: 2rem;
  }

  .error {
    color: $color-red;
    font-weight: $weight-semi;
    margin-bottom: 2rem;
  }

  .payment-title {
    font-weight: $weight-bold;
    color: $color-white;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 3rem;
    font-size: 3rem;
  }

  .section-title {
    font-size: 1.4rem;
    font-weight: $weight-semi;
    color: $color-white;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .address-qr {
    width: 15rem;
    height: 15rem;
    margin-bottom: 3.5rem;
  }

  .deposit-address-label {
    font-size: 1.1rem;
    font-weight: $weight-semi;
    color: $color-font-sub;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.275rem;
    margin-bottom: 1.4rem;
  }

  .deposit-address-value {
    font-size: 1.3rem;
    font-weight: $weight-semi;
    color: $color-white;
    text-align: center;
    margin-bottom: 1.7rem;
  }

  .copy-btn {
    margin-bottom: 4rem;
    background: #282d42;
    color: $color-font-sub;
    
    svg {
      fill: $color-font-sub;
    }
  }

  .allow-time-text {
    font-size: 1.4rem;
    color: $color-font-sub;
    font-weight: $weight-semi;
    text-align: center;
    line-height: 1.4rem;
    max-width: 44rem;
    margin-bottom: 3.5rem;
  }

  .disclaimers {
    display: flex;
    background-color: rgba($color-red, 0.15);
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    max-width: 44rem;
    font-size: 1.2rem;
    color: $color-red;
    border-radius: 0.8rem;
    text-align: center;


    .disclaimer-title {
      font-weight: $weight-bold;
      margin-bottom: 1rem;
      letter-spacing: 0.02rem;
    }

    .disclaimer-desc {
      font-weight: $weight-semi;
      text-align: center;
    }
  }

  .cancel-button {
    text-align: center;
    cursor: pointer;
    font-weight: $weight-semi;
    color: $color-font-sub;
  }

  .footer {
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 1px solid #1a1c2a;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .Button {
      width: 18rem;
    }
  }

  .tfuel-converter {
    display: flex;
    background-color: rgba($color-grey-ui, 0.15);
    justify-content: center;
    flex-direction: column;
    padding: 2rem 2rem 1.5rem 2rem;
    max-width: 44rem;
    width: 100%;
    font-size: 1.4rem;
    color: $color-font-sub;
    border-radius: 0.8rem;
    margin-bottom: 3.5rem;

    .tfuel-converter-title {
      font-weight: $weight-bold;
      margin-bottom: 1.5rem;
      text-align: center;
      letter-spacing: 0.02rem;
    }

    .input-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;

      .input-title {
        margin-right: 2rem;
        font-weight: $weight-semi;
      }

      .currency-symbol {
        font-size: 2rem;
        margin-right: 0.5rem;
      }

      .usd-input {
        background: transparent;
        border: none;
        border-bottom: 1px solid $color-font-sub;
        color: $color-font-sub;
        width: 8rem;
        text-align: center;
        font-size: 2rem;
        padding: 0.2rem;

        &:focus {
          outline: none;
        }
      }
    }

    .conversion-result {
      display: flex;
      align-items: center;
      justify-content: center;
      
      .result-title {
        margin-right: 2rem;
        font-weight: $weight-semi;
      }

      .asterisk {
        margin-left: 0.2rem;
      }
    }

    .conversion-note {
      margin-top: 2rem;
      font-size: 1.2rem;
      color: $color-font-sub;
      font-weight: $weight-semi;
      text-align: center;
    font-style: italic;
    }
  }

  @include breakpoint('mobile') {
    max-width: none;
    .copy-btn {
      width: auto;
    }

    &.modal {
      align-items: center;
    }
  }
}
