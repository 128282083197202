.CreateRAGChatbotModal {
  background-color: #191d29;
  border-radius: 20px;
  padding: 0 4rem 2rem 4rem;
  .modal-header {
    margin-top: 50px;
  }
  .modal-content {
    margin-bottom: 20px;
  }
  &__row {
    width: 100%;
    display: flex;
    margin: 5px 0;
  }
  &__input {
    width: 100%;
    height: 40px;
    background-color: #191d29;
    border-radius: 6px;
    border: 1px solid #3d4463;
    outline: 0;
    color: #ffffff;
    padding: 0 20px;
    font-family: $font-family;
    &::placeholder {
      color: #636b91;
    }
    &.pwd {
      margin-left: 10px;
      flex: 1;
    }
    &:disabled {
      cursor: not-allowed;
      color: #636b91;
    }
  }
  &__textarea {
    width: 100%;
    height: 111px;
    background-color: #191d29;
    border-radius: 6px;
    border: 1px solid #3d4463;
    outline: 0;
    color: #ffffff;
    padding: 10px;
    resize: none;
    &::placeholder {
      color: #636b91;
    }
    &--metadata {
      height: 80px;
      margin-bottom: 20px;
    }
  }
  &__select-wrap {
    flex: 1;
    padding-right: 20px;
    &:last-child {
      padding-right: 0;
    }
    &.endpoint {
      flex-basis: 80%;
    }
    &.access {
      flex-basis: 20%;
    }
    &.slider {
      flex-basis: 33%;

      .SelectInput {
        width: auto;
      }
    }
    &.name {
      flex-basis: 60%;
    }
  }
  &__select-label {
    margin: 10px 0;
    color: #8a8fb5;
    display: flex;
  }
  &__slider-wrap {
    height: 40px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    background-color: rgba(#3d4463, 0.2);

    input {
      width: 40px;
      height: 3.4rem;
      text-align: center;
      border-radius: 0.6rem;
      background: transparent;
      border: none;
      color: $color-green;
      font-size: 1.4rem;
      font-family: "Gilroy", -apple-system, system-ui, BlinkMacSystemFont,
        "Helvetica Neue", "Helvetica", sans-serif;
      font-weight: $weight-medium;

      &:focus {
        outline: none;
        border: 1px solid rgba($color-green, 0.6);
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    input[type="range"] {
      -webkit-appearance: none;
      appearance: none;
      background: transparent;
      cursor: pointer;
      flex: 1;

      &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        background: transparent;
        height: 0.2rem;
      }
      &::-moz-range-track {
        -moz-appearance: none;
        background: transparent;
        height: 0.2rem;
      }
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        background: $color-green;
        height: 1.5rem;
        width: 1.5rem;
        border: none;
        border-radius: 50%;
        margin-top: -0.6rem;
      }
      &::-moz-range-thumb {
        -moz-appearance: none;
        border: none;
        border-radius: 50%;
        background-color: $color-green;
        height: 1.5rem;
        width: 1.5rem;
      }
      &:focus {
        outline: none;
        border: none;
      }
    }
  }
  &__select-content {
    display: flex;
    align-items: center;
    height: 40px;
  }
  &__pwd-placeholder {
    margin-left: 10px;
    color: #636b91;
    border: 1px solid #3d4463;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    display: flex;
    align-items: center;
  }
  &__price-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #8a8fb5;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
    &--price {
      margin-top: 5px;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
    }
  }
  &__error {
    font-size: 16px;
    height: 16px;
    font-weight: $weight-semi;
    color: $color-red;
    margin: 15px 0;
    text-align: center;
    & a {
      // color: #ffffff;
      color: #18c99d;
      font-weight: $weight-bold;
      cursor: pointer;
    }
  }
  &__switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 22px;
    &--wrap {
      display: flex;
      margin-top: 35px;
    }
    &--label {
      flex: 1;
      margin: 0 20px;
      line-height: 20px;
      font-size: 16px;
      color: #8a8fb5;
    }
  }

  &__switch-input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1f2331;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  &__switch-slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: #8a8fb5;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  &__switch-input:checked + .CreateRAGChatbotModal__switch-slider {
    background-color: rgba(#18c99d, 0.1);
  }

  &__switch-input:focus + .CreateRAGChatbotModal__switch-slider {
    box-shadow: 0 0 1px rgba(#18c99d, 0.1);
  }

  &__switch-input:checked + .CreateRAGChatbotModal__switch-slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    background-color: #18c99d;
  }
}
