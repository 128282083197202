.SelectOrgPage {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  width: 60rem;

  &__List {
    margin-top: 4rem;
  }


  &__Logout {
    width: 22rem;
    align-self: center;
    margin-top: 16rem;
  }

  &__Skip {
    margin-top: 16rem;
    align-self: center;
  }

}