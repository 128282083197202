.AppShell {
  &__Body {
    display: flex;
    width: 100%;
  }
}

.AppSideMenu {
  display: flex;
  flex-direction: column;
  width: 74px;
  padding-top: 30px;
  border-right: 1px solid #24283b;

  .separator {
    height: 38px;
  }

  &__Item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;

    &.active {
      border-left: 1px solid $color-green;
    }

    &__icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 48px;
      height: 48px;
      background: #1f2331;
      border-radius: 10px;

      &:hover {
        background: $color-green;

        .g-icon {
          fill: black;
        }
      }

      &.active {
        cursor: default;
        background: $color-green;

        .g-icon {
          fill: black;
        }
      }

      .g-icon {
        fill: #636b91;

        &.icon-video {
          width: 32px;
          height: 32px;

          svg {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }

  &__SubItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-bottom: 35px;
    cursor: pointer;

    &.active {
      border-left: 1px solid $color-green;
    }

    &:hover {
      .g-icon {
        fill: $color-green;
      }
    }

    &.active {
      cursor: default;

      .g-icon {
        fill: $color-green;
      }
    }

    .g-icon {
      fill: #636b91;

      &.icon-video,
      &.icon-settings {
        width: 32px;
        height: 32px;

        svg {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
