
@mixin flex($direction: row, $justify: flex-start, $align: center, $wrap: nowrap) {
  display: flex;
  flex-flow: $direction $wrap;
  justify-content: $justify;
  align-items: $align;
  .space-left {
    margin-left: auto;
  }
  .space-right {
    margin-right: auto;
  }
}

@mixin row($justify: flex-start, $align: flex-start, $wrap: nowrap) {
  @include flex(row, $justify, $align, $wrap);
}

@mixin rowCenter($justify: center, $align: center, $wrap: nowrap) {
  @include row($justify, $align, $wrap);
}
@mixin rowAlignCenter($justify: flex-start, $align: center, $wrap: nowrap) {
  @include row($justify, $align, $wrap);
}
@mixin rowStretch($justify: flex-start, $align: stretch, $wrap: nowrap) {
  @include row($justify, $align, $wrap);
}
@mixin rowSplit($justify: space-between, $align: center, $wrap: nowrap) {
  @include row($justify, $align, $wrap);
}

@mixin column($justify: flex-start, $align: center, $wrap: nowrap) {
  @include flex(column, $justify, $align, $wrap);
}

@mixin columnStretch($justify: flex-start, $align: stretch, $wrap: nowrap) {
  @include column($justify, $align, $wrap);
}
@mixin columnCenter($justify: center, $align: center, $wrap: nowrap) {
  @include column($justify, $align, $wrap);
}
@mixin columnAlignCenter($justify: flex-start, $align: center, $wrap: nowrap) {
  @include column($justify, $align, $wrap);
}


@mixin backgroundImage($url: '', $size: contain, $xpos: center, $ypos: center) {
  background-image: url($url);
  background-position: $xpos $ypos;
  background-repeat: no-repeat;
  background-size: $size;
}

@mixin absoluteCover() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}



@mixin noselect{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}



@mixin hideColumn() {
  display:none;
        width:0;
        height:0;
        opacity:0;
        visibility: collapse;
}

