.ServicePage {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: rgba($color-content-background, 0.08);
  min-height: calc(100vh - 70px);
  width: calc(100% - 235px);
  position: relative;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 55px 55px 0;
  }

  &__title {
    font-size: 30px;
    font-weight: bold;
    line-height: 45px;
    text-align: center;
    margin-bottom: 12px;
    color: #ffffff;
  }

  &__Empty {
    font-size: 1.5rem;
    color: $color-font-sub;
    font-weight: $weight-medium;
    margin-top: 4rem;
    line-height: 2rem;
  }

  &__search {
    display: flex;

    @include breakpoint("mobile") {
      display: none;
    }

    input {
      font-size: 1.3rem;
      padding: 0.5rem 0.5rem;
      background-color: #191d29;
      color: white;
      border: none;
      width: 170px;
      border-radius: 5px 0 0 5px;

      &:focus {
        outline: 0;
      }
      &[disabled] {
        background-color: #191d29;
      }
      &::placeholder {
        color: #8a8fb5;
      }
    }
    &--button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: #191d29;
      border-radius: 0 5px 5px 0;
      width: 25px;
      &:hover {
        background: rgba(green, 0.2);
      }
    }
  }

  &__Error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
    color: $color-font-sub;
    font-weight: $weight-semi;
    margin-top: 6rem;
    font-size: 1.5rem;

    &--red {
      color: $color-red;
    }

    &__Buttons {
      display: flex;
      gap: 5rem;
      margin-top: 2rem;
    }
  }

  .ServiceLoader {
    margin-top: 6rem;
  }

  & .EmptyState {
    margin-top: 13rem;
    &.project-api-keys {
      margin-top: 0;
      padding: 20px 0;
    }
  }

  @include breakpoint("mobile") {
    min-height: calc(100vh - 120px);
    height: 100%;
    width: 100%;

    &__content {
      padding: 2rem;
    }
  }
}
