:root {
  --toastify-color-dark: #ff0000;
}

$color-background: #131722;
$color-content-background: #3D4463;

$color-text-dark: #8A8FB5;
$color-text-light: white;

$color-green: #18C99D;
$color-red: #FF2685;
$color-dark-grey: #191D29;
$color-white: #ffffff;
$color-orange: #ff9113;
$color-purple: #7C75FF;
$color-blue-usdc: #2775CA;
$color-border: #24283B;
$color-border-bis: #2C334F;
$color-light: #8A8FB5;

$modal-border-radius: 6px;

$color-input-background: transparent;
$color-input-focus: $color-green;

$color-input-border: $color-border;

$color-input-text: white;
$color-font-main: white;
$color-font-sub: $color-text-dark;

$color-input-placeholder: $color-text-dark;

$color-green-hover: #1BE6B3;
$color-grey-ui: #5A6285;
$color-input-background-alt: #161B26;
$color-text-sub: #636B91;
$color-hover: #2C334F;
$color-overlay: rgba(5, 6, 10, 0.92);
$color-modal: $color-dark-grey;


$color-toast-success: #45BFA0;
$color-toast-error: #FF3056;

