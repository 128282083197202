.LandingPageTVA {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: calc(1280px + 24px);
    padding-left: 12px;
    padding-right: 12px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;

    @include breakpoint("mobile") {
      padding-top: 0;
    }
  }

  &__pitch {
    font-weight: bold;
    font-size: 64px;
    line-height: 77px;
    text-align: center;

    @include breakpoint("mobile") {
      font-size: 22px;
      line-height: 26px;
    }
  }

  &__powered-by {
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 23px;

    @include breakpoint("mobile") {
      height: 15px;
      margin-top: 15px;
    }
  }

  &__what {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: $color-text-dark;
    text-align: center;
    max-width: 546px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;

    @include breakpoint("mobile") {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__steps {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 70px;
    max-width: 680px;
    width: 100%;

    .Step {
      flex: 1;
    }

    @include breakpoint("mobile") {
      margin-top: 45px;
    }
  }
}

.LiveDemoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  width: 100%;
  margin-bottom: 70px;

  &__title {
    font-size: 38px;
    font-weight: bold;
    line-height: 45px;
    text-align: center;
    margin-bottom: 12px;

    @include breakpoint("mobile") {
      font-size: 28px;
      line-height: 33px;
      margin-bottom: 4px;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    margin-bottom: 34px;
    color: $color-text-dark;
  }

  @include breakpoint("mobile") {
    max-width: calc(100vw - 24px);
  }
}

.LiveDemoCard {
  display: flex;
  flex-direction: column;
  max-width: 680px;
  width: 100%;
  border-radius: 20px;
  border: 1px solid $color-border;
}

.LiveDemoCardStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 44px;
  border-bottom: 1px solid $color-border;

  &__number {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.8px;
    line-height: 15px;
    color: $color-green;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 20px;
  }

  &__description {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 30px;
    text-align: center;
    color: $color-text-dark;
    max-width: 486px;
  }

  &__code {
    width: 100%;
    margin-bottom: 45px;

    [contenteditable="true"] {
      color: $color-text-dark;
      border: 0;
      outline: none;
    }

    @include breakpoint("mobile") {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  &__line {
    height: 1px;
    width: 100%;
    background-color: $color-border;
  }

  &__status {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: $color-text-dark;
  }

  .Button {
    min-width: 110px;
    margin-bottom: 28px;
  }
}

.URLCard {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #191d29;
  border-radius: 6px;
  color: #8a8fb5;
  font-family: monospace, monospace;
  min-height: 40px;
  text-align: center;
  width: 100%;
  margin-bottom: 45px;
  line-break: anywhere;
  padding: 12px;

  &--green {
    color: $color-green;
    background-color: transparentize($color-green, 0.9);
  }

  &--red {
    color: $color-red;
    background-color: transparentize($color-red, 0.9);
  }
}

.PricingSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  width: 100%;
  margin-bottom: 70px;

  @include breakpoint("mobile") {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__title {
    font-size: 38px;
    font-weight: bold;
    line-height: 45px;
    text-align: center;
    margin-bottom: 12px;

    @include breakpoint("mobile") {
      font-size: 28px;
      line-height: 33px;
      margin-bottom: 4px;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    margin-bottom: 12px;
    color: $color-text-dark;
    display: flex;
    align-items: center;

    img {
      height: 16px;
      margin-left: 6px;
      margin-right: 4px;
    }
  }

  &__cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1100px;
    margin-top: 22px;
    flex-wrap: wrap;


    @include breakpoint("mobile") {
      flex-direction: column;

      .PricingCard {
        margin-bottom: 17px;
      }
    }
  }

  &__asterisks {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    color: $color-text-dark;
    margin-top: 30px;
    width: 50%;
    max-width: 1100px;
    // text-align: center;

    @include breakpoint("mobile") {
      margin-top: 10px;
      padding: 0 20px;
    }

    &--icon {
      font-size: 14px;
      margin-top: 2px;
      margin-right: 5px;
    }
    & div {
      font-size: 14px;
    }
    &--text {
      margin-top: 10px;
    }
  }
}

.PricingCard {
  background-color: #191d29;
  border-radius: 30px;
  padding: 40px;
  transition: background-color 0.5s ease-in-out;
  will-change: background-color;
  min-width: 300px;

  &__title {
    font-size: 26px;
    font-weight: bold;
    line-height: 33px;
    color: white;
    margin-bottom: 30px;
    max-width: 204px;
    //height: 66px;
  }

  &__price {
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
    color: $color-green;
    margin-bottom: 9px;

    .tfuel {
      margin-left: 6px;
      color: $color-text-dark;
    }
  }

  &__price-subtitle {
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
    color: $color-text-dark;
    margin-bottom: 30px;
  }

  &__product {
    font-size: 17px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 20px;
  }

  &__savings {
    font-size: 14px;
    font-weight: bold;
    line-height: 26px;
    color: $color-green;
    margin-bottom: 30px;
  }

  &__tfuel-discount {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: $color-text-dark;
    margin-top: 36px;

    img {
      height: 16px;
      width: 16px;
      margin-right: 6px;
    }
  }

  .Button {
    width: 100%;
  }

  &:hover {
    background-color: transparentize($color-green, 0.92);

    .Button {
      background-color: $color-green;
    }
  }
}

.SamplePricingSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  width: 100%;
  margin-bottom: 70px;

  &__title {
    font-size: 38px;
    font-weight: bold;
    line-height: 45px;
    text-align: center;
    margin-bottom: 12px;

    @include breakpoint("mobile") {
      font-size: 28px;
      line-height: 33px;
      margin-bottom: 4px;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    margin-bottom: 34px;
    color: $color-text-dark;

    @include breakpoint("mobile") {
      margin-bottom: 17px;
    }
  }

  &__cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1100px;

    @include breakpoint("mobile") {
      flex-direction: column;

      .PricingCard {
        margin-bottom: 17px;
      }
    }
  }

  &__equals-icon {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &__plus-icon {
    margin-left: 18px;
    margin-right: 18px;
    width: 30px;
    height: 30px;

    @include breakpoint("mobile") {
      height: 16px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  &__estimated-title {
    font-weight: 600;
    font-size: 14px;
    color: $color-text-dark;
    line-height: 17px;
    margin-bottom: 12px;
  }

  &__estimated-cost {
    font-weight: bold;
    font-size: 24px;
    color: $color-green;
    line-height: 28px;
  }

  &__enable-edge-title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: $color-text-dark;
    line-height: 17px;
    margin-top: 60px;

    img {
      height: 16px;
      width: 16px;
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  &__enable-edge-cost {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 45px;
    color: $color-green;
    line-height: 55px;
    margin-top: 12px;

    .tfuel {
      margin-left: 6px;
      color: $color-text-dark;
      font-size: 38px;

      @include breakpoint("mobile") {
        margin-left: 0;
        font-size: 24px !important;
      }
    }

    @include breakpoint("mobile") {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 36px;
      line-height: 43px;
    }
  }

  &__enable-edge-savings {
    font-weight: 600;
    font-size: 16px;
    color: white;
    line-height: 19px;
    margin-top: 5px;
  }

  &__cta-button {
    margin-top: 50px;
    width: 220px;

    &.mobile-only {
      display: none;
      width: 100%;
    }

    @include breakpoint("mobile") {
      display: none;

      &.mobile-only {
        display: block;
      }
    }
  }
}

.SamplePricingCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: #191d29;
  border-radius: 30px;
  padding-top: 55px;
  padding-bottom: 55px;

  @include breakpoint("mobile") {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  &__title {
    font-size: 18px;
    font-weight: bold;
    color: white;
    line-height: 24px;
  }

  &__subtitle {
    font-size: 12px;
    font-weight: 600;
    color: #8a8fb5;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    line-height: 22px;
  }

  &__price {
    font-size: 16px;
    font-weight: bold;
    color: $color-green;
    line-height: 22px;
  }
}
