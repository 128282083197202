.g-scrollbars {
  flex: 1;
  .g-scrollbar-container {
    &.safe-center {
      @include column($justify: flex-start, $align: stretch);
      .safe-center-container {
        margin: auto;
        width: 100%;
      }
    }
  }
  .scrollbar {
    
    &.vertical {
      position: absolute;
      width: .6rem;
      right: .2rem;
      bottom: 0;
      top: 0;
      & > * {
        background-color: $color-grey-ui !important;
        border-radius: .3rem !important;
        width: 100%;
        min-height: 3rem;
      }
    }
  }
}