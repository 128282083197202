.g-price {
  @include row($align: center);

  .price-amount {
    position: relative;
    @include row($align: center);
  }
  .discount-amount {
    @include row($align: center);
    margin-left: 2rem;
    color: $color-white;
  }

  .price-slash {
    display: none;
  }

  &.discounted {
    .price-amount {
      .price-value,
      .g-icon {
        filter: grayscale(1);
        opacity: 0.50;
      }
      .price-slash {
        display: block;
        position: absolute;
        background: $color-red;
        height: 1.5px;
        top: 50%;
        right: -10%;
        left: -10%;
      }
    }
  }

  &.sub {
    .price-amount {
      color: $color-font-sub;
    }
  }

  &.green {
    .price-amount {
      color: $color-green;
    }
  }

  &.red {
    .price-amount {
      color: $color-red;
    }
  }


  &.xs {
    font-size: 1.2rem;
    font-weight: $weight-semi;
    .g-icon {
      margin-right: .4rem;
    }
  }

  &.s{
    .g-icon {
      margin-right: .4rem;
    }
  }

  &.m {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: $weight-semi;
    .g-icon {
      width: 1.6rem;
      height: 1.6rem;
      margin-right: #{2rem * .4};
    }
  }

  &.l {
    font-size: 1.4rem;
    font-weight: $weight-bold;
    .g-icon {
      width: #{1.4rem * .85};
      height: #{1.4rem * .85};
      margin-right: #{1.4rem * .35};
    }
  }

  &.xl {
    font-size: 3.2rem;
    line-height: 3.2rem;
    font-weight: $weight-bold;
    .g-icon {
      top: 0rem;
      width: #{1.6rem * 1};
      height: #{1.6rem * 1};
      margin-right: #{1.6rem * .4};
    }
  }

  .price-tag {
    position: relative;
    top: -.2rem;
    font-size: 1.2rem;
    color: $color-font-sub;
    margin-left: 1rem;
    font-weight: $weight-semi;
  }
  
}
