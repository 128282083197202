.SignUpPage {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: calc(1280px + 24px);
    padding-left: 12px;
    padding-right: 12px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 90px;
    padding-bottom: 100px;
  }

  &__title {
    font-size: 30px;
    font-weight: bold;
    line-height: 45px;
    text-align: center;
    margin-bottom: 12px;
    color: #ffffff;
  }

  .SignUpForm {
    width: 42rem;
    margin-top: 3rem;

    @include breakpoint("mobile") {
      min-width: unset;
      width: calc(100vw - 24px);
    }

    .Button {
      margin-left: 2rem;
      margin-right: 2rem;
      margin-top: 2rem;

    }
  }

  .g-recaptcha {
    margin: 8px auto 20px;
  }

  &__already-have {
    font-size: 14px;
    color: $color-font-sub;
    margin-top: 20px;
    font-weight: $weight-medium;

    a {
      color: $color-green;
      font-weight: 600;
    }
  }
}

.FormTip {
  width: 480px;
  text-align: center;
  color: #8a8fb5;
  margin-bottom: 15px;
  line-height: 22px;
  font-size: 20px;
  a {
    color: #18c99d;
  }
}
