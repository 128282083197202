.ComingSoonPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 30rem);

  &__title {
    font-size: 2.2rem;
    font-weight: $weight-bold;
    color: white;
  }

  &__desc {
    font-size: 5.6rem;
    color: $color-content-background;
    font-weight: $weight-bold;
    margin-top: 2.5rem;
  }
}