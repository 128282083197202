.SecurityPage {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__Block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
    max-width: 57rem;
    background: #1F2331;
    margin-top: 3.5rem;
    border-radius: 1rem;

    &__Row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-grow: 1;
      width: calc(100% - 2.1rem);
      padding: 2.8rem 2.1rem 2.8rem 0;
      margin-left: 2.1rem;
      border-bottom: 1px solid $color-border-bis;

      &--last {
        border-bottom: none;
      }

      &__Title {
        font-size: 1.6rem;
        font-weight: $weight-bold;
        color: $color-font-main;
      }

      &__Bottom {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        margin-top: 2.2rem;
        font-weight: $weight-medium;
        color: $color-font-sub;

        &__Left {
          max-width: 27rem;
        }

        &__Right {
          margin-right: -2.1rem;
          .Button {
            background: transparent;
            margin-bottom: -1.2rem;
            font-weight: $weight-medium;
            opacity: 1;
          }
        }
      }

    }

    &__Items {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__Item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.4rem;
      font-weight: $weight-medium;
      color: $color-font-sub;
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
      border-bottom: 1px solid $color-border;
    }
  }


}