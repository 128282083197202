.ServiceSidebarSub {
  display: flex;
  flex-direction: column;
  width: 235px;

  &__title {
    font-size: 12px;
    font-weight: $weight-bold;
    color: #636B91;
    margin-top: 35px;
    margin-left: 12px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    margin-bottom: 15px;
    cursor: default;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0 12px;
    height: 50px;
    font-size: 13px;
    font-weight: $weight-medium;
    color: #636B91;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 22px;
    border-left: 1px solid #636B91;

    &:hover {
      color: $color-green;
      svg {
        fill: $color-green;
      }
    }

    svg {
      fill: #636B91;
    }

    &--active {
      color: $color-green;
      cursor: default;
      svg {
        fill: $color-green;
      }
    }

    &--suffix {
      margin-left: 0.4rem;
    }

  }
}