.AudioInput {
  position: relative;
  margin-top: 1rem;

  &__Label {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: $weight-semi;
    color: $color-font-sub;
    cursor: pointer;
    border: 2px dotted #4B547A;
    border-radius: 0.8rem;

    &--Green {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      color: $color-green;
    }

    &--dragover {
      background: $color-input-background-alt;
      border-radius: 0.8rem;
      border-color: $color-green;
    }
  }

  input[type='file'] {
    width: 40rem;
    height: 16rem;
    padding: 0.8rem;
    background: transparent;
    color: rgba(0, 0, 0, 0);

    &::file-selector-button {
      display: none;
    }

    &:focus {
      outline: none;
      border-color: rgba($color-green, 0.6);
    }
  }

  &__AudioUploaded {
    width: 40rem;
    height: 16rem;
    border-radius: 0.8rem;
    background: $color-content-background;
    padding: 1.5rem;
    position: relative;

    &__Info {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }

    &__Filename {
      font-size: 1.4rem;
      font-weight: $weight-medium;
      color: $color-font-main;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: -0.4rem;
      align-self: center;
    }

    &__Waveform {
      cursor: pointer;
      height: 6rem;
      margin: 1rem 0;
      
      // WaveSurfer specific styles
      wave {
        overflow: hidden !important;
        border-radius: 0.4rem;
      }
    }

    &__Player {
      width: 100%;
      margin-top: 1rem;
      height: 4rem;
      border-radius: 0.8rem !important;
      
      /* Custom audio player styling */
      &::-webkit-media-controls-panel {
        background: $color-grey-ui;
        border-radius: 0.8rem !important;
        padding: 0 1.2rem;
        display: flex !important;
        align-items: center;
        justify-content: space-between !important;
        height: 4rem !important;
      }
      
      /* Add !important to ensure our styles take precedence */
      &::-webkit-media-controls-enclosure {
        border-radius: 0.8rem !important;
      }
      
      &::-webkit-media-controls-play-button {
        border-radius: 50%;
        position: absolute !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
        background-color: $color-green !important;
        
      }
      
      &::-webkit-media-controls-current-time-display {
        color: $color-font-main;
        font-weight: $weight-medium;
        font-size: 1.2rem;
        position: relative !important;
        left: 0 !important;
      }

      &::-webkit-media-controls-time-remaining-display {
        color: $color-font-main;
        font-weight: $weight-medium;
        font-size: 1.2rem;
        margin-right: auto !important;
        position: relative !important;
        left: 0 !important;
      }
      
      &::-webkit-media-controls-timeline {
        border-radius: 0.2rem;
        height: 0.4rem;
        padding: 0;
        display: none;
      }
      
      &::-webkit-media-controls-volume-slider-container,
      &::-webkit-media-controls-volume-slider {
        margin-left: auto !important;
        width: 6rem;
        position: relative !important;
        right: 0 !important;
      }
      
      &::-webkit-media-controls-volume-slider {
        border-radius: 0.2rem;
        height: 0.4rem;
        filter: invert(1) brightness(1.8); /* Make the button white */
      }
      
      &::-webkit-media-controls-mute-button {
        background-color: transparent;
        filter: invert(1) brightness(1.8); /* Make the button white */
        margin-left: auto !important;
        position: relative !important;
        right: 0 !important;
      }
      
      /* Force volume slider to always show */
      
      /* Volume slider thumb */
      &::-webkit-media-controls-volume-slider::-webkit-slider-thumb {
        background-color: white !important;
      }
      
      /* Hide playback speed and download buttons */
      &::-webkit-media-controls-playback-button,
      &::-webkit-media-controls-overflow-button,
      &::-webkit-media-controls-download-button,
      &::-webkit-media-controls-toggle-closed-captions-button,
      &::-webkit-media-controls-fullscreen-button,
      &::-webkit-media-controls-more-button {
        display: none;
      }
      
      /* Firefox specific styles */
      &::-moz-range-track {
        background-color: rgba(255, 255, 255, 0.3);
      }
      
      &::-moz-range-thumb {
        background-color: white;
      }
    }

    &__Close {
      position: absolute;
      top: 0.8rem;
      right: 0.8rem;
      cursor: pointer;
      border-radius: 0.4rem;
      padding: 0.4rem;
      background: #1F2331;

      .g-icon {
        width: 1.2rem;
        height: 1.2rem;
      }
      svg {
        fill: $color-font-sub;
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  &__Loading {
    width: 40rem;
    height: 16rem;
    border-radius: 0.8rem;
    background: $color-content-background;
    padding: 1.5rem;
    position: relative;
    
    &__Content {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
    }
    
    &__Text {
      font-size: 1.4rem;
      font-weight: $weight-medium;
      color: $color-font-main;
    }
    
    &__Close {
      position: absolute;
      top: 0.8rem;
      right: 0.8rem;
      cursor: pointer;
      border-radius: 0.4rem;
      padding: 0.4rem;
      background: #1F2331;

      .g-icon {
        width: 1.2rem;
        height: 1.2rem;
      }
      svg {
        fill: $color-font-sub;
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  @include breakpoint('mobile') {
    &__AudioUploaded, &__Loading {
      width: 100%;
      max-width: 55rem;
    }
  }
} 