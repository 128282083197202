.Form {
  display: flex;
  flex-direction: column;

  input,
  textarea {
    font-family: $font-family;
    padding: 1rem 1.4rem 1rem 0;
    border: none;
    border-bottom: 1px solid $color-input-border;
    font-size: 16px;
    font-weight: $weight-semi;
    color: $color-input-text;
    width: 100%;
    margin-bottom: 12px;

    background-color: $color-input-background;
    &:focus {
      outline: 0;
      border-color: $color-input-focus;
    }
    &[disabled] {
      color: $color-dark-grey;
      //background-color: rgba($color-input-background, .60);
    }
    &:disabled {
      color: $color-dark-grey;
      cursor: not-allowed;
    }
    &::placeholder {
      color: $color-input-placeholder;
      font-weight: $weight-semi;
      opacity: 0.6;
    }
  }
}

.FormError {
  margin-top: 15px;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 600;
  color: $color-red;
  text-align: center;
}

.NewForm {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2.1rem;
    margin: 0 2rem 1.5rem;

    &--last {
      margin-bottom: 0;
    }
    &.center {
      justify-content: center;
    }
  }

  &__InputTextWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    &--half {
      width: 49%;
    }

    &--regular {
      width: 30rem;
    }

    &--auto {
      width: auto;
    }

    input {
      font-family: $font-family;
      height: 4rem;
      border-radius: 6px;
      border: 1px solid #3d4463;
      padding: 0 2rem;
      background: #131722;
      font-size: 1.4rem;
      color: $color-font-sub;
      font-weight: $weight-medium;

      &::placeholder {
        color: #636b91;
      }
      &::-webkit-input-placeholder {
        color: #636b91;
      }
      &::-moz-placeholder {
        color: #636b91;
      }

      &:-ms-input-placeholder {
        color: #636b91;
      }

      &:-moz-placeholder {
        color: #636b91;
      }

      &:focus {
        outline: 0;
        border-color: $color-input-focus;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  &__InputWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .SelectInput {
      width: 100%;
    }

    &--floating {
      width: auto;

      .SelectInput {
        margin-bottom: 0;
        width: 14rem;
      }
    }
  }

  &__InputLabel {
    font-size: 1.4rem;
    font-weight: $weight-medium;
    color: $color-font-sub;
    margin-bottom: 1.1rem;
    &.center {
      display: flex;
      justify-content: center;
    }
  }

  @include breakpoint('mobile') {
    &__Row {
      flex-direction: column;
    }
  }
}

.NewFormError {
  margin-top: -2rem;
  margin-bottom: 2rem;
  font-size: 16px;
  height: 16px;
  font-weight: $weight-semi;
  color: $color-red;

  &.hidden {
    display: none;
  }
}
