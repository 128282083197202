.SupportPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 450px;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    // max-width: 57rem;

    &--title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 50px;
      text-align: center;
    }
  }
}
