.ModelDeploymentDetailPage {
  &__details {
    // overflow-y: scroll;
    margin-bottom: 50px;
    &--row {
      display: flex;
      font-size: 14px;
      margin-top: 28px;
      &.section {
        margin-top: 55px;
      }
    }
    &--section-title {
      color: #636b91;
      font-weight: $weight-bold;
    }
    &--title {
      flex-basis: 30%;
      color: #636b91;
      display: flex;
      align-items: center;
    }
    &--value {
      flex-basis: 70%;
      color: #ffffff;
      display: flex;
      align-items: center;
      & .Loader {
        margin: 0;
        margin-right: 10px;
      }
      &.green {
        color: #18c99d;
      }
      &.disabled {
        color: #636b91;
      }
      &.status {
        & svg {
          margin-right: 5px;
        }
      }
    }
  }

  &__nodes {
    padding: 10px 0;
    margin-bottom: 50px;
    color: #8a8fb5;
    &--row {
      height: 55px;
      display: flex;
      font-size: 14px;
      border-bottom: 1px solid #24283b;
    }
    &--cell {
      display: flex;
      align-items: center;
      &.id {
        flex-basis: 50%;
      }
      &.specs {
        flex-basis: 30%;
      }
      &.status {
        flex-basis: 20%;
        & svg {
          margin-right: 5px;
        }
      }
    }
  }

  &__storage {
    margin-top: 20px;

    &--header {
      display: flex;
      color: #636b91;
      font-weight: $weight-medium;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 10px;
    }

    &--row {
      display: flex;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      color: #ffffff;

      &:last-child {
        border-bottom: none;
      }
    }

    &--cell {
      flex: 1;
      padding: 12px 16px 12px 0;
      display: flex;
      align-items: center;

      &:first-child {
        padding-left: 0;
      }

      &.mobile-hide {
        @include breakpoint("mobile") {
          display: none;
        }
      }
    }

    &--button {
      cursor: pointer;
    }

    &--selector {
      flex: 1;
    }

    &--create-button {
      width: auto;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 600;
      cursor: not-allowed;
      flex: 1;
      margin-left: 20px;
      color: #636b91;
      &.active {
        color: #18c99d;
        cursor: pointer;
      }
    }
  }

  @include breakpoint('mobile') {
    &__details {
      margin-bottom: 0;
      &--row {
        flex-direction: column;
        gap: 1rem;

        &.section {
          margin-top: 0;
        }
      }
      &--section-title {
        margin-top: 3rem;
      }
    }
    &__nodes {
      &--row {
        height: auto;
        padding: 1rem 0;
      }
    }
  }
}
