.UserAvatar {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: $weight-bold;
  text-transform: uppercase;
  color: black;
  cursor: default;
}