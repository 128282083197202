.AiArtContestPage {
  width: 100%;
  margin: 3.7rem;

  &__Header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__Splash {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 15rem;
  }

  &__Title {
    font-size: 5.7rem;
    font-weight: $weight-bold;
    margin-bottom: 3rem;
    text-align: center;

    &--splash {
      font-size: 7.5rem;
      margin-bottom: 4.3rem;
    }
  }

  &__Subtitle {
    font-size: 1.5rem;
    font-weight: $weight-bold;
    margin-bottom: 1.4rem;
  }

  &__Logo {
    height: 2.4rem;
    margin-bottom: 4rem;
  }

  &__Border {
    width: 100%;
    height: 1px;
    background: $color-border;
  }

  &__SendButton {
    position: absolute;
    top: 4rem;
    right: 4rem;
  }

  &__Description {
    font-size: 2rem;
    font-weight: $weight-medium;
    text-align: center;
    line-height: 2.4rem;
    color: $color-font-sub;
    margin-top: 2.7rem;
  }

  &__StartButton {
    margin-top: 8.6rem;
    width: 15.8rem;

  }

  &__Instructions {
    a {
      color: $color-green;
      text-decoration: none;
      font-weight: $weight-semi;
    }
  }

  .ChatbotMessageContainer {
    margin-top: 0;
  }

  @include breakpoint('mobile') {

    &__Title {
      font-size: 4rem;
      margin-bottom: 2rem;

      &--splash {
        font-size: 5rem;
        margin-bottom: 3rem;
      }

    }
    &__SendButton {
      display: none;
      //position: static;
      //margin-top: 2rem;
    }
  }
}