.code-input {
  @include row($justify: center);

  .character {
    @include row($align: center, $justify: center);
    width: 4rem;
    height: 5.5rem;
    border-radius: 0.4rem;
    margin: 0 0.65rem;
    border: 1px solid #596385;
    flex-grow: 0;
    flex-basis: auto;
    cursor: text;
    font-size: 2.9rem;
    color: $color-font-main;
    font-weight: $weight-regular;
    background: transparent;

    &input[type="text" i] {
      padding: 0;
    }
  }

  input {
    padding: 0;
    border: none;
    font-weight: $weight-semi;
    color: $color-input-text;
    width: 100%;
    text-align: center;
    background-color: $color-input-background;

    &:focus {
      outline: 0;
      border-color: $color-input-focus;
    }
  }

}

.g-code-wrapper {
  @include column($justify: center);
  margin-top: 3.2rem;
  width: 100%;
  background-color: #292D3D;
  border-radius: 1rem;
  padding: 2rem 2rem 2.6rem;

  .code-label {
    font-size: 1.4rem;
    font-weight: $weight-semi;
    margin-bottom: 2rem;
  }

  .use-recovery {
    font-size: 1.3rem;
    cursor: pointer;
    margin-top: 1.5rem;
    color: $color-font-sub;
    font-weight: $weight-medium;
    text-decoration: underline;

    &:hover {
      color: $color-green;
    }
  }

  .recovery-input {
    width: 100%;
  }
}
