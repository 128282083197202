.AcceptProjectInvite {

  &__desc {
    margin-top: 2rem;
    font-size: 1.5rem;
    color: $color-font-sub;
    font-weight: $weight-semi;
    text-align: center;
  }


}