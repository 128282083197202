.Navbar {
  background-color: $color-background;
  height: 7rem;
  &.expand {
    height: 130px;
    border-bottom: none;
    & .Navbar__content {
      border-bottom: 1px solid $color-border;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    padding-left: 28px;
    padding-right: 28px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 100%;
    border-bottom: 1px solid $color-border;

    &.isHome {
      align-self: center;
      max-width: 161rem;
    }

    .SelectProjectButton {
      border: 1px solid $color-content-background;
      flex-direction: row-reverse;
      background-color: transparent;
      border-radius: 0.6rem;
      margin-left: 12rem;
      width: 30rem;
      height: 4rem;
      text-align: left;
      justify-content: space-between;
      align-items: center;
      padding-left: 2rem;
      padding-right: 0;
      font-size: 1.4rem;
      font-weight: $weight-medium;
      color: $color-font-sub;
      position: relative;

      &:has(.g-icon) {
        padding-left: 2rem;
      }

      &:hover {
        border-color: $color-green;

        .g-icon {
          fill: $color-green;
        }
      }

      .g-icon {
        //position: absolute;
        //right: 0;
        fill: $color-font-sub;
        margin-right: 1.4rem;
        margin-top: 0.3rem
      }
    }
  }

  &__logo {
    cursor: pointer;
  }

  &__login-button {
    border-right: 1px solid $color-border-bis;
  }

  &__Link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
  }

  &__user-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    height: 100%;

    .vertical-divider {
      width: 1px;
      height: 4rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      background-color: $color-border-bis;
    }

    .UserAvatar {
      cursor: pointer;
    }

  }

  &__user {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    border-radius: 50px;
    width: 36px;
    height: 36px;
    background-color: #191d29;
    color: #8a8fb5;
    margin-left: 30px;
  }

  @include breakpoint('mobile') {

    &__content {
      padding-left: 2.5rem;
      padding-right: 1.5rem;
    }

    &__logo {
      height: 3rem;
    }

    &__side-menu {
      padding-right: 1.6rem;
      cursor: pointer;
    }
  }
}

.NavbarLink {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #8a8fb5;
  font-size: 16px;
  font-weight: 600;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  position: relative;
  background: transparent;
  outline: none;
  border: 0;
  cursor: pointer;

  &:hover {
    color: $color-green;
  }

  &.login-btn, &.signup-btn {
    color: $color-green;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    &:hover {
      color: $color-green-hover;
    }

  }

  &.login-btn {
    margin-left: 3rem;
  }

  &__border {
    position: absolute;
    bottom: 0;
    height: 2px;
    left: 0;
    right: 0;
    border-bottom: 1px solid $color-green;
  }

  &--active {
    color: white;
  }

  &.metric {
    margin-left: 15px;
  }
  &.delete {
    &:hover {
      color: #FF2685;
    }
  }
  &.disabled {
    cursor: not-allowed;
    &:hover {
      color: #8a8fb5 !important;
    }
  }

  &.logout-btn {
   cursor: pointer;

    &:hover {
      color: $color-red;
    }
  }

  &--account, &--logout {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 1rem 2rem;
    text-align: right;
    font-weight: $weight-medium;
  }

  &--logout {
    color: $color-red;

    &:hover {
      color: rgba($color-red, 0.9);
    }
  }

  @include breakpoint('mobile') {
    font-size: 1.6rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }

}
