.DAppVideosPane {
  width: 100%;
}

.DAppSettingsPane {
  margin-top: 45px;
}

.DAppSettingsToolTip {
  color: #8a8fb5;
  margin: 10px 0 40px;
  display: flex;

  &__icon,
  &__text {
    display: inline-block;
    vertical-align: middle;
  }
  & p {
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  & svg {
    vertical-align: middle;
    margin: 0 2px 0 5px;
  }
  & a {
    color: white;
  }
}
