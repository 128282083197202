.VideoPage {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: calc(1280px + 24px);
    padding-left: 12px;
    padding-right: 12px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    width: 100%;
    padding-bottom: 100px;
  }

  &__title {
    font-size: 30px;
    font-weight: bold;
    line-height: 45px;
    text-align: center;
    margin-bottom: 12px;
    color: #ffffff;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 12px;
    color: $color-text-dark;
  }

  .URLCard {
    margin-top: 15px;
    width: unset;
  }

  &__video-wrapper {
    width: 900px;
    margin-left: auto;
    margin-right: auto;
  }

  &__delete {
    font-size: 16px;
    font-weight: 600;
    color: $color-text-dark;
    line-height: 2rem;
    text-align: center;
  }

  &__drm-description {
    font-size: 16px;
    font-weight: 600;
    color: $color-text-dark;
    line-height: 2rem;
    text-align: center;
  }

  &__docs-instructions {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    margin-bottom: 12px;
    color: white;
  }

  &__info {
    color: white;
    width: 100%;
    max-width: 700px;
    margin-top: 10px;

    &--row {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 13%;
      margin-bottom: 5px;
      width: 80%;
      height: 30px;
      &.event {
        height: auto;
        margin-top: 10px;
        align-items: flex-start;
      }
    }

    &--header {
      width: 35%;
    }

    &--content {
      width: 80%;
      color: #8a8fb5;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.online {
        color: #18c99d;
      }
      &.event {
        flex-direction: column;
        align-items: flex-start;
        & p {
          margin-bottom: 10px;
        }
      }
    }

    &--success {
      color: #18c99d;
      // width: 20px;
    }

    &--error {
      color: $color-red;
      width: 80%;
      font-weight: 600;
      margin-left: 13%;
      margin-top: 20px;
      & a {
        color: #18c99d;
        font-weight: $weight-bold;
        cursor: pointer;
      }
    }

    &--warning {
      color: $color-text-dark;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
    }
  }

  &__button {
    width: fit-content;
    border: 1px solid #8a8fb5;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;

    &--close {
      height: 20px;
      width: 20px;
      margin-left: 10px;
      cursor: pointer;
    }

    &--copy {
      height: 20px;
      width: 20px;
      cursor: pointer;
      margin-right: 20px;
    }
  }

  &__delete {
    display: flex;
    flex-direction: column;
    align-items: center;
    & span {
      margin: 10px;
      font-size: 18px;
    }
  }

  @include breakpoint("mobile") {
    width: 100%;
    &__content {
      max-width: none;
      width: auto;
      padding: 1rem 2rem;
      margin: 0;
    }

    &__title {
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin-bottom: 0.8rem;
    }

    &__subtitle {
      font-size: 1.3rem;
      margin-bottom: 0.8rem;
    }

    &__video-wrapper {
      width: 100%;
    }

    &__info {
      max-width: 100%;

      &--row {
        margin-left: 0;
        width: 100%;
      }

      &--header {
        width: 40%;
      }

      &--content {
        width: 60%;
      }

      &--error {
        margin-left: 0;
        width: 100%;
      }
    }

    &__button {
      &--copy {
        margin-right: 1rem;
      }
    }

    .wbhkReg__url {
      flex-direction: column;
      gap: 1rem;

      &--icons {
        justify-content: flex-end;
      }
    }

    .LiveDemoCardStep {
      width: 100%;

      pre {
        width: 100%;
      }
    }
  }
}

.wbhkReg__url {
  display: flex;
  justify-content: space-between;
  width: 100%;

  input {
    width: 100%;
    font-size: 16px;
    font-family: $font-family;
    border: none;
    border-bottom: 1px solid $color-input-border;
    color: $color-input-text;

    background-color: $color-input-background;
    &:focus {
      outline: 0;
      border-color: $color-input-focus;
    }
    &[disabled] {
      color: rgba($color-input-text, 0.4);
      //background-color: rgba($color-input-background, .60);
    }
    &::placeholder {
      color: $color-input-placeholder;
      font-weight: $weight-semi;
      opacity: 0.6;
    }
  }

  &--icons {
    display: flex;
  }

  &--icon {
    cursor: pointer;
    &.edit {
      color: #8a8fb5;
      &:hover {
        color: white;
      }
    }
    &.cancel {
      margin-right: 10px;
      color: rgba(#8a8fb5, 0.5);
      &:hover {
        color: #8a8fb5;
      }
    }
    &.submit {
      color: rgba(#18c99d, 0.5);
      &:hover {
        color: #18c99d;
      }
    }
  }
}
