.AiServicePage {
  padding: 68px 55px 60px 59px;
  position: relative;
  overflow-y: auto;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.detail {
      justify-content: flex-start;
    }
    &.detail-update {
      flex-direction: column;
      align-items: self-start;
    }
    &--title {
      font-size: 3.2rem;
      font-weight: $weight-bold;
    }

    &--subtitle {
      font-size: 1.8rem;
      font-weight: 600;
      margin-top: 1.4rem;
    }

    &--note {
      font-size: 1.4rem;
      font-weight: 600;
      margin-top: 1rem;
      color: #636b91;
    }

    &--note-row {
      display: flex;
      margin-bottom: 0.5rem;
      flex-wrap: wrap;

      a {
        color: $color-green;

        &.learn-more {
          color: rgba(24, 201, 157, 0.9);
        }
      }
    }

    &--link {
      color: $color-green;
      cursor: pointer;
    }

    &--button {
      cursor: pointer;
      padding: 0.8rem 1.8rem;
      border-radius: 0.8rem;
      background-color: rgba(#18c99d, 0.1);
      color: #18c99d;
      font-size: 1.4rem;
      font-weight: $weight-semi;
      display: flex;
      align-items: center;

      &:hover {
        background-color: rgba(#18c99d, 0.25);
      }

      &.detail {
        height: 4rem;
        font-size: 1.8rem;
        margin-left: 2rem;
      }

      &.disabled {
        cursor: not-allowed;
        color: #636b91;
        background-color: #1f2331;

        &:hover {
          background-color: #1f2331;
        }
      }

      & .Loader {
        margin: 0;
        margin-right: 1rem;
      }
    }
  }
  &__action-bar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    padding: 5px 0;
    border-top: 1px solid #24283b;
    border-bottom: 1px solid #24283b;
    font-weight: 600;
  }
  &__action-button {
    margin-right: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #636b91;
    font-size: 15px;
    svg {
      fill: white;
      width: 15px;
      height: 15px;
    }
    &:hover {
      color: white;
    }
    .g-icon {
      display: flex;
      align-items: center;
    }
    &.stop {
      svg {
        width: 15px;
        height: 15px;
      }
    }
    &.delete {
      .g-icon.icon-delete {
        width: 15px;
        height: 17px;
        svg {
          width: 15px;
          height: 17px;
        }
      }
    }
  }
  &__content {
    width: 100%;
    margin-top: 5rem;

    &.new-deployment {
      margin-top: 3.8rem;
    }

    &.storage {
      margin-top: 0;
    }

    & .AiTabs {
      position: relative;
    }
  }

  &__table {
    width: 100%;
    font-size: 1.4rem;
    color: #636b91;
    font-weight: $weight-medium;

    &--header {
      text-align: left;
      font-weight: $weight-medium;
    }

    &--header-row {
      display: flex;
      height: 7rem;
    }

    &--row {
      display: flex;
      height: 7rem;
      border-radius: 1rem;
      margin-bottom: 1rem;
      background-color: #1f2331;

      &:hover {
        background-color: #282d3c;
      }

      & .title {
        color: #ffffff;
      }

      a {
        display: flex;
        align-items: center;
        flex: 1;
        flex-basis: 20%;
        // padding-left: 24px;
      }

      &.clickable {
        cursor: pointer;
      }
      &.notAllowed {
        cursor: not-allowed;
      }
    }

    &--cell {
      flex: 1;
      display: flex;
      align-items: center;

      &.title {
        flex-basis: 15%;
        padding-left: 2.4rem;

        .icon-notebook {
          margin-left: -0.5rem;
          margin-right: 0.5rem;
          fill: #636b91;
        }

        .icon-serving {
          margin-left: -0.5rem;
          margin-right: 0.5rem;
          fill: #636b91;
        }
      }

      &.hardware {
        flex-basis: 30%;
      }

      &.duration {
        flex-basis: 7%;
      }

      &.status {
        flex-basis: 5%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.icon {
          font-size: 2rem;
          color: #18c99d;
        }

        & .Loader {
          margin: 0;
          margin-right: 0.5rem;
        }

        & svg {
          margin-right: 0.5rem;
        }
      }

      &.view {
        justify-content: center;
        flex-basis: 5%;
        position: relative;
      }

      &.ellipsis {
        justify-content: end;
        flex-basis: 3%;
        position: relative;
      }

      &.serving-duration {
        flex-basis: 5%;
      }

      &.serving-hardware {
        flex-basis: 25%;
      }

      &.inference-endpoint {
        flex-basis: 10%;

        & .AiServicePageViewButton {
          width: fit-content;
          padding: 0 1rem;
        }
      }

      &.llm-model {
        flex-basis: 50%;
      }

      &.chatbot-title {
        flex-basis: 25%;
        padding-left: 2.5rem;
      }

      &.chatbot-id {
        flex-basis: 25%;
      }
      &.region {
        flex-basis: 5%;
        justify-content: center;
      }
      &.clickable {
        cursor: pointer;
      }
    }

    @include breakpoint("mobile") {
      overflow-x: auto;
      display: block;

      &--header-row,
      &--row {
        min-width: 120rem;
      }

      &--cell {
        &.title {
          flex-basis: 20%; // Adjusted for better mobile layout
        }

        &.hardware {
          flex-basis: 25%;
        }

        &.duration {
          flex-basis: 10%;
        }

        &.serving-hardware {
          flex-basis: 20%;
        }

        &.inference-endpoint {
          flex-basis: 15%;
        }

        &.chatbot-title,
        &.chatbot-id {
          flex-basis: 25%;
        }
      }
    }
  }

  &__back-button {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 15rem;
    cursor: pointer;
    fill: #636b91;
    color: #636b91;
    font-size: 1.3rem;
    top: 2.5rem;

    &:hover {
      color: #ffffff;
      fill: #ffffff;
    }

    &.long {
      width: 18rem;
    }

    &.gpu-node {
      width: 10rem;
    }

    &.gpu-node-list {
      width: 120px;
    }

    &.gpu-node-detail {
      width: 135px;
    }

    &.rag-chatbot {
      width: 11.5rem;
    }
  }

  &__cells {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  &__cell {
    cursor: pointer;
    flex-basis: 45%;
    background-color: #1f2331;
    border-radius: 1rem;
    height: 27rem;
    margin-right: 3rem;
    margin-bottom: 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #282d3c;
    }

    &--text {
      font-size: 2rem;
      font-weight: $weight-bold;
      margin-top: 2.5rem;
    }
  }

  &__code-container {
    width: 100%;
    max-width: calc(100vw - 440px);
    overflow-x: auto;
    word-wrap: break-word;
  }

  &__copy-wrap {
    position: absolute;
    right: 0;
    top: -1.5rem;

    & .Button {
      width: 11rem;
    }
  }

  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
    color: $color-font-sub;
    font-weight: $weight-semi;
    margin-top: 6rem;
    font-size: 1.5rem;
  }

  &__name-warp {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 1rem;

    .PageTitleBar__right--icon {
      display: none;
    }

    &--empty {
      color: $color-font-sub;
      font-style: italic;
    }

    &:hover {
      .PageTitleBar__right--icon {
        display: block;
      }
    }

    @include breakpoint("mobile") {
      .PageTitleBar__right--icon {
        display: block;
      }
    }

    input {
      border: none;
      border-bottom: 1px solid $color-input-focus;
      font-weight: $weight-semi;
      color: $color-input-text;
      background-color: $color-input-background;
      width: calc(100% - 3.8rem);

      &:focus {
        outline: 0;
        border-color: $color-input-focus;
      }

      &[disabled] {
        color: rgba($color-input-text, 0.4);
        //background-color: rgba($color-input-background, .60);
      }

      &::placeholder {
        color: $color-input-placeholder;
        font-weight: $weight-semi;
        text-align: center;
        opacity: 0.6;
      }
    }
  }

  &__icon-warp {
    display: flex;
  }

  &__tooltip {
    max-width: 280px;
  }

  &__copy-button-wrap {
    margin-left: 1rem;
    cursor: pointer;

    &:hover {
      .g-icon {
        fill: $color-green;
      }
    }
  }

  &__event {
    border-radius: 6px;
    background-color: #1f2331;
    margin: 20px 0;
    padding: 2rem;
    &--info {
      display: flex;
      color: #636b91;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &--label {
      min-width: 100px;
      font-weight: 600;
      margin-right: 10px;
    }
    &--empty-state {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      color: #636b91;
      font-size: 20px;
      font-weight: 600;
    }
  }
  &__filter {
    &--sort {
      margin-left: 15px;
    }
  }

  @include breakpoint("mobile") {
    padding: 3rem 2rem;

    &--with-back-button {
      padding: 6rem 2rem;
    }

    &__content,
    .new-deployment {
      margin-top: 3rem;
    }

    &__header {
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;

      &--button.detail {
        margin-left: 0;
      }
    }

    .SelectInput {
      width: 100%;
      margin-bottom: 3rem;
    }
  }
}

.AiServiceHeaderButtonIcon {
  height: 2.4rem;
  width: 2.4rem;
  margin-right: 0.6rem;
  fill: #18c99d;

  &.empty {
    width: 0;
    margin: 0;
  }
}

.AiServicePageViewButton {
  border-radius: 0.8rem;
  color: #636b91;
  height: 3.2rem;
  width: 6.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;

  &.active {
    color: #18c99d;
    cursor: pointer;

    &:hover {
      background-color: rgba(#18c99d, 0.1);
    }

    & a {
      pointer-events: inherit;
      cursor: pointer;
    }
  }

  & a {
    display: flex;
    justify-content: center;
    pointer-events: none;
  }

  & .Loader {
    margin-right: 0.5rem;
  }
}

.AiServicePageEllipsis {
  margin-right: 1rem;
  cursor: pointer;

  &.ct {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &__popup {
    position: absolute;
    right: 3.5rem;
    top: 0;
    border-radius: 1rem;
    border: 1px solid #282d3c;
    background-color: #1f2331;
    padding: 1rem 2rem;
    z-index: 2;
    cursor: default;

    &.ct {
      right: 5rem;
    }

    &--row {
      color: #8a8fb5;
      cursor: pointer;
      margin: 1rem 0;

      &:hover {
        color: white;
      }
      &.disabled {
        cursor: not-allowed;
        color: #636b91;
      }
    }
  }
}
