.stream-offline {
  background: center;
  height: 200px;
  width: 300px;
  margin-top: 100px;
}
.ingestor-selector {
  width: 120%;
  display: flex;

  & div:first-child{
    margin-right: 20px;
  }

  // &--button {

  // }
}