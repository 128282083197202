.ShowcaseImageToVideo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &__container {
    display: flex;
    flex-grow: 1;
  }

  &__LeftSide {
    border-right: 1px solid $color-border-bis;
  }

  &__RightSide {
    width: 100%;
  }

  .ErrorMessage {
    color: $color-red;
    font-weight: $weight-semi;
    margin: 3.5rem 4rem 4rem 3.8rem;
  }

  .ResultBox {
    background: #191D29;
    border-radius: 1rem;
    width: 45rem;
    margin: 3.5rem 4rem 4rem 3.8rem;

    &__Idle {
      display: flex;
      padding: 1.4rem 2rem 3rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 25rem;
      color: #3D4463;
      font-weight: $weight-bold;

      .g-icon {
        margin-bottom: 2rem;
      }
    }

    &__Loading {
      position: relative;
      display: flex;
      padding: 1.4rem 2rem 3rem;
      justify-content: center;
      align-items: center;
      height: 25rem;

      &__TopWrapper {
        position: absolute;
        top: 1.4rem;
        right: 2rem;
        text-align: center;
        color: $color-font-main;
        font-weight: $weight-medium;
        font-size: 1.2rem;
      }

      &__CenterWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        color: $color-green;
        font-weight: $weight-medium;
        font-size: 1.4rem;
        gap: 1rem;
      }

      &__BottomWrapper {
        position: absolute;
        bottom: 2.5rem;
        left: 0;
        right: 0;
        text-align: center;
        color: $color-font-sub;
        font-weight: $weight-medium;
        font-size: 1.4rem;
      }
    }

    &__Video {
      position: relative;
      display: flex;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &__Player {
        width: 100%;
        height: 100%;
      }

      &__DownloadWrapper {
        position: absolute;
        top: 2rem;
        right: 2rem;

        .g-icon {
          width: 3rem;
          height: 3rem;
          cursor: pointer;
          svg {
            width: 3rem;
            height: 3rem;
          }
        }
      }
    }
  }

  &__Title {
    font-weight: $weight-medium;
    color: $color-font-sub;
    margin-top: 2.2rem;
  }


  .SidePanel {
    background: #1F2331;
    border-radius: 1rem;
    padding: 1.4rem 2rem 3rem;
    margin-top: 2rem;
    width: 44rem;
    height: fit-content;
    margin-right: 3.8rem;
    margin-bottom: 4rem;

    &__OutputOptions {
      display: flex;
      align-items: center;
      color: $color-font-main;
      font-weight: $weight-bold;
      cursor: pointer;
      margin-top: 3rem;

      &.open {
        .g-icon {
          transform: rotate(180deg);
        }
      }

      .g-icon {
        margin-left: 0.7rem;
        fill: #8A8FB5;
      }
    }


    &__ActionButtons {
      display: flex;
      justify-content: space-between;
      margin-left: -0.8rem;
      margin-right: -0.8rem;
      margin-top: 2rem;

      &__Button {
        padding: 0.5rem 1rem;
        cursor: pointer;
        height: 4rem;
        flex: 1;
        margin-left: 0.8rem;
        margin-right: 0.8rem;

        &:disabled {
          opacity: 0.5;
          cursor: default;

        }
      }
    }

    &__GenerateImageLink {
      font-size: 1.2rem;
      font-weight: $weight-medium;
      color: $color-font-sub;
      text-align: center;
      margin-top: 1.7rem;

      a {
        text-decoration: underline;
      }
    }


    &__form {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &__input {
      height: 10rem;
      flex-grow: 1;
      padding: 1.8rem;
      border-radius: 0.6rem;
      background: transparent;
      border: 1px solid #3D4463;
      color: $color-font-sub;
      font-size: 1.4rem;
      font-family: "Gilroy", -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", sans-serif;
      font-weight: $weight-medium;
      margin-bottom: 1.75rem;
      resize: none;

      &::placeholder {
        color: rgba($color-font-sub, 0.6);
      }

      &:focus {
        outline: none;
        border-color: rgba($color-green, 0.6);
      }
    }


  }

  .VideosWrapper {
    width: 100%;
  }

  .UserMessageContainer {
    padding-top: 0;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    &.isContest {
      padding-top: 3.4rem;
    }

    .UserMessage {
      margin-top: 3rem;
      padding: 2rem 2.5rem;
      border-radius: 1rem;
      font-size: 1.4rem;
      font-weight: $weight-medium;
      width: 36rem;
      max-width: 100%;
      line-height: 1.68rem;
      display: flex;
      flex-direction: column;
      position: relative;
      align-self: flex-end;
      background: $color-dark-grey;
      color: $color-green;
      margin-bottom: 2rem;

      &__actions {
        display: flex;
        align-items: center;
        position: absolute;
        top: -4rem;
        left: 0;
        gap: 1.1rem;

        .Button--medium {
          min-width: auto;
          width: auto;
        }

        .g-icon {
          width: 1.8rem;
          height: 1.8rem;

          svg {
            width: 1.8rem;
            height: 1.8rem;

          }
        }
      }

      &__PromptLabel {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: $weight-bold;
        color: $color-font-main;
        margin-bottom: 1.2rem;

        .g-icon {
          margin-left: 0.6rem;
        }
      }

      &__PromptValue {
        font-weight: $weight-medium;
        color: #3D4463;
        margin-bottom: 2rem;

        img {
          height: 20rem;
        }
      }

      &__BottomWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      &__ParamsButton {
        display: flex;
        align-items: center;
        color: $color-font-sub;
        cursor: pointer;

        &.open {
          .g-icon {
            transform: rotate(180deg);
          }
        }

        .g-icon {
          margin-left: 0.7rem;
          fill: #8A8FB5;
        }
      }

      &__Params {
        pre {
          font-size: 1.4rem;
          font-weight: $weight-medium;
          color: $color-font-sub;
          margin-top: 1.2rem;
          white-space: pre-line;
        }
      }
    }
  }




  @include breakpoint('mobile') {

    &__container {
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;
    }

    &__LeftSide {
      margin-bottom: 0;
      margin-top: 0;
      border: none;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__RightSide {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .VideosWrapper {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    &__Title {
      align-self: flex-start;
      margin-top: 2rem;
      margin-bottom: -1rem;
    }

    .SidePanel {
      margin-right: 0;
      width: 100%;
      max-width: 55rem;
      margin-top: 0;
      margin-bottom: 0;

      &__ActionButtons {
        .Button--medium {
          max-width: 50%;
          min-width: auto;
        }
      }
    }

    .ResultBox {
      margin: 2rem 0 0;
      width: 100%;
      max-width: 55rem;
    }

    .UserMessageContainer {
      .UserMessage {
        width: 100%;
        max-width: 55rem;
      }
    }

  }
}
