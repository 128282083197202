.ImageInput {
  position: relative;
  margin-top: 1rem;

  &__Label {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: $weight-semi;
    color: $color-font-sub;
    cursor: pointer;
    border: 2px dotted #4B547A;
    border-radius: 0.8rem;

    &--Green {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      color: $color-green;
    }

    &--dragover {
      background: $color-input-background-alt;
      border-radius: 0.8rem;
      border-color: $color-green;
    }
  }

  input[type='file'] {
    width: 40rem;
    height: 16rem;
    padding: 0.8rem;
    background: transparent;
    color: rgba(0, 0, 0, 0);

    &::file-selector-button {
      display: none;
    }

    &:focus {
      outline: none;
      border-color: rgba($color-green, 0.6);
    }
  }

  &__ImageUploaded {
    width: 40rem;
    border-radius: 0.8rem;

    &__Close {
      position: absolute;
      top: 0.8rem;
      right: 0.8rem;
      cursor: pointer;
      border-radius: 0.4rem;
      padding: 0.4rem;
      background: #1F2331;

      .g-icon {
        width: 1.2rem;
        height: 1.2rem;
      }
      svg {
        fill: $color-font-sub;
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  @include breakpoint('mobile') {

    &__ImageUploaded {
      width: 100%;
      max-width: 55rem;
    }

  }
}
