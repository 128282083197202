.OrgAndProjectDropdowns {
  display: flex;
  align-items: center;
  margin-left: 2rem;
  margin-right: 2rem;
  gap: 0.3rem;

  &__Dropdown {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__DropdownLabel {
    display: none;
  }

  .SelectInput {
    max-width: 19rem;
    width: auto;
    margin-bottom: 0;
    caret-color: transparent;

    .dropdown-icon {
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      height: 100%;
      right: 0.5rem;
      fill: $color-font-sub;
      width: 2.8rem;

      svg {
        width: 2.8rem;
        height: 2.8rem;
      }
    }
  }

  &__Separator {
    color: $color-font-sub;
    font-weight: $weight-bold;
    font-size: 1.4rem;
  }

  .OrgLabel {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &__sub {
      display: none;
    }

    &__name {
      height: 1.5rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .ProjectLabel {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &__sub {
      display: none;
    }

    &__name {
      height: 1.5rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .CreateNewOption {
    display: flex;
    align-items: center;
    border-top: 1px solid #3D4463;
    margin-top: -10px;
    padding-top: 10px;
    margin-left: -1rem;
    margin-right: -1rem;

    &__icon {
      width: 12px;
      height: 12px;
      margin-right: 8px;
      margin-left: 1rem;

      svg {
        fill: $color-font-sub;
      width: 12px;
      height: 12px;
      }
    }

    &:hover {
        svg {
          fill: $color-green;
        }
    }
  }

  @include breakpoint('mobile') {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
    flex-grow: 1;
    margin-bottom: 1rem;
    border-bottom: 1px solid $color-border-bis;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #202432;

    &__Separator {
      display: none;
    }

    &__Dropdown {
      width: 100%;
    }

    &__DropdownLabel {
      display: none;
    }

    .SelectInput {
      max-width: none;
      width: 100%;
      height: auto;
      background-color: #131722;
    }

    .OrgLabel, .ProjectLabel {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__sub {
        display: block;
        font-size: 1.1rem;
        text-transform: uppercase;
        font-weight: $weight-bold;
      }

      &__name {
        height: auto;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
