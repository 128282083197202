.AiTabs {
  width: 100%;
  border-bottom: 1px solid #24283b;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 2rem 0;
  padding: 0;
  list-style: none;

  @include breakpoint('mobile') {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    margin-bottom: 3rem;

    .AiTab {
      margin-right: 0;
      text-align: center;
      width: 100%;
    }
  }
}

.AiTab {
  box-shadow: inset 0 -1px 0 transparent;
  display: block;
  font-size: 14px;
  font-weight: $weight-medium;
  color: $color-text-dark;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  margin-right: 40px;
  padding-bottom: 5px;

  a {
    display: block;
    // padding: 14px 14px 15px;
  }

  &--selected {
    color: white;
    border-bottom: 1px solid #18c99d;

    // &:after{
    //   content: "";
    //   height: 2px;
    //   width: 15px;
    //   border-bottom: 2px solid $color-green;
    //   left: calc(50% - 7.5px);
    //   position: absolute;
    //   bottom: 4px;
    // }
  }
}
