.PaymentPage {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: calc(1280px + 24px);
    padding-left: 12px;
    padding-right: 12px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    width: 100%;
    padding-bottom: 100px;
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    line-height: 45px;
    text-align: center;
    margin: 30px 0;
    color: #ffffff;
  }

  &__note {
    width: 50%;
    margin-bottom: 50px;
    text-align: center;
    color: $color-text-dark;
    line-height: 30px;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 12px;
    color: $color-text-dark;
  }

  &__info {
    color: white;
    width: 100%;
    max-width: 700px;
    margin-top: 10px;

    &--row {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 13%;
      margin-bottom: 5px;
      width: 80%;
      height: 30px;
    }

    &--header {
      width: 35%;
    }

    &--content {
      width: 80%;
      color: #8a8fb5;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.online {
        color: #18c99d;
      }

      &.state {
        color: #ff2685;
        &.paid {
          color: #18c99d;
        }
      }
    }

    &--success {
      color: #18c99d;
      // width: 20px;
    }

    &--error {
      color: $color-red;
      width: 80%;
      font-weight: 600;
      margin-left: 13%;
      margin-top: 20px;
    }

    &--warning {
      color: $color-text-dark;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
    }
  }

  &__button {
    width: fit-content;
    border: 1px solid #8a8fb5;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;

    &--close {
      height: 20px;
      width: 20px;
      margin-left: 10px;
      cursor: pointer;
    }

    &--copy {
      height: 20px;
      width: 20px;
      cursor: pointer;
      margin-right: 20px;
    }
  }
}
