.CreateCustomImageModal {
  background-color: #191d29;
  border-radius: 20px;
  padding: 0 4rem;
  overflow: hidden;
  .modal-header {
    margin-top: 50px;
  }

  .modal-content-wrap {
    max-height: 650px;
    overflow-y: auto;
  }
  .modal-content {
    width: calc(100% - 10px);
  }
  .modal-actions {
    position: relative;
    margin-top: -200px;
    padding-bottom: 2rem;
    background-image: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0) 0%,
      #191d29 25%
    );
  }
  &__row {
    width: 100%;
    display: flex;
    margin: 5px 0;
    &:last-child {
      margin-bottom: 30px;
    }
  }
  &__input {
    width: 100%;
    height: 40px;
    background-color: #191d29;
    border-radius: 6px;
    border: 1px solid #3d4463;
    outline: 0;
    color: #ffffff;
    padding: 0 20px;
    font-family: $font-family;
    &::placeholder {
      color: #636b91;
    }
    &:focus {
      border-color: #18c99d;
    }
  }
  &__textarea {
    width: 100%;
    height: 100px;
    background-color: #191d29;
    border-radius: 6px;
    border: 1px solid #3d4463;
    outline: 0;
    color: #ffffff;
    padding: 10px;
    resize: none;
    &::placeholder {
      color: #636b91;
    }
    &:focus {
      border-color: #18c99d;
    }
  }
  &__section-wrap {
    flex: 1;
    padding-right: 20px;
    &:last-child {
      padding-right: 0;
    }
    &.name {
      flex-basis: 100%;
    }
    &.image {
      flex-basis: 70%;
    }
    &.port {
      flex-basis: 30%;
    }
    &.image-empty {
      flex-basis: 50%;
    }
    &.cpu,
    &.ram,
    &.disk-size {
      flex-basis: calc(50% / 3);
    }
    &.gpu-model {
      flex-basis: 40%;
    }
    &.gpu-count {
      flex-basis: 10%;
    }
    &.duration {
      flex-basis: 20%;
    }
    &.duration-empty {
      flex-basis: 80%;
    }
    &.replicas {
      flex-basis: 100%;
    }
    &.tags {
      flex-basis: 100%;
    }
  }
  &__select-label {
    margin: 10px 0;
    color: #8a8fb5;
    display: flex;
  }
  &__row-wrap {
    display: flex;
    width: 100%;
  }
  &__price-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #8a8fb5;
    font-size: 14px;
    &--price {
      margin-top: 5px;
      font-size: 16px;
      color: #ffffff;
    }
  }
  &__number-input-section {
    flex-basis: 50%;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__select-group {
    flex-basis: 50%;
    margin-right: 20px;
    border-radius: 6px;
    border: 1px solid #3d4463;
    padding: 20px 0 10px 0;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: #1f2331;
    }
    &--row {
      display: flex;
      margin-bottom: 10px;
    }
    &--icon {
      flex-basis: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--circle {
      height: 14px;
      width: 14px;
      border-radius: 14px;
      border: 1px solid #3d4463;
      &.active {
        border: none;
        background-color: #18c99d;
      }
    }
    &--text {
      flex-basis: 90%;
      color: #8a8fb5;
      font-size: 14px;
      font-weight: $weight-medium;
      &.title {
        font-size: 15px;
        font-weight: $weight-bold;
      }
    }
  }
  &__error {
    font-size: 16px;
    height: 16px;
    font-weight: $weight-semi;
    color: $color-red;
    margin-bottom: 15px;
  }

  @include breakpoint('mobile') {
    .modal-header {
      margin-top: 2rem;
    }
    .modal-content {
      margin-bottom: 0rem;
    }
    &__row {
      flex-direction: column;
    }
    &__section-wrap {
      padding-right: 0;
      
      // Reset all flex-basis values for mobile
      &.name,
      &.image,
      &.port,
      &.tags,
      &.description {
        flex-basis: 100%;
      }
    }
    
    // Adjust spacing between stacked elements
    &__input,
    &__textarea {
      margin-bottom: 1rem;
    }

    // Ensure select components take full width
    .CreateDeploymentModal__selector {
      width: 100%;
    }
  }
}

.number-input {
  height: 40px;
  display: flex;
  align-items: center;
  min-width: 390px;
  border-radius: 6px;
  border: 1px solid #3d4463;
  &__label {
    height: 100%;
    color: #8a8fb5;
    background-color: #1f2331;
    border-radius: 6px 0 0 6px;
    flex-basis: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__group {
    height: 100%;
    display: flex;
    flex-basis: 70%;
  }
  &__button {
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 20%;
    color: rgba(#8a8fb5, 0.35);
    font-size: 25px;
    user-select: none;
    &:hover {
      color: #8a8fb5;
    }
  }
  &__input {
    outline: 0;
    background-color: transparent;
    color: #8a8fb5;
    flex: 1;
    text-align: center;
    border: none;
    font-size: 16px;
    font-family: $font-family;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  @include breakpoint('mobile') {
    min-width: 100%;
    width: 100%;
    
    &__group {
      width: 100%;
    }
  }
}
