.g-settings-box {
  @include row($align: center, $justify: space-between);
  max-width: 57rem;
  background: #1F2331;
  padding: 3rem;
  border-radius: 1rem;
  margin-bottom: 1rem;

  &.top {
    margin-top: 3rem;
  }

  &.light {
    background: $color-input-background-alt;
  }
  .box-left {
    @include column($align: flex-start);

    .box-title {
      font-weight: $weight-semi;
      color: $color-font-sub;
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }

    .box-value {
      @include row($align: center);
      font-weight: $weight-semi;
      color: $color-font-main;
      font-size: 1.4rem;
      margin-right: 4rem;

      &.hidden {
        font-style: italic;
      }

      &.red {
        color: $color-red;
      }

      &.green {
        color: $color-green;
      }

      .g-icon {
        margin-right: 0.4rem;
        fill: $color-green;
      }
    }

    .box-content {
      @include row($align: flex-end, $justify: space-between);
      width: 100%;

      .input.checkbox {
        font-weight: $weight-semi;

        input[type="checkbox"] {
        }
      }
    }
  }

  .box-buttons {

  }

  .Button {
    min-width: 12rem;
  }

  @include breakpoint('mobile') {
    width: 100%;
    min-width: auto;
    max-width: none;

    .btn {
      min-width: auto;
      width: auto;
    }
  }
}