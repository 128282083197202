.Step{
  display: flex;
  flex-direction: column;
  align-items: center;

  &__number{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50px;
    height: 50px;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
    border-radius: 50%;
    border: 1px solid $color-green;

    @include breakpoint('mobile'){
      width: 40px;
      height: 40px;
      font-size: 19px;
      margin-bottom: 18px;
    }
  }

  &__title{
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;

    @include breakpoint('mobile'){
      font-size: 12px;
      line-height: 14px;
      max-width: 80px;
      text-align: center;
    }
  }
}
