.CreateNotebookModal {
  background-color: #191d29;
  border-radius: 20px;
  padding: 0 4rem 2rem 4rem;
  .modal-header {
    margin-top: 50px;
  }
  .modal-content {
    margin-bottom: 20px;
  }
  &__row {
    width: 100%;
    display: flex;
    margin: 5px 0;
  }
  &__input {
    width: 100%;
    height: 40px;
    background-color: #191d29;
    border-radius: 6px;
    border: 1px solid #3d4463;
    outline: 0;
    color: #ffffff;
    padding: 0 20px;
    font-family: $font-family;
    &::placeholder {
      color: #636b91;
    }
    &.pwd {
      margin-left: 10px;
      flex: 1;
    }
    &.auth {
      margin-left: 20px;
      flex: 1;
    }
    &:disabled {
      cursor: not-allowed;
      color: #636b91;
    }
  }
  &__select-wrap {
    flex: 1;
    padding-right: 20px;
    &:last-child {
      padding-right: 0;
    }
    &.name {
      flex-basis: 100%;
    }
    &.image {
      flex-basis: 70%;
    }
    &.image-empty {
      flex-basis: 50%;
    }
    &.cpu,
    &.ram,
    &.disk-size {
      flex-basis: calc(50% / 3);
    }
    &.gpu-model {
      flex-basis: 40%;
    }
    &.gpu-count {
      flex-basis: 10%;
    }
    &.duration {
      flex-basis: 50%;
    }
    &.duration-empty {
      flex-basis: 50%;
    }
    &.vms {
      flex-basis: 100%;
    }
    &.port {
      flex-basis: 50%;
    }
    &.pwd {
      flex-basis: 50%;
    }
    &.auth {
      flex-basis: 100%;
    }
  }
  &__select-label {
    margin: 10px 0;
    color: #8a8fb5;
    display: flex;
  }
  &__select-content {
    display: flex;
    align-items: center;
    height: 40px;
  }
  &__pwd-placeholder {
    margin-left: 10px;
    color: #636b91;
    border: 1px solid #3d4463;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    display: flex;
    align-items: center;
  }
  &__price-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #8a8fb5;
    font-size: 14px;
    margin-top: 20px;
    &--price {
      margin-top: 5px;
      font-size: 16px;
      color: #ffffff;
    }
  }
  &__error {
    font-size: 16px;
    font-weight: $weight-semi;
    color: $color-red;
    margin: 15px 0;
    text-align: center;
    & a {
      // color: #ffffff;
      color: #18c99d;
      font-weight: $weight-bold;
      cursor: pointer;
    }
  }
  &__switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 22px;
    &--wrap {
      display: flex;
      margin-top: 35px;
    }
    &--label {
      flex: 1;
      margin: 0 20px;
      line-height: 20px;
      font-size: 16px;
      color: #8a8fb5;
    }
  }

  &__switch-input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1f2331;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  &__switch-slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: #8a8fb5;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  &__switch-input:checked + .CreateNotebookModal__switch-slider {
    background-color: rgba(#18c99d, 0.1);
  }

  &__switch-input:focus + .CreateNotebookModal__switch-slider {
    box-shadow: 0 0 1px rgba(#18c99d, 0.1);
  }

  &__switch-input:checked + .CreateNotebookModal__switch-slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    background-color: #18c99d;
  }

  @include breakpoint('mobile') {
    padding: 0 1.5rem 1.5rem 1.5rem;
    border-radius: 12px;

    .modal-header {
      margin-top: 2rem;
    }

    .modal-content {
      margin-bottom: 15px;
    }

    &__row {
      flex-direction: column;
    }

    &__select-wrap {
      padding-right: 0;
      margin-bottom: 10px;

      &.name,
      &.image,
      &.image-empty,
      &.cpu,
      &.ram,
      &.disk-size,
      &.gpu-model,
      &.gpu-count,
      &.duration,
      &.duration-empty,
      &.vms,
      &.port,
      &.pwd,
      &.auth {
        flex-basis: 100%;
      }
    }

    &__select-content {
      height: auto;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }

    &__input {
      height: 40px;
      
      &.pwd,
      &.auth {
        margin-left: 0;
        margin-top: 10px;
        flex: auto;
      }
    }

    &__pwd-placeholder {
      height: 40px;
    }

    &__switch {
      &--wrap {
        flex-direction: column;
        align-items: center;
      }

      &--label {
        margin: 10px 0;
        text-align: center;
      }
    }

    &__price-info {
      margin-top: 15px;
    }
  }
}
