.custom-tooltip {
  background-color: #000;
  padding: 15px;
  &__label {
    color: white;
  }
  &__value {
    color: #18c99d;
    margin-top: 5px;
  }
}
