.Overlay {
  $self: &;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding-top: 150px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(#131722, .5);
  z-index: 2;

  &.disabled {
    background-color: #131722;
  }

  &__wrap {
    background-color: rgba(#131722, 1);
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px;
    border-radius: 20px;
    max-width: 65%;
  }

  &.note {
    padding-top: 50px;
    .Overlay__title {
      color: #8a8fb5;
      margin-bottom: 40px;
    }
  }

  &__title {
    font-size: 21px;
    font-weight: 600;
    color: white;
    line-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    & .g-icon {
      margin-right: 10px;
      padding-top: 5px;
    }
  }

  &__subtitle {
    font-size: 15px;
    color: $color-text-dark;
    line-height: 21px;
    margin-bottom: 20px;
    text-align: center;
  }

  &__content {
    font-size: 18px;
    color: $color-text-dark;
    line-height: 25px;
    margin-top: 30px;
    margin-bottom: 40px;
    color: #ffffff;
    text-align: center;
  }

  @include breakpoint('mobile') {
    padding-top: 80px;

    &__wrap {
      padding: 40px 20px;
      max-width: 90%;
      width: 90%;
    }

    &__title {
      font-size: 18px;
      line-height: 24px;
    }

    &__subtitle {
      font-size: 14px;
      line-height: 20px;
    }

    &__content {
      font-size: 16px;
      line-height: 22px;
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }
}
