.ServiceSidebar {
  display: flex;
  flex-direction: column;
  width: 235px;
  border-right: 1px solid $color-border;

  &__title {
    font-size: 12px;
    font-weight: $weight-bold;
    color: #636b91;
    margin-top: 35px;
    margin-left: 12px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    margin-bottom: 15px;
    cursor: default;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0 12px;
    height: 55px;
    font-size: 14px;
    font-weight: $weight-medium;
    color: #636b91;
    cursor: pointer;
    transition: background-color 0.3s;


    &.ai {
      height: 40px;
      font-size: 16px;
      font-weight: $weight-bold;
    }

    &.disabled {
      cursor: default;
      &:hover {
        color: #636b91;

        .ServiceSidebar__item__icon-wrapper {
          .g-icon {
            fill: #636b91;
          }
        }
      }

      &--active {
        color: #636b91;
        cursor: default;

        .ServiceSidebar__item__icon-wrapper {
          .g-icon {
            fill: #636b91;
          }
        }
      }
    }

    &:hover {
      color: $color-green;

      .ServiceSidebar__item__icon-wrapper {
        .g-icon {
          fill: $color-green;
        }
      }
    }

    &--active {
      color: $color-green;
      cursor: default;

      .ServiceSidebar__item__icon-wrapper {
        .g-icon {
          fill: $color-green;
        }
      }
    }

    &__icon-wrapper {
      width: 24px;
      margin-right: 7px;

      .g-icon {
        fill: #636b91;

        &--active {
          fill: $color-green;
        }
      }
    }
  }

  .SelectInput {
    margin: 0;
    margin-left: 2rem;

    .dropdown-icon {
      position: static;
    }
  }

  @include breakpoint('mobile') {
    width: auto;
    flex-direction: row;
    border-right: none;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    border-bottom: 1px solid $color-border;

    &__title {
      margin: 0;
      letter-spacing: normal;
      text-transform: none;
      font-size: 14px;
    }
  }
}

