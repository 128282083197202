.CreateChatbotToolModal {
  background-color: #191d29;
  border-radius: 20px;
  padding: 0 4rem 2rem 4rem;

  .modal-header {
    margin-top: 50px;
  }

  .modal-content {
    margin-bottom: 20px;
  }

  &__row {
    width: 100%;
    display: flex;
    margin: 5px 0;
    gap: 10px;
    align-items: center;

    &--parameters {
      align-items: flex-start;
    }
  }

  &__input {
    width: 100%;
    height: 40px;
    background-color: #191d29;
    border-radius: 6px;
    border: 1px solid #3d4463;
    outline: 0;
    color: #ffffff;
    padding: 0 20px;
    font-family: $font-family;

    &.sub {
      background: #222838;
    }
    &::placeholder {
      color: #636b91;
    }

    &:hover {
      border-color: white;
    }

    &:focus {
      border-color: #18c99d;
    }

    &:disabled {
      cursor: not-allowed;
      color: #636b91;
    }
  }

  &__textarea {
    width: 100%;
    min-height: 80px;
    background-color: #191d29;
    border-radius: 6px;
    border: 1px solid #3d4463;
    outline: 0;
    color: #ffffff;
    padding: 10px 20px;
    resize: vertical;
    font-family: $font-family;

    &::placeholder {
      color: #636b91;
    }

    &:hover {
      border-color: white;
    }

    &:focus {
      border-color: #18c99d;
    }

    &--body {
      min-height: 120px;
      font-family: monospace;
    }
  }

  &__select-wrap {
    flex: 1;

    &.short {
      flex: 0 0 0;
    }
    
    &.method {
      flex: 0 0 12rem;
      margin-right: 1rem;
    }

    &.endpoint {
      flex: 1;
    }

    &.parameter-name {
      flex: 1;
    }

    &.parameter-desc {
      flex: 2;
    }

    &.parameter-remove {
      flex: 0 0 auto;
      display: flex;
      align-items: flex-start;
      padding-top: 32px;
    }

    &.toggle {
      margin-left: 8.5rem;
    }
    
    .Toggle {
      margin-top: 3px;
    }

    .SelectInput {
      width: 100%;
    }

    &.key {
      flex: 0 0 30%;
    }

    &.value {
      flex: 1;
      margin: 0 10px;
    }
  }

  &__select-label {
    margin: 10px 0;
    color: #8a8fb5;
    font-size: 14px;
    display: flex;
    align-items: center;

    &.white {
      color: #ffffff;
      font-weight: $weight-bold;
    }
    
    &--required::after {
      content: '*';
      color: #ff4c4c;
      margin-left: 4px;
    }
  }

  &__parameters-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 10px;
  }

  &__sub-button {
    padding: 1rem 1rem;
    width: auto;
    min-width: 100px;
  }

  &__actions {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.subs {
      justify-content: flex-end;
    }

    .CreateChatbotToolModal__add-parameter {
      margin-top: 0;
    }

    
    .Button {
      color: $color-green;
      font-size: 1.4rem;
      
      &:last-child {
        color: $color-green;
        font-size: 1.4rem;
        padding: 0;
        background: none;
        
        &:hover {
          color: lighten($color-green, 10%);
        }
      }
    }
  }

  &__add-parameter {
    display: flex;
    justify-content: center;
    background-color: rgba($color-green, 0.1);
    color: $color-green;
    border-radius: 1.7rem;
    padding: 1rem 2rem;
  }

  &__error {
    font-size: 14px;
    color: #ff4c4c;
    margin: 15px 0;
    text-align: center;
  }

  &__parameter-type {
    width: 120px;
    
    .SelectInput {
      width: 100%;
    }
  }

  &__tabs-container {
    margin-top: 20px;
  }

  &__tab-content {
    margin-top: 20px;
    background: #222838;
    border-radius: 6px;
    padding: 20px;
  }

  &__remove-button {
    cursor: pointer;
    fill: #2f344d;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-bottom: 0.6rem;

    &.align-bottom {
      align-self: flex-end;
      margin-bottom: 2.2rem;
    }

    .g-icon {
      width: 16px;
      height: 16px;
    }

    &:hover {
      .g-icon {
        fill: #3d4463;
      }
    }
  }

  &__tab-header {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 1.5rem;

    .Button {
      color: $color-green;
      font-size: 1.4rem;
      padding: 0;
      height: auto;
      background: none;

      &:hover {
        color: lighten($color-green, 10%);
      }
    }
  }
}