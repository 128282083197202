.ItemsList {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ItemsListHeader {
  display: flex;
  font-size: 14px;
  font-weight: $weight-medium;
  color: #636B91;
  padding-bottom: 17px;
  margin-top: 45px;

  div {
    width: 50%;
  }

  &__name {
    padding-left: 24px;
  }
}

.ItemsListItem {
  display: flex;
  position: relative;
  align-items: center;
  background-color: #1F2331;
  border-radius: 10px;
  width: 100%;
  height: 70px;
  cursor: pointer;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: $weight-medium;

  &__name {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    font-size: 14px;
    width: 50%;
    height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &__icon {
      margin-right: 5.75px;
      fill: #636b91;
    }

    &--l {
      font-size: 1.6rem;
    }
  }

  &__url {
    color: $color-text-dark;
    padding-right: 30px;
    height: 16px;
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;

    a {
      text-decoration: underline;
    }
  }

  &__users {
  }

  &__caret {
    position: absolute;
    right: 20px;
    top: calc(50% - 8px);
    color: #8a8fb5;
    width: 16px;
    height: 16px;
    svg {
      fill: #8a8fb5;
    }

    button {
      margin-right: 1rem;
    }
  }

  &__ellipsis {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    color: #8a8fb5;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: #8a8fb5;
    }

    button {
      margin-right: 1rem;
    }
  }

  &__33 {
    width: 33%;
  }

  &:hover {
    background: #282D3C;
  }

  &--current {
    background: #383D4C;
  }



  &__id, &__status, &__location, &__size, &__duration {
    width: 50%;
    color: $color-text-dark;
  }

  .icon-vertical-dots {
    svg {
      fill: #8a8fb5;
    }
  }

  .editable {
    height: 100%;
    .AiServicePage__name-warp {
      padding-right: 3rem;
    }
  }

  @include breakpoint(mobile) {
    &__name {
      padding-left: 1rem;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 2rem;
    }
    &__caret {
      right: 1rem;
    }
    &__url {
    }
  }
}
