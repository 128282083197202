.VerifyEmailPage{
  display: flex;
  flex-direction: column;
  flex: 1;

  &__content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: calc(1280px + 24px);
    padding-left: 12px;
    padding-right: 12px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 90px;
    padding-bottom: 100px;
    width: 100%;
  }

  &__error {
    margin-top: 15px;
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 600;
    color: $color-red;
    text-align: center;
  }

  .Button {
    margin-top: 20px;
  }
}

