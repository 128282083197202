.g-icon {
  &.icon-livestream {
    width: 28px;
    height: 28px;
  }

  &.icon-horizontal-bars {
    width: 22px;
    height: 22px;
  }

  &.icon-video {
    width: 22px;
    height: 22px;
  }
  &.icon-webhook {
    width: 22px;
    height: 22px;
  }

  &.icon-overview {
    margin-top: 4px;
  }

  &.icon-send {
    width: 1.4rem;
    height: 1.4rem;
  }

  &.icon-info {
    width: 1.4rem;
    height: 1.4rem;
  }

  &.icon-refresh {
    width: 1.6rem;
    height: 1.6rem;
  }

  &.icon-copy {
    fill: #636b91;
    width: 2.2rem;
    height: 2.2rem;
  }

  &.icon-upload-icon {
    width: 32px;
    height: 32px;
  }

  &.icon-down-chevron {
    width: 2rem;
    height: 2rem;
  }

  &.icon-delete {
    width: 16px;
    height: 20px;
  }

  &.xl {
    width: 3rem;
    height: 3rem;

    svg {
      width: 3rem;
      height: 3rem;
    }
  }
}
