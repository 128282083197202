html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color:inherit;
  text-decoration: none;
}

*{
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}


//Remove text selections unless it if a form element
//* {
//  cursor: default;
//  -webkit-touch-callout: none; /* iOS Safari */
//  -webkit-user-select: none; /* Safari */
//  -khtml-user-select: none; /* Konqueror HTML */
//  -moz-user-select: none; /* Firefox */
//  -ms-user-select: none; /* Internet Explorer/Edge */
//  user-select: none; /* Non-prefixed version, currently
//                                  supported by Chrome and Opera */
//}

//p, input, textarea{
//  -webkit-touch-callout: default; /* iOS Safari */
//  -webkit-user-select: text; /* Safari */
//  -khtml-user-select: text; /* Konqueror HTML */
//  -moz-user-select: text; /* Firefox */
//  -ms-user-select: text; /* Internet Explorer/Edge */
//  user-select: text; /* Non-prefixed version, currently
//                                  supported by Chrome and Opera */
//}

//// Remove tap highlight on iOS and some Android devices
//* {
//  -webkit-tap-highlight-color: rgba(0,0,0,0);
//  /* For some Androids */
//  -webkit-tap-highlight-color: transparent;
//  tap-highlight-color: transparent;
//}
