.ShowcaseTextToVideo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60rem);

    &__ComingSoon {
      font-size: 5.6rem;
      color: $color-content-background;
      font-weight: $weight-bold;
      margin-top: 2.5rem;
    }
}