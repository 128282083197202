.ShowcaseImageItem {
  display: flex;
  border-radius: 1rem;
  font-size: 1.4rem;
  font-weight: $weight-medium;
  line-height: 1.68rem;
  position: relative;
  align-self: flex-start;
  padding: 2rem 0;
  flex-direction: column;
  align-items: center;

  &:hover &__actions {
    opacity: 1;
  }

  .image {
    max-width: 45rem;
    max-height: 45rem;

    @include breakpoint('mobile') {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__loader {
    width: 45rem;
    height: 45rem;
    background: rgba(white, 0.03);
    display: flex;
    align-items: center;
    justify-content: center;
    //border-radius: 1rem;

    @include breakpoint('mobile') {
      width: 100%;
      height: auto;
      min-height: 20rem;
    }
  }

  &__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $color-font-sub;
    font-weight: $weight-semi;
    margin-top: 1rem;

    .g-icon {
      margin-left: 0.6rem;
    }
  }


  &__actions {
    opacity: 0;
    display: flex;
    align-items: center;
    position: absolute;
    top: -2rem;
    left: 0;
    gap: 1.1rem;

  }

  @include breakpoint('mobile') {
    width: 100%;
    max-width: none;
    @include column($justify: center, $align: center);

    &__actions {
      opacity: 1;
      right: 0;
      left: auto;
      top: auto;
      bottom: -2.4rem;
    }
  }

}