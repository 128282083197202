.AiShowcasesPage {
  width: 100%;
  margin: 3.7rem;

  &__Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__Title {
    font-size: 4rem;
    font-weight: $weight-bold;
    margin-bottom: 1rem;
  }

  &__Subtitle {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: $weight-medium;
    color: $color-font-sub;
    margin-bottom: 4rem;
  }

  &__Terms {
    font-size: 1.4rem;
    color: $color-font-sub;
    margin-top: 0.5rem;
    font-size: 1.3rem;
    text-decoration: underline;
  }

  .Discover {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    color: $color-font-sub;
    font-weight: $weight-medium;
    background: #1F2331;
    border-radius: 1rem;
    width: 44rem;
    height: fit-content;

    &__Left {
      margin-right: 2rem;
    }

    &__Title {
      font-weight: $weight-bold;
      margin-bottom: 0.7rem;
    }

    &__ExploreButton {
      flex-direction: row-reverse;
      padding-left: 2.2rem;
      padding-right: 1.2rem;
      min-width: 13.4rem;

      .g-icon {
        transform: rotate(270deg);
        margin-left: 1rem;
      }
    }
  }

  &__Tabs {
    width: 100%;
    border-bottom: 2px solid #2C334F;
    margin-bottom: 0;

    .Tab {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }

  @include breakpoint('mobile') {
    margin: 1.9rem;
    width: calc(100% - 3.8rem);

    &__Header {
      flex-direction: column;
      align-items: flex-start;
    }

    &__Subtitle {
      padding-bottom: 1rem;
      margin-bottom: 2rem;
    }

    &__MobileTabs {
      width: 100%;

      .dropdown-icon {
        bottom: 1.4rem;
      }
    }

    .Discover {
      margin-right: 0;
      width: 100%;
      margin-bottom: 2rem;
    }

    .Tabs {
      justify-content: center;

      .Tab {
        padding-left: 2rem;
        padding-right: 2rem;

        &--selected{

          &:after{
            left: 2rem;
            right: 2rem;
          }
        }
      }
    }
  }
}