.Button {
  position: relative;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  height: 40px;
  padding-left: 22px;
  padding-right: 22px;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  will-change: background-color;
  min-width: 90px;
  font-family: "Gilroy", -apple-system, system-ui, BlinkMacSystemFont,
    "Helvetica Neue", "Helvetica", sans-serif;

    &:has(.g-icon) {
      padding-left: 12px;
    }

  .g-icon {
    margin-right: 8px;
  }

  &.reverse {
    flex-direction: row-reverse;
  }
  &--icon-only {
    min-width: auto !important;
    .g-icon {
      margin-right: 0px;
    }
  }

  &--green {
    background-color: $color-green;
    color: white;

    &:hover {
      background-color: transparentize($color-green, 0.1);
    }

    &:active {
      background-color: transparentize($color-green, 0.2);
    }
    &:disabled {
      background-color: $color-grey-ui;
      opacity: 0.5;
      cursor: default;
    }
  }

  &--transparent {
    background-color: transparent;
    color: $color-font-sub;

    &:hover {
      // background-color: transparentize(transparent, 0.1);
      color: $color-green;
    }

    &:disabled {
      background-color: $color-grey-ui;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &--transparent-green {
    background-color: transparent;
    color: $color-green;

    &:hover {
      // background-color: transparentize(transparent, 0.1);
      color: $color-green-hover;
    }

    &:disabled {
      background-color: $color-grey-ui;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &--red {
    background-color: #3d4463;
    color: #ff2685;

    &:hover {
      background-color: transparentize(#3d4463, 0.1);
    }

    &:active {
      background-color: transparentize(#3d4463, 0.2);
    }
  }

  &--grey {
    background-color: #3d4463;
    color: white;

    &:hover {
      background-color: transparentize(#3d4463, 0.1);
    }

    &:active {
      background-color: transparentize(#3d4463, 0.2);
    }
    &:disabled {
      background-color: $color-grey-ui;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &--red {
    background-color: #ff2685;
    color: white;

    &:hover {
      background-color: transparentize(#ff2685, 0.1);
    }

    &:active {
      background-color: transparentize(#ff2685, 0.2);
    }
  }

  &--green-outline {
    background-color: transparentize($color-green, 0.9);
    color: $color-green;
    min-width: 180px;

    &:hover {
      background-color: transparentize($color-green, 0.7);
    }

    &:active {
      background-color: transparentize($color-green, 0.7);
    }
    &:disabled {
      background-color: $color-grey-ui;
      color: $color-font-main;
      opacity: 0.5;
      pointer-events: none;
    }

    .g-icon {
      fill: $color-green;
    }
  }

  &--grey-outline {
    border: 1px solid #3d4463;
    color: $color-font-sub;
    background: transparent;

    &:disabled {
      color: $color-font-main;
      opacity: 0.5;
      pointer-events: none;
    }

    .g-icon {
      fill: $color-green;
    }

    &:hover {
      background-color: transparentize(#3d4463, 0.1);
    }

  }

  &--red-outline {
    background-color: transparentize($color-red, 0.9);
    color: $color-red;
    min-width: 180px;

    &:hover {
      background-color: transparentize($color-red, 0.7);
    }

    &:active {
      background-color: transparentize($color-red, 0.7);
    }
    &:disabled {
      background-color: $color-grey-ui;
      opacity: 0.5;
      pointer-events: none;
    }

    .g-icon {
      fill: $color-red;
    }
  }

  &--white-outline {
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
    min-width: 180px;

    &:hover {
      background-color: transparent;
      border-color: rgba(#ffffff, 0.85);
      color: rgba(#ffffff, 0.85);
    }

    &:active {
      background-color: transparent;
    }
    &:disabled {
      background-color: $color-grey-ui;
      opacity: 0.5;
      pointer-events: none;
    }

    .g-icon {
      fill: #ffffff;
    }
  }

  &--xs {
    height: 25px;
    font-size: 12px;
    min-width: 50px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 6px;
 
    &:has(.g-icon) {
      padding-left:8px;
    } }

  &--small {
    height: 34px;
    font-size: 12px;
    min-width: 70px;
    padding-left: 16px;
    padding-right: 16px;

    &:has(.g-icon) {
      padding-left: 16px;
    }
  }

  &--medium {
    min-width: 140px;
  }

  &--large {
    height: 46px;
    font-size: 16px;
    line-height: 19px;
  }

  &--xlarge {
    height: 66px;
    font-size: 20px;
    line-height: 24px;
  }

  .Loader {
    position: absolute;
  }

  &--has-icon {
    justify-content: space-between;
  }

  &--metric {
    height: auto;
    margin-left: 7px;
    padding: 0 3px;
    // border: 1px solid;
    min-width: 70px;
    font-size: 14px;
  }
}

.BackButton {
  position: relative;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  height: 36px;
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  will-change: background-color;
  background-color: transparent;
  font-family: "Gilroy", -apple-system, system-ui, BlinkMacSystemFont,
    "Helvetica Neue", "Helvetica", sans-serif;
  color: $color-text-dark;

  svg {
    margin-right: 6px;
  }
}
.btn {
  cursor: pointer;
  transition: all 0.25s;
  outline: none;
  border: none;

  &.icon {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background: rgba(#7c81a3, 0.45);
    color: #8a8fb5;
    font-weight: 600;
    height: 2.5rem;
    min-width: 2.5rem;
    border-radius: 1.5rem;
    padding: 0 0rem;
    i {
      display: block;
      width: 2rem;
      height: 2rem;
      margin: 0;
      top: -1px;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.next {
      i {
        background-image: url("../../assets/icons/page_next_grey@2x.png");
      }
      &:hover i {
        background-image: url("../../assets/icons/page_next_white@2x.png");
      }
    }
    &.prev {
      i {
        background-image: url("../../assets/icons/page_prev_grey@2x.png");
      }
      &:hover i {
        background-image: url("../../assets/icons/page_prev_white@2x.png");
      }
    }
    &.first {
      i {
        background-image: url("../../assets/icons/page_first_grey@2x.png");
      }
      &:hover i {
        background-image: url("../../assets/icons/page_first_white@2x.png");
      }
    }
    &.last {
      i {
        background-image: url("../../assets/icons/page_last_grey@2x.png");
      }
      &:hover i {
        background-image: url("../../assets/icons/page_last_white@2x.png");
      }
    }
  }
  // transparent
  &.t {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: #8a8fb5;
    font-weight: 600;
    font-size: 1.125rem;
    height: 2.5rem;
    min-width: 2.5rem;
    line-height: 2.5rem;
    border-radius: 1.5rem;
    padding: 0 0.875rem;

    &:hover {
      background: rgba(124, 129, 163, 0.45);
      color: #ffffff;
    }
  }
}