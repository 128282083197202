.ResetPassword {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: calc(1280px + 24px);
    padding-left: 12px;
    padding-right: 12px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 90px;
    padding-bottom: 100px;
  }

  &__title {
    font-size: 30px;
    font-weight: bold;
    line-height: 45px;
    text-align: center;
    margin-bottom: 12px;
    color: #ffffff;
  }

  .ResetPasswordForm {
    min-width: 420px;
    margin-top: 60px;

    @include breakpoint('mobile') {
      min-width: unset;
      width: calc(100vw - 24px);
    }

    &__button {
      margin-top: 10px;
      min-height: 50px;
      font-size: 16px;
      transition: none;
    }

    &__success {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-green;
      font-size: 16px;
      margin-top: 10px;
      min-height: 50px;
      max-width: 420px;
      text-align: center;
    }

    .FormError {
      min-height: 16px;
    }
  }
}
