.QuotaPage {
  display: flex;
  flex-direction: column;
  width: 100%;


  .PageTitleBar {
  }

  .Tabs {
    margin-top: 4rem;
  }

  .Button {
    width: 20rem;
  }

  table {
    max-width: 87rem;
    width: 100%;
    border-collapse: collapse;
    margin-top: 5.5rem;

    th {
      text-align: left;
      font-size: 1.4rem;
      font-weight: $weight-medium;
      color: $color-font-sub;
      padding: 0 2rem 0.6rem 0;
      border-bottom: 1px solid $color-border-bis;
    }

    tr {
      border-bottom: 1px solid $color-border-bis;

      td {
        padding: 1.5rem 2rem 1.5rem 0;
        font-size: 1.4rem;
        font-weight: $weight-medium;

      }
    }
  }

  @include breakpoint('mobile') {
    &__select--label {
      margin-top: 3rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 1.4rem;
      font-weight: $weight-semi;
      color: $color-font-sub;
    }

    .SelectInput {
      width: 100%;
      margin-top: 1rem;
    }

    table {
      margin-top: 3rem;
      font-size: 1.2rem;

      th, td {
        padding: 1rem 1rem 1rem 0;
      }
    }

    .Button {
      width: 100%;
      margin-top: 2rem;
    }
  }
}