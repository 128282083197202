.g-toast {
  @include row($align: center);
  max-width: 25rem;
  margin-left: auto;
  position: absolute;
  right: 0;

  .toast-wrapper {
    @include row($align: center, $justify: flex-end);
    margin-right: 1rem;
    border-radius: 1rem;
    font-weight: $weight-semi;
    padding: 2rem;
    color: white;
    font-size: 1.5rem;
    background-color: $color-toast-success;
    cursor: pointer;

    &--success {
      background-color: $color-toast-success;
    }

    &--error {
      background-color: $color-toast-error;
    }

    .icon {
      margin-left: 1.4rem;
    }
  }
}

.g-toast-container {
  z-index: 20010 !important;
  width: 100%;
  margin-top: 8rem;
}


body.with-header {
  .g-toast {
    top: 6rem;
  }
}

