.ShowcaseChatbot {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &__container {
    display: flex;
    flex-grow: 1;
  }

  &__LeftInfo {
    position: absolute;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    font-weight: $weight-medium;
    margin-top: 2.2rem;
    color: $color-font-sub;
  }

  &__ShowOptionsBtn {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    max-width: 14.5rem;
    font-weight: $weight-medium;
    color: $color-font-sub;
    background: #1F2331;
    border-radius: 1rem;
    padding: 1rem;
    margin-top: 2.6rem;
    cursor: pointer;
    margin-left: 1rem;

    &:hover {
      background: #2F3341;
    }
    &--reverse {
      margin-left: 0;
      margin-top: 1.6rem;

      .g-icon {
        transform: rotate(180deg);
      }
    }

    .g-icon {
      margin-right: 1rem;
      width: 2rem;
      height: 1.6rem;
    }
  }

  &__chatbox {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    border-left: 1px solid $color-border-bis;
    padding-left: 3.8rem;
    height: calc(100vh - 32rem);

    &--no-settings {
      border-left: none;
      padding-left: 0;
    }
  }

  &__messages {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
  }

  &__form {
    display: flex;
    position: relative;
  }

  &__input {
    height: 13rem;
    flex-grow: 1;
    padding: 1.8rem;
    border-radius: 0.6rem;
    background: $color-background;
    border: 1px solid #3D4463;
    color: $color-font-sub;
    font-size: 1.4rem;
    font-family: "Gilroy", -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", sans-serif;
    font-weight: $weight-medium;

    &::placeholder {
      color: rgba($color-font-sub, 0.6);
    }

    &:focus {
      outline: none;
      border-color: rgba($color-green, 0.6);
    }
  }

  &__button {
    position: absolute;
    padding: 0.5rem 1rem;
    cursor: pointer;
    bottom: 1rem;
    right: 1rem;
    width: 10rem;
    height: 3.4rem;
    font-size: 1.4rem;
  }

  &__InputActions {
    position: absolute;
    top: -3rem;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 1.2rem;

    .g-icon {
      cursor: pointer;


    }
  }

  .SettingsPanel {
    width: 44rem;
    padding-right: 3.6rem;
    margin-top: 3.6rem;

    &__InputsWrapper {
      display: flex;
      flex-direction: column;
      background: #1F2331;
      border-radius: 1rem;
      padding: 2rem;
      padding-top: 6rem;
      margin-top: 1.6rem;
    }

    &__HideOptionsBtn {
      display: flex;
      align-items: center;
      margin-bottom: 4.5rem;
      font-size: 1.4rem;
      font-weight: $weight-medium;
      color: $color-font-sub;

      .g-icon {
        margin-right: 1rem;
        width: 2rem;
        height: 1.6rem;
      }
    }


    &__field {
      margin-bottom: 2.2rem;

      &--top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .label-wrapper {
          display: flex;
          align-items: center;
        }

        label {
          font-size: 1.4rem;
          font-weight: $weight-medium;
          color: $color-font-sub;
          margin-right: 0.7rem;
        }

        input {
          width: 7.4rem;
          height: 3.4rem;
          padding-left: 0.8rem;
          border-radius: 0.6rem;
          background: transparent;
          border: 1px solid #3D4463;
          color: $color-font-main;
          font-size: 1.4rem;
          font-family: "Gilroy", -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", sans-serif;
          font-weight: $weight-medium;

          &:focus {
            outline: none;
            border-color: rgba($color-green, 0.6);
          }

        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }

      }

      input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        cursor: pointer;
        width: 36rem;

        &::-webkit-slider-runnable-track {
          -webkit-appearance: none;
          background: transparent;
          height: 0.2rem;
        }
        &::-moz-range-track {
          -moz-appearance: none;
          background: transparent;
          height: 0.2rem;
        }
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          background: $color-green;
          height: 1.5rem;
          width: 1.5rem;
          border: none;
          border-radius: 50%;
          margin-top: -0.6rem;
        }
        &::-moz-range-thumb {
          -moz-appearance: none;
          border: none;
          border-radius: 50%;
          background-color: $color-green;
          height: 1.5rem;
          width: 1.5rem;
        }
        &:focus {
          outline: none;
        }

      }
    }
  }

  @include breakpoint('mobile') {
    height: auto;

    &__container {
      flex-direction: column;
      height: auto;
    }

    &__chatbox {
      height: auto;
      border-left: none;
      padding-left: 0;
    }

    &__messages {
      margin-bottom: 6rem;

    }
    &__form {
      position: fixed;
      background: #1F2331;
      border-radius: 1rem;
      padding: 1rem;
      padding-top: 5rem;
      bottom: 0rem;
      left: 0rem;
      right: 0rem;
    }

    &__input {
      height: 6rem;
    }

    &__button {
      top: 6rem;
      right: 2rem;
      bottom: 2rem;
      height: auto;
    }

    &__InputActions {
      top: 1rem;
      left: 1rem;
      justify-content: flex-end;
    }

    &__ShowOptionsBtn {
      margin-top: 0;
      padding: 0;
      align-self: flex-start;
      margin-right: 2rem;
      margin-left: 1rem;

      &:hover {
        background: #1F2331;
      }

      .g-icon {
        width: auto;
        height: auto;
        fill: $color-font-sub;
      }

    }

    .SettingsPanel {
      position: fixed;
      bottom: 11.5rem;
      z-index: 1;
      left: 0;
      right: 0;
      width: auto;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: 0;
      margin-left: 0;
      padding-right: 0;

      &__InputsWrapper {
        padding-top: 3rem;
        padding-bottom: 1rem;
      }
    }
  }
}

.ChatbotMessageContainer {
  padding-top: 3.4rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  &:first-child {
    margin-top: 2rem;
  }

  &:last-child {
    margin-bottom: 5rem;
  }

  &:hover .ChatbotMessage__actions {
    opacity: 1;
  }
}

.ChatbotMessage {
  padding: 2rem 2.5rem;
  border-radius: 1rem;
  font-size: 1.4rem;
  font-weight: $weight-medium;
  max-width: 80%;
  line-height: 1.68rem;
  display: flex;
  position: relative;

  &--user {
    align-self: flex-end;
    background-color: rgba($color-green, 0.1);
    color: $color-green;
  }

  &--assistant {
    align-self: flex-start;
    background-color: #1F2331;
    color: $color-font-sub
  }

  &--loader {
    align-self: flex-start;
    background-color: transparent;
  }

  &__actions {
    opacity: 0;
    display: flex;
    align-items: center;
    position: absolute;
    top: -4rem;
    left: 0;
    gap: 1.1rem;

    .g-icon {
      cursor: pointer;
    }

    &--user {
      top: -3rem;

      .button {
        display: none;
      }
    }
  }
}
