.g-landing-web3-theatre {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  .content {
    // width: 1280px;
    display: flex;
    flex-direction: column;

    .nav {
      display: flex;
      align-items: center;
      height: 85px;
      border-bottom: 1px solid #2c2f40;

      .logo-wrapper {
        .logo {
          cursor: pointer;
        }
      }
    }

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 145px;

      .cover {
        width: 855px;
        height: 71px;
      }

      .slogan {
        font-weight: $weight-medium;
        font-size: 20px;
        line-height: 24px;
        max-width: 680px;
        text-align: center;
        color: #8a8fb5;
        margin-top: 33px;
      }
    }

    .section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 112px;

      &.right {
        flex-direction: row-reverse;
        .section-content {
          margin-left: 25px;
          margin-right: 0;
        }
      }

      .section-content {
        display: flex;
        flex-direction: column;
        margin-right: 25px;
        margin-left: 0;

        .section-title {
          font-weight: $weight-bold;
          font-size: 50px;
          line-height: 54px;
          max-width: 460px;
        }

        .section-desc {
          max-width: 500px;
          margin-top: 30px;
          color: #8a8fb5;
          font-size: 18px;
          line-height: 24px;
          font-weight: $weight-regular;
        }
      }

      .section-image-wrapper {
        width: 670px;
        height: 360px;

        .section-image {
          width: 100%;
          height: 100%;
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 137px;
      margin-bottom: 137px;

      .footer-content {
        font-weight: $weight-bold;
        font-size: 22px;
        line-height: 28px;
        text-align: center;
        width: 394px;
      }

      .button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: #18c99d;
        border-radius: 12px;
        width: 236px;
        height: 66px;
        font-weight: $weight-semi;
        font-size: 20px;
        margin-top: 50px;
        cursor: pointer;

        img {
          margin-left: 14.44px;
        }
      }
    }
  }

  @include breakpoint("mobile") {
    .content {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;

      .nav {
        height: 70px;
      }

      .header {
        margin-top: 20px;
        .cover {
          width: 100%;
          height: auto;
        }
        .slogan {
          text-align: left;
        }
      }

      .section {
        flex-direction: column-reverse;
        margin-top: 60px;

        &.right {
          flex-direction: column-reverse;
        }

        .section-content {
          margin-top: 30px;

          .section-title {
            width: auto;
            font-size: 30px;
            line-height: 33px;
          }
          .section-desc {
            width: auto;
            margin-top: 10px;
          }
        }
        .section-image-wrapper {
          width: 100%;
          height: auto;

          .section-image {
            width: 100%;
            height: auto;
          }
        }
      }
      .footer {
        margin-top: 50px;
        margin-bottom: 50px;

        .footer-content {
          width: auto;
        }

        .button {
          margin-top: 30px;
        }
      }
    }
  }
}
