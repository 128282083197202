.Toggle {
  position: relative;
  display: inline-block;
  width: 5.7rem;
  height: 3rem;
  
  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .Toggle__slider {
      background-color: #084436;
      
      &:before {
        transform: translateX(2.4rem);
        background-color: #00B07C;
      }
    }

    &:focus + .Toggle__slider {
      box-shadow: 0 0 1px #00B07C;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    .Toggle__slider {
      cursor: not-allowed;
    }
  }

  .Toggle__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2A3147;
    transition: .4s;
    border-radius: 34px;
    border: 1px solid #3D4463;

    &:before {
      position: absolute;
      content: "";
      height: 2.2rem;
      width: 2.2rem;
      left: 0.5rem;
      bottom: 0.3rem;
      background-color: $color-grey-ui;
      transition: .4s;
      border-radius: 50%;
      box-sizing: border-box;
    }

    &:hover {
      border-color: $color-grey-ui;
    }
  }
} 