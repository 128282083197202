.DrawingCanvas {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;

  &__Stage {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .konvajs-content {
      cursor: crosshair;
    }
  }

  &__Menu {
    position: absolute;
    right: 0.4rem;
    bottom: 0.4rem;
    display: flex;
    align-items: flex-end;
    flex-direction: column-reverse;
    justify-content: flex-end;
    background: $color-input-background-alt;
    border-radius: 0.8rem;
    padding: 0.7rem;

    &__IconWrapper {
      cursor: pointer;
      fill: #636b91;

      &:hover {
        fill: $color-green;
      }

      &--active {
        fill: $color-green;
      }

      &--disabled {
        fill: #636b91;
        cursor: default;

        &:hover {
          fill: #636b91;
        }
      }

      .icon-back {
        margin-bottom: 0.7rem;

        svg {
          padding: 0.2rem;
        }
      }
    }
  }

  &__Controls {
    position: absolute;
    display: flex;
    width: 22rem;
    background: $color-input-background-alt;
    border-radius: 0.8rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    bottom: -0.2rem;
    right: 4.8rem;

    &__Item {
      width: 100%;
      padding: 0.8rem;
      margin-bottom: 0.4rem;
      padding-right: 1.2rem;
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: space-between;

      .g-icon {
        width: 3rem;
        height: 3rem;
        fill: $color-font-sub;
        stroke: $color-font-sub;
      }

      input[type="number"] {

      }
      input[type="color"] {
        //  remove white spacing around input
        padding: 0;
        border: none;
        width: 2rem;
        height: 2rem;
        border-radius: 2rem;
      }

      .ShowcaseSettingsPanel__field {
        margin-bottom: 0rem;
      }

      button {
        margin-left: 15px;
        font-size: 14px;
        color: #fff;
        background-color: #007bff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }

      button:hover {
        background-color: #0056b3;
      }

    }

  }
}
