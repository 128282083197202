.ServiceContainer {
  display: flex;
  width: calc(100% - 70px);
  position: relative;

  @include breakpoint("mobile") {
    flex-direction: column;
    width: 100%;
  }
}
