.pagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  margin: 2rem auto 0;

  .btn {
    flex: 0 0 auto;
    margin-right: 0.875rem;

    &:last-child {
      margin-right: 0rem;
    }
    &.active {
      color: #ffffff;
    }
    &.first,
    &.last,
    &.prev,
    &.next {
      padding-left: 0;
      padding-right: 0;
      i {
        display: block;
        width: 32px;
        height: 32px;
        margin: 0;
        top: -1px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.first {
      margin-right: 1.5rem;
    }
    &.last {
      margin-left: 1.5rem;
    }
    &.next {
      margin-left: 2rem;
    }
    &.prev {
      margin-right: 2rem;
    }
  }
  .ellipse {
    margin-right: 1rem;
    color: #8A8FB5;
    &[disabled] {
      opacity: 0.5;
    }
  }
}
