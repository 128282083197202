.CreateVideoPage {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: calc(1280px + 24px);
    padding-left: 12px;
    padding-right: 12px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    width: 100%;
    padding-bottom: 100px;
    &--subtitle {
      margin: 30px 0;
      font-size: 20px;
    }

    .PageTitleBar {
      margin-bottom: 45px;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: bold;
    line-height: 45px;
    text-align: center;
    margin-bottom: 12px;
    color: #ffffff;
  }

  &__state {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 600;
  }

  &__warning {
    margin-top: 15px;
    font-size: 12px;
    font-weight: 600;
    color: $color-red;
  }

  &__error {
    margin-top: 25px;
    font-size: 16px;
    font-weight: 600;
    color: $color-red;
    z-index: 1;
    & a {
      color: #18c99d;
      font-weight: $weight-bold;
      cursor: pointer;
    }
  }

  .CreateVideoForm {
    min-width: 380px;
    max-width: 700px;
    width: 100%;

    &__label {
      display: flex;
      flex-direction: row;
      font-size: 14px;
      font-weight: $weight-medium;
      margin-bottom: 15px;
      color: $color-text-sub;

      .icon-info {
        margin-left: 3rem;
      }
    }

    &__default-input {
      padding: 20px;
      background: $color-input-background-alt;
      border-radius: 8px;
      border: none;
      font-size: 14px;
      font-weight: $weight-medium;
      color: $color-text-sub;
      margin-bottom: 0;
    }

    &__or {
      text-align: center;
      margin-bottom: 18px;
      margin-top: 18px;
      font-size: 14px;
      color: #575c77;
      font-weight: $weight-bold;
    }

    .choose-file-text {
      font-weight: $weight-semi;
      color: $color-green;

      &:hover {
        color: $color-green-hover;
      }
    }

    &__encode-options {
      display: flex;
      flex-direction: row;
      height: 40px;
    }

    &__encode-option {
      flex-grow: 1;
      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
      background: $color-input-background-alt;
      font-weight: $weight-semi;
      color: $color-text-sub;
      padding-top: 12px;
      padding-bottom: 10px;

      &.active,
      &:hover {
        background: rgba(24, 201, 157, 0.1);
        color: $color-green;
        cursor: pointer;
      }

      &.left {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &.right {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }

    &__drm-title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 24px;
      margin-top: 48px;
      text-align: center;
    }

    &__section {
      display: flex;
      margin-bottom: 10px;
    }

    &__drm {
      display: flex;
      flex-direction: column;
      padding: 35px 32px 0;
      background: #11141f;
      margin-top: 30px;
      margin-left: -20px;
      margin-right: -20px;
      border-radius: 18px;

      .drm-details {
        display: flex;
        flex-direction: column;
        width: 100%;
        transition: 0.2s ease-in;
        opacity: 1;
        //overflow: hidden;

        &.hidden {
          max-height: 0;
          transition: 0.2s ease-out;
          opacity: 0;
        }
      }
      &--enable-drm {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 35px;

        &--toggle-wrapper {
          position: relative;
          display: inline-block;
          width: 57px;
          height: 30px;
        }
        &--toggle {
          opacity: 0;
          width: 0;
          height: 0;
        }
        &--slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #1f2331;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 30px;

          &:before {
            position: absolute;
            content: "";
            height: 23px;
            width: 23px;
            left: 4px;
            bottom: 4px;
            background-color: #575c77;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
          }
        }
        &--toggle:checked + .CreateVideoForm__drm--enable-drm--slider {
          background: rgba(24, 201, 157, 0.1);
        }
        &--toggle:checked + .CreateVideoForm__drm--enable-drm--slider:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
          background-color: $color-green;
        }

        &--texts {
          margin-left: 28px;
        }
        &--title {
          font-size: 14px;
          font-weight: $weight-bold;
          color: white;
          margin-bottom: 10px;
        }
        &--description {
          font-size: 13px;
          font-weight: $weight-semi;
          color: $color-text-sub;
        }
      }
    }

    .CreateVideoForm__use-studio-drm {
      display: flex;
      flex-direction: row;

      .g-tooltip-trigger {
        width: 50%;

        .CreateVideoForm__use-studio-drm--option {
          width: 100%;
        }
      }

      &--option {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 140px;
        width: 50%;
        text-align: center;
        background: $color-input-background-alt;
        color: $color-text-sub;
        font-size: 14px;
        border-radius: 6px;

        &.active,
        &:hover {
          background: rgba(24, 201, 157, 0.1);
          color: $color-green;
          border: 1px solid #0a5442;
        }

        &:hover {
          cursor: pointer;
        }

        &.active {
          cursor: default;
        }

        &.disabled {
          background: #1f2331;
          color: #575c77;
          cursor: not-allowed;
          border: none;
        }

        &.left {
          margin-right: 8px;
        }

        &.right {
          margin-left: 8px;
        }

        &--title {
          text-transform: uppercase;
          font-weight: $weight-bold;
          letter-spacing: 2.1px;
        }

        &--desc {
          margin-top: 18px;
          font-weight: $weight-medium;
        }
        &--badge {
          top: 12.5px;
          position: absolute;
        }
      }
    }

    &__nft-collection-title {
      font-size: 14px;
      font-weight: $weight-bold;
      margin-bottom: 15px;
      color: $color-text-sub;
      margin-top: 60px;
      letter-spacing: 2.1px;
      text-transform: uppercase;
      text-align: center;
    }

    &__nft-collection-desc {
      font-size: 13px;
      font-weight: $weight-semi;
      color: $color-text-sub;
      text-align: center;
      margin-bottom: 30px;
    }

    &__nft-collection--add {
      margin-top: 20px;
      margin-bottom: 35px;
      cursor: pointer;
      align-self: center;
      font-size: 13px;
      font-weight: $weight-semi;
      color: $color-green;
      text-align: center;
      background: $color-input-background-alt;
      border-radius: 8px;
      padding: 12px 20px;
      transition: 0.4s;
      height: 40px;
      width: 200px;
      -webkit-transition: 0.4s;

      &:hover {
        background: rgba(24, 201, 157, 0.1);
      }
    }

    &__drm-left {
      display: flex;
      flex-direction: column;
      width: 38%;
      margin-right: 8px;
    }

    &__drm-right {
      display: flex;
      flex-direction: column;
      width: 62%;
    }

    &__drm-address {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      background: $color-input-background-alt;
      border-radius: 8px;
      padding-right: 10px;
      margin-bottom: 0.3rem;

      &--title {
        font-size: 16px;
        width: 38%;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      &--input {
        padding: 12px;
        border: none;
        font-size: 14px;
        font-weight: $weight-medium;
        color: $color-text-sub;
        margin-bottom: 0;
      }

      &--close-btn {
        cursor: pointer;
      }
    }

    &__drm_description {
      color: $color-text-dark;
      font-size: 14px;
      font-weight: 600;
      max-width: 486px;
      text-align: center;
      margin: 8px auto 20px;
      line-height: 1.2rem;
    }

    &__selects {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 30px;
    }

    &__select {
      width: 48%;
      $self: &;

      &--title {
        margin-bottom: 15px;
        font-size: 16px;
      }
      &--wrap {
        & > div {
          margin: 0;
          max-width: 100%;
        }
        & input {
          padding: 0;
          width: 0;
        }
      }
      &--note {
        font-size: 13px;
        color: $color-text-sub;
        margin-top: 17px;
      }
      &--note-info {
        width: 12px;
        padding-top: 2px;
        margin-right: 5px;
      }
    }

    &__filename {
      margin-top: 40px;
    }

    &__wh_title {
      margin: 20px 0;
    }
  }

  .css-hwg6uv-control,
  .css-1za41q-control {
    background: transparent;
    border: 1px solid;
    border-radius: 6px;
    height: 40px;
    box-shadow: none;

    &:hover {
      border-color: rgba($color-green, 0.7);
    }
  }

  .css-hwg6uv-control {
    border-color: #3d4463;
  }
  .css-1za41q-control {
    border-color: rgba($color-green, 0.7);
  }

  .css-fjqyky-menu {
    border: none;
    border-radius: 6px;
    background: $color-input-background-alt;
    margin-top: 5px;
    color: $color-text-sub;
  }

  .select-custom-option {
    display: flex;
    align-items: center;
  }
}
