.CreditSelection {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  &.disabled {
    .CreditSelection__option {
      cursor: default;
      color: rgba(#8a8fb5, 0.3);
      border-color: rgba(#3d4463, 0.3);
      &:hover {
        color: rgba(#8a8fb5, 0.3);
        border-color: rgba(#3d4463, 0.3);
      }
    }
  }
  &__option-wrap {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  &__option {
    font-size: 18px;
    height: 40px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 15px 10px;
    border: 1px solid #3d4463;
    cursor: pointer;
    // width: 25%;
    &:hover {
      color: rgba($color-green, 0.8);
      border-color: rgba($color-green, 0.8);
    }
    &.selected {
      color: $color-green;
      border-color: $color-green;
    }

    &.disabled {
      cursor: not-allowed;
      color: rgba(#8a8fb5, 0.3);
      border-color: rgba(#3d4463, 0.3);
    }
  }
}
