.UsagePage {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 450px;

  .PageTitleBar {
    margin-bottom: 55px;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    border-top: 1px solid #24283b;
    padding-top: 70px;
  }

  &__chart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    
    &--tooltip {
      font-size: 18px;
      display: flex;
      justify-content: center;
    }
    &--note {
      font-size: 32px;
      color: #3d4463;
      font-weight: 600;
    }
    .recharts-tooltip-cursor {
      fill: "red" !important;
    }
  }

  &__selector {
    margin-left: 10%;
    margin-bottom: 15px;
  }

  &__selector-label {
    margin: 10px 0 10px 10%;
    &.top {
      margin-top: 50px;
    }
  }

  &__selector-wrap {
    border-left: 1px solid #24283b;
    min-width: 345px;
    .react-datepicker-wrapper {
      width: 90%;
      margin-left: 10%;
      input {
        height: 4rem;
        border-radius: 6px;
        border: 1px solid #3d4463;
        padding: 0 2rem;
        background: #131722;
        font-size: 1.4rem;
        color: white;
        font-weight: $weight-medium;
        width: 100%;
        &::placeholder {
          color: #636b91;
        }
        &:focus {
          outline: 0;
          border-color: $color-input-focus;
        }
        &:disabled {
          opacity: 0.5;
        }
      }
      font-size: 14px;
    }
  }

  &__button {
    width: 90%;
    margin-left: 10%;
    margin-top: 90px;
  }

  @include breakpoint('mobile') {
    .PageTitleBar {
      margin-bottom: 2rem;
    }

    &__content {
      padding: 2rem 1rem;
      flex-direction: column;
      gap: 2rem;
    }

    &__chart {
      width: 100%;
      
      &--container {
        overflow-x: auto;
      }
      
      &--tooltip {
        font-size: 14px;
        padding: 0 1rem;
        text-align: center;
      }
    }

    &__selector-wrap {
      width: 100%;
      min-width: unset;
      border-left: none;
      border-top: 1px solid #24283b;
      padding-top: 2rem;

      .react-datepicker-wrapper {
        width: 100%;
        margin-left: 0;
      }
    }

    &__selector {
      width: 100%;
      margin-left: 0;
    }

    &__selector-label {
      margin: 10px 0;
      
      &.top {
        margin-top: 2rem;
      }
    }

    &__button {
      width: 100%;
      margin-left: 0;
      margin-top: 2rem;
    }
  }
}
