.ShowcaseSketch {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &__container {
    display: flex;
    flex-grow: 1;
  }

  &__LeftSide {
    width: 65rem;
    margin-right: 3.8rem;

  }

  &__ImageInputWrapper {
    margin-top: 2rem;
    margin-bottom: 4rem;
    padding: 2rem;
    background: #191D29;
    border-radius: 0.8rem;

    .ImageInput {
      width: 100%;
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 55rem;

      &__ImageUploaded {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        border-radius: 0;

        &__Close {
          background: #191D29;
          padding: 1rem;
          top: 0;
          right: 0;

          &:hover {
            background: #2D3443;
          }
          .g-icon {
            width: 2.5rem;
            height: 2.5rem;
            svg {
              width: 2.5rem;
              height: 2.5rem;
            }

          }
        }
      }

      input {
        width: 100%;
        height: 55rem;
        cursor: pointer;
      }

      &__Label {
        flex-direction: column;
        gap: 0.5rem;
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &__input {
      height: 10rem;
      flex-grow: 1;
      padding: 1.8rem;
      border-radius: 0.6rem;
      background: transparent;
      border: 1px solid #3D4463;
      color: $color-font-sub;
      font-size: 1.4rem;
      font-family: "Gilroy", -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", sans-serif;
      font-weight: $weight-medium;
      margin-bottom: 1.75rem;
      resize: none;

      &::placeholder {
        color: rgba($color-font-sub, 0.6);
      }

      &:focus {
        outline: none;
        border-color: rgba($color-green, 0.6);
      }
    }


  }


  &__RightSide {
    width: 100%;
    max-width: 82rem;
    padding-top: 2rem;
  }

  .ErrorMessage {
    color: $color-red;
    font-weight: $weight-semi;
    height: 1.4rem;
    margin: 2.3rem 0 1.7rem 0;
  }

  &__Prompts {
    display: flex;
    flex-direction: column;
    gap: 1.55rem;
    background: #191D29;
    border-radius: 1rem;
    padding: 1.7rem 1.5rem;

    .TextInput {

      input {
        background: transparent;
        border: 1px solid #3D4463;
        border-radius: 0.6rem;
        color: $color-font-sub;
        font-size: 1.4rem;
        font-family: "Gilroy", -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", sans-serif;
        font-weight: $weight-medium;
        padding: 1rem;
        width: 100%;
        resize: none;

        //  focus
        &:focus {
          outline: none;
          border-color: $color-green;
        }
      }
    }

  }

  &__ResultBoxes {
    display: flex;
    gap: 4.3rem;
    margin-top: 2.6rem;
  }

  &__ResultWrapper {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    width: 50%;
    position: relative;

    &__LeftBadge {
      position: absolute;
      background: #3D4463;
      color: $color-font-sub;
      font-size: 1.1rem;
      font-weight: $weight-semi;
      padding: 0.3rem 0.6rem;
      border-radius: 0.4rem;
      left: 2rem;
      top: 2rem;
    }
  }

  .ResultBox {
    background: #191D29;
    border-radius: 1rem;
    width: 100%;
    aspect-ratio: 1/1;

    &__Idle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      align-items: center;
      color: #3D4463;
      font-weight: $weight-bold;
    }

    &__Loading {
      position: relative;
      display: flex;
      padding: 1.4rem 2rem 3rem;
      justify-content: center;
      align-items: center;
      height: 100%;

      &__TopWrapper {
        position: absolute;
        top: 2rem;
        right: 2rem;
        text-align: center;
        color: $color-font-main;
        font-weight: $weight-medium;
        font-size: 1.2rem;
      }

      &__CenterWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        color: $color-green;
        font-weight: $weight-medium;
        font-size: 1.4rem;
        gap: 1rem;
      }

      &__BottomWrapper {
        position: absolute;
        bottom: 2.5rem;
        left: 0;
        right: 0;
        text-align: center;
        color: $color-font-sub;
        font-weight: $weight-medium;
        font-size: 1.4rem;
      }
    }

    &__Image {
      position: relative;
      display: flex;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__DownloadWrapper {
       position: absolute;
       top: 2rem;
       right: 2rem;

       .g-icon {
         width: 2.5rem;
         height: 2.5rem;
         cursor: pointer;

         svg {
           width: 2.5rem;
           height: 2.5rem;
         }
       }
     }
  }

  &__Title {
    font-weight: $weight-medium;
    color: $color-font-sub;
    margin-top: 2.2rem;
  }

  @include breakpoint('mobile') {

    &__container {
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;
    }

    &__LeftSide {
      margin-bottom: 0;
      margin-top: 0;
      margin-right: 0;
      border: none;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__RightSide {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

    }

    &__Title {
      align-self: flex-start;
      margin-top: -1rem;
      margin-bottom: 2rem;
    }

    &__ImageInputWrapper {
      margin-right: 0;
      width: 100%;
      max-width: 55rem;
      margin-top: 0;
      margin-bottom: 0;

      .ImageInput {
        width: 100%;
        aspect-ratio: 1/1;
        height: auto;

        input {
          width: 100%;
          height: auto;
        }

      }
    }

    .ErrorMessage {
      margin-bottom: 0.4rem;

    }
    &__Prompts {
      width: 100%;
      margin-top: 0;
    }

    &__ResultBoxes {
      flex-direction: column;
      gap: 2rem;
      margin-top: 2rem;
      width: 100%;
    }

    &__ResultWrapper {
      width: 100%;
    }

    .ResultBox {
      width: 100%;

      &__Image {
        img {
          max-width: 42rem;
        }
      }

    }

  }
}
