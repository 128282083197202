.ShowcaseSettingsPanel {
  margin-top: 3.6rem;

  &__InputsWrapper {
    display: flex;
    flex-direction: column;
  }

  &__HideOptionsBtn {
    display: flex;
    align-items: center;
    margin-bottom: 4.5rem;
    font-size: 1.4rem;
    font-weight: $weight-medium;
    color: $color-font-sub;

    .g-icon {
      margin-right: 1rem;
      width: 2rem;
      height: 1.6rem;
    }
  }


  &__field {
    margin-bottom: 2.2rem;

    &--top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label-wrapper {
        display: flex;
        align-items: center;
      }

      label {
        font-size: 1.4rem;
        font-weight: $weight-medium;
        color: $color-font-sub;
        margin-right: 0.7rem;
      }

      input {
        width: 7.4rem;
        height: 3.4rem;
        padding-left: 0.8rem;
        border-radius: 0.6rem;
        background: transparent;
        border: 1px solid #3D4463;
        color: $color-font-main;
        font-size: 1.4rem;
        font-family: "Gilroy", -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", sans-serif;
        font-weight: $weight-medium;

        &:focus {
          outline: none;
          border-color: rgba($color-green, 0.6);
        }

        &.large {
          width: 17rem;
        }

      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }

    }

    input[type="range"] {
      -webkit-appearance: none;
      appearance: none;
      background: transparent;
      cursor: pointer;
      width: 100%;

      &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        background: transparent;
        height: 0.2rem;
      }

      &::-moz-range-track {
        -moz-appearance: none;
        background: transparent;
        height: 0.2rem;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        background: $color-green;
        height: 1.5rem;
        width: 1.5rem;
        border: none;
        border-radius: 50%;
        margin-top: -0.6rem;
      }

      &::-moz-range-thumb {
        -moz-appearance: none;
        border: none;
        border-radius: 50%;
        background-color: $color-green;
        height: 1.5rem;
        width: 1.5rem;
      }

      &:focus {
        outline: none;
      }

    }
  }
}