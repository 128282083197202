.accordion {
  background-color: #191d29;
  margin: 20px 0;
  width: 100%;
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    border-radius: 5px 5px 0 0;
    border: 1px solid #3d4463;
    cursor: pointer;
    &:hover {
      // background-color: #000;
      border-color: $color-green;
    }
    & label {
      cursor: pointer;
      color: #8a8fb5;
      display: flex;
      align-items: center;
      & > span {
        margin-left: 5px;
        color: #8a8fb5;
      }
    }
    &--arrow {
      color: #fff;
      transition: 0.2s ease-in-out;
      // &.downwards {
      //   transform: rotate(-90deg);
      // }
    }
  }
  &__body {
    border-radius: 0 0 5px 5px;
    border-top: none !important;
    max-height: 0;
    overflow: hidden;
    margin-top: -1px;
    transition: max-height 0.2s ease-out;
    &--inner {
      padding: 10px;
    }
  }
}
