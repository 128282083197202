.ShowcaseTextToImage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &__container {
    display: flex;
    flex-grow: 1;
  }

  &__LeftInfo {
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    font-weight: $weight-medium;
    color: $color-font-sub;
    margin-bottom: 3rem;
  }

  &__chatbox {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    border-left: 1px solid $color-border-bis;
    padding-left: 3.8rem;
    height: calc(100vh);
  }

  &__messages {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
  }

  .SidePanel {
    background: #1F2331;
    border-radius: 1rem;
    padding: 1.4rem 2rem 3rem;
    margin-top: 3.4rem;
    width: 44rem;
    height: fit-content;
    margin-right: 3.8rem;

    &__Title {
      margin-top: 0.5rem;
      margin-bottom: 1.5rem;
      font-weight: $weight-bold;
      color: white;
    }

    &__Select {
      width: 100%;
    }

    &__Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.45rem;

      &__Prompt {
        font-weight: $weight-bold;
      }
      &__InputActions {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 1.2rem;

        .g-icon {
          cursor: pointer;


        }
      }

    }
    &__form {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &__input {
      height: 10rem;
      flex-grow: 1;
      padding: 1.8rem;
      border-radius: 0.6rem;
      background: transparent;
      border: 1px solid #3D4463;
      color: $color-font-sub;
      font-size: 1.4rem;
      font-family: "Gilroy", -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", sans-serif;
      font-weight: $weight-medium;
      margin-bottom: 1.75rem;
      resize: none;

      &::placeholder {
        color: rgba($color-font-sub, 0.6);
      }

      &:focus {
        outline: none;
        border-color: rgba($color-green, 0.6);
      }
    }

    &__button {
      padding: 0.5rem 1rem;
      cursor: pointer;
      width: 10rem;
      height: 3.4rem;
      font-size: 1.4rem;
    }

    &__OutputOptions {
      display: flex;
      align-items: center;
      color: $color-font-main;
      font-weight: $weight-bold;
      cursor: pointer;
      margin-top: 3rem;

      &.open {
        .g-icon {
          transform: rotate(180deg);
        }
      }

      .g-icon {
        margin-left: 0.7rem;
        fill: #8A8FB5;
      }
    }
  }


  .UserMessageContainer {
    padding-top: 0;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    &.isContest {
      padding-top: 3.4rem;
    }

    .UserMessage {
      margin-top: 3rem;
      padding: 2rem 2.5rem;
      border-radius: 1rem;
      font-size: 1.4rem;
      font-weight: $weight-medium;
      width: 66rem;
      max-width: 100%;
      line-height: 1.68rem;
      display: flex;
      flex-direction: column;
      position: relative;
      align-self: flex-end;
      background: $color-dark-grey;
      color: $color-green;
      margin-bottom: 2rem;

      &__actions {
        display: flex;
        align-items: center;
        position: absolute;
        top: -4rem;
        left: 0;
        gap: 1.1rem;

        .Button--medium {
          min-width: auto;
          width: auto;
        }

        .g-icon {
          width: 1.8rem;
          height: 1.8rem;

          svg {
            width: 1.8rem;
            height: 1.8rem;

          }
        }
      }

      &__PromptLabel {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: $weight-bold;
        color: $color-font-main;
        margin-bottom: 1.2rem;

        .g-icon {
          margin-left: 0.6rem;
        }
      }

      &__PromptValue {
        font-weight: $weight-medium;
        color: #3D4463;
        margin-bottom: 2rem;
      }

      &__BottomWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      &__ParamsButton {
        display: flex;
        align-items: center;
        color: $color-font-sub;
        cursor: pointer;

        &.open {
          .g-icon {
            transform: rotate(180deg);
          }
        }

        .g-icon {
          margin-left: 0.7rem;
          fill: #8A8FB5;
        }
      }

      &__Params {
        pre {
          font-size: 1.4rem;
          font-weight: $weight-medium;
          color: $color-font-sub;
          margin-top: 1.2rem;
          white-space: pre-line;
        }
      }
    }
  }


  .BotMessageContainer {
    padding-top: 3.4rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    gap: 4rem;
    width: 100%;

    &:first-child {
      margin-top: 2rem;
    }

    &:last-child {
      margin-bottom: 5rem;
    }

  }

  @include breakpoint('mobile') {
      width: 100%;
      max-width: 100%;

    .icon-down-chevron {
      width: auto;
      height: auto;
    }
    &__container {
      flex-direction: column;
      margin-top: 2rem;
    }

    &__chatbox {
      border-left: none;
      padding-left: 0;
      height: auto;
    }

    &__LeftInfo {
      margin-bottom: 0;
      margin-top: 2rem;
    }
    .SidePanel {
      margin-right: 0;
      width: 100%;
      margin-top: 0;

      &__Header {
        margin-bottom: 1.2rem;

        &__InputActions {

          .icon-up-bar, .icon-down-bar {
            display: none;
          }
        }
      }

      &__input {
        height: 7.5rem;
      }
    }

    .BotMessageContainer {
      padding-top: 0;
      margin-bottom: 0;
    }

    &__messages {
      flex-direction: column-reverse;

      .UserMessage {
        width: 100%;
        margin-top: 4rem;
      }

      .BotMessageContainer {
        margin-top: 3.4rem;
        flex-direction: column-reverse;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
