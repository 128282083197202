.CreateDocumentModal {
    padding-left: 4rem;
    padding-right: 4rem;

    .NewFormError {
        color: #ff1744;
        margin-bottom: 1rem;
        font-size: 0.875rem;
    }

    .CreateRAGChatbotModal__textarea {
        width: 100%;
        min-height: 15rem;
        padding: 1rem;
        border: 0.1rem solid #3d4463;
        border-radius: 0.6rem;
        background: transparent;
        color: #636b91;
        resize: vertical;
        font-size: 1.4rem;

        &--metadata {
            min-height: 10rem;
        }

        &:focus {
            outline: none;
            border-color: #18C99D;
        }
    }

    .choose-file-text {
        color: $color-green;
    }

    &--label {
        font-size: 1.6rem;
        margin-bottom: 2rem;
        align-self: flex-start;
        font-weight: $weight-semi;
        cursor: default;

        &.top-margin {
            margin-top: 4rem;
        }
    }

    .container {
        width: 100%;
    }
    &--tabs {
        display: flex;
        gap: 1rem;
        width: 100%;
        margin-bottom: 1rem;

        justify-content: flex-start;
        border-bottom: 0.1rem solid #282B3B;
    }

    &--tab {
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 1rem;
        padding: 0.5rem 1rem 1rem;
        cursor: pointer;
        border-bottom: 0.2rem solid transparent;
        color: #636B91;

        &.active {
            color: #18C99D;
            border-bottom-color: #18C99D;
        }

        &:hover:not(.disabled) {
            color: #18C99D;
        }

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    &__error {
        color: #ff1744;
        margin-top: 0.5rem;
        font-size: 0.875rem;
    }

    .modal-actions {
        margin-top: 2rem;
    }

    @include breakpoint('mobile') {
        flex-direction: column;

        &__tabs {
            display: flex;
            gap: 1rem;
            flex-direction: row;
            align-items: center;
            width: 100%;
            border-bottom: 0.1rem solid #3d4463;
            padding: 0;
        }

        &__tab {
            flex-grow: 1;
            text-align: center;
            margin-bottom: 0;
            padding: 1.5rem;
        }

        &__content {
            padding: 1rem;
        }
    }

}