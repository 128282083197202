body.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Content {
  outline: none;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;

  background-color: $color-overlay;
  z-index: 89;

  .modal-scrollbars {
    cursor: pointer;
    height: 100%;
    .g-scrollbar-container {
      // trick to centering and overflow // do not add justify or align
      overflow: auto !important;
      display: flex;
      flex-flow: column;
    }
  }

  .modal-close-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 2rem;
    right: 2rem;
    padding: 0.5rem;
    height: 3.6rem;
    width: 3.6rem;
    transition: all 0.2s ease-in-out;
    border-radius: 12px;
    cursor: pointer;
    fill: #636b91;
    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
    &:hover {
      fill: white;
    }
  }

  .modal-back-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 2rem;
    left: 4rem;
    padding: 0.5rem;
    height: 3.6rem;
    width: 3.6rem;
    border-radius: 12px;
    cursor: pointer;
    fill: #636b91;
    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
    &:hover {
      fill: white;
      span {
        color: white;
      }
    }
    span {
      margin-left: 5px;
      color: #636b91;
    }
  }

  @include breakpoint("mobile") {
    .modal-scrollbars {
      .g-scrollbar-container {
        display: block;
        width: 100vw;
        height: 100vh;
        padding: 0 !important;
      }
    }
    .modal {
      @include column($justify: center, $align: stretch);
      padding: 20px;
      min-height: 100vh;
      width: 100vw;
      max-width: 100%;
      max-width: none;
      margin: 0;
      border-radius: 0;
    }
  }
}

.modal {
  position: relative;
  // trick to centering and overflow // do not add justify or align
  margin: auto;
  padding: 5rem 1.5rem 2rem;
  max-width: 88rem;
  border-radius: 20px;
  background-color: $color-modal;
  width: 100%;
  cursor: default;

  &.s {
    max-width: 500px;
  }

  &.m {
    max-width: 70rem;
  }

  &.xl {
    max-width: 123.5rem;
    margin-top: 11.2rem;
    margin-bottom: 11.2rem;

    .modal-content {
      min-height: calc(100vh - 40rem);
    }
  }

  &.full {
    width: 100%;
    max-width: 100%;
    padding: 0;

    .modal-content {
      min-height: calc(100vh - 40rem);
    }
  }

  &.IncreaseQuotaModal {
    .error {
      color: $color-red;
      margin-top: 20px;
      margin-bottom: -40px;
      padding: 0 20px;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }

  .modal-header {
    @include columnAlignCenter();
    & + .modal-content {
      margin-top: 20px;

      @include breakpoint('mobile') {
        margin-top: 0;
      }
    }
  }

  .modal-title {
    font-size: 2rem;
    font-weight: $weight-bold;
    margin: 0 0 20px;
    text-align: center;
    &.row {
      @include rowCenter();
      & > * {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  .modal-subtitle {
    font-size: 18px;
    max-width: 80%;
    font-weight: $weight-semi;
    color: $color-font-main;
    margin: 0 auto 20px;
    text-align: center;
    text-transform: capitalize;
    strong {
      color: $color-text-dark;
    }
  }

  .modal-body {
    flex-grow: 1;
    overflow: hidden;
  }

  .modal-content {
    margin: 20px 0 0;
    font-size: 1.4rem;
    font-weight: $weight-medium;

    &.stretch {
      align-items: stretch;
    }
    &.fill-height {
      flex: 1 1 auto;
      max-height: 600px;
    }

    .short-content {
      font-size: 1.6rem;
      font-weight: $weight-semi;
      color: $color-font-sub;
      text-align: center;
      line-height: 2rem;
    }

    .link {
      color: $color-green;
      font-weight: $weight-semi;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .modal-section {
    @include columnCenter($align: center);
    position: relative;
    width: 100%;
    padding-top: 40px;
    border-top: 1px solid $color-border;
    &:not(:first-child) {
      margin-top: 40px;
    }
    &.stretch {
      align-items: stretch;
    }
    &.s {
      padding-top: 20px;
    }
    .modal-section-label {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0 10px;
      letter-spacing: 3px;
      text-transform: uppercase;
      font-weight: $weight-semi;
      font-size: 12px;
      color: $color-font-sub;
    }
  }

  .modal-section-title {
    font-size: 1.4rem;
    color: $color-font-main;
    font-weight: $weight-semi;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 20px;
    text-align: center;
  }
  .modal-section-title + .modal-section-subtitle {
    margin-top: -20px;
  }

  .modal-section-subtitle {
    font-size: 14px;
    color: $color-font-sub;
    font-weight: $weight-semi;
    margin-bottom: 20px;
    text-align: center;
  }

  .modal-actions {
    @include columnAlignCenter();
    margin-top: 60px;
    button {
      min-width: 21rem;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
  .modal-content + .modal-actions {
    margin-top: 0;
  }

  .action-helper {
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
    font-weight: $weight-semi;
    color: $color-font-main;
    text-align: center;
    max-width: 70%;
    &.warning {
      color: $color-red;
    }
    a {
      color: $color-green-hover;
    }
    & > * {
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
    &.sub {
      color: $color-text-dark;
    }
  }

}
