.Metrics {
  display: flex;
  flex-direction: column;
  flex: 1;
  &__content {
    display: flex;
    justify-content: center;
    max-width: 1080px;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    width: 100%;
    padding-bottom: 100px;
  }

  &__title {
    font-size: 30px;
    font-weight: bold;
    line-height: 45px;
    text-align: center;
    margin-bottom: 12px;
    color: #ffffff;
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 12px;
    color: #8a8fb5;
  }

  &__navbar {
    width: 270px;
    padding: 0 15px;

    &--title {
      margin: 45px 0 15px 0;
      padding: 0 20px;
    }
  }

  &__chart {
    flex: 1;
    padding: 0 15px;

    &--container {
      background-color: rgba(#191d29, 0.5);
      padding-top: 30px;
      min-width: 750px;
      min-height: 255px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--header {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 30px;
    }

    &--title {
      text-align: left;
      display: flex;
    }

    &--info {
      text-align: right;
      display: flex;
      margin-left: 60px;
    }

    &--download {
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin-left: 10%;
    }

    &--text {
      margin-bottom: 5px;
      font-size: 14px;
      max-width: 120px;
      margin-left: 10px;
      line-height: 20px;
      font-weight: 600;
      height: 100%;
      text-align: left;

      & p {
        color: #8a8fb5;
      }
    }

    &--value {
      margin-bottom: 5px;
      font-size: 40px;
      height: 100%;
      position: relative;
      color: $color-green;
      border-left: 1px solid #2c2f40;
      padding-left: 20px;

      // & span {
      //   position: absolute;
      //   bottom: 0;
      //   right: 0;

      //   &.left {
      //     left: 0;
      //   }
      // }
    }

    &--filter {
      & .Tabs {
        margin: 2rem 0 0 0;
        background-color: rgba(#191d29, 0.5);
        height: 70px;
      }

      & .Tab {
        display: flex;
        align-items: center;

        &::after {
          bottom: 0;
        }
      }
    }
  }

  &__download {
    &--wrap {
      display: flex;
      flex-direction: column;
      flex-basis: 33%;
      &.button {
        flex-basis: 25%;
      }
      input {
        height: 4rem;
        border-radius: 6px;
        border: 1px solid #3d4463;
        padding: 0 2rem;
        background: #131722;
        font-size: 1.4rem;
        color: white;
        font-weight: $weight-medium;
        &::placeholder {
          color: #636b91;
        }
        &:focus {
          outline: 0;
          border-color: $color-input-focus;
        }
        &:disabled {
          opacity: 0.5;
        }
      }
      .react-datepicker-wrapper {
        // width: 100%;
        input {
          width: 100%;
        }
        font-size: 14px;
      }
    }
    &--label {
      margin-bottom: 10px;
      font-weight: 500;
    }
  }

  &__subbar-wrap {
    // display: none;
  }

  &__leftbar {
    cursor: pointer;
    border-radius: 12px;
    background: #191d29 0% 0% no-repeat padding-box;
    margin: 10px 0;

    &:hover {
      background-color: rgba($color-green, 0.08);
    }
    &.active {
      background: rgba($color-green, 0.08) 0% 0% no-repeat padding-box;

      .Metrics__card-header {
        font-weight: 700;
      }
      .Metrics__card-chart {
        // background-color: #191d29;
      }
      .Metrics__subbar-wrap {
        display: block;
        background: transparent;
      }
    }
  }

  &__subbar-wrap {
    padding: 10px 0;
    background-color: #13161f;
    display: none;
  }

  &__subbar {
    cursor: pointer;
    padding: 17px;
    font-size: 14px;
    min-height: 40px;
    display: flex;
    align-items: center;
    border-left: 3px solid transparent;

    &.active {
      border-left: 3px solid $color-green !important;
      background: rgba($color-green, 0.08) 0% 0% no-repeat padding-box;
      color: $color-green;
      font-weight: 700;
    }

    &:hover {
      background: rgba($color-green, 0.08) 0% 0% no-repeat padding-box;
      border-left: 3px solid $color-green;
      font-weight: 700;
    }
  }

  &__card-header {
    height: 45px;
    padding: 20px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    font-weight: 700;

    &--title {
    }
    &--value {
      font-size: 18px;
    }
  }

  &__card-chart {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

  &__breakdown {
    width: 100%;
    background-color: #13161f;
    display: flex;

    &--navbar {
      border: 1px solid #191d29;
    }

    &--content {
      width: 100%;
      border: 1px solid #191d29;
    }
  }

  &__filter {
    &--header {
      width: 100%;
      display: flex;
      min-height: 60px;
      font-size: 12px;
    }

    &--content {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 50px;
      border-top: 1px solid #191d29;
      border-bottom: 1px solid #191d29;
    }

    &--name {
      flex: 1.5;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid rgba(#3d4463, 0.3);
      color: #8a8fb5;
      font-weight: 600;
    }

    &--title {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;
      flex: 0 0 90px;
      text-transform: uppercase;
      padding: 4px;
      cursor: pointer;
      border-right: 1px solid rgba(#3d4463, 0.3);
      color: #8a8fb5;
      font-weight: 600;
      padding: 20px 20px;

      &:last-child {
        border: none;
      }
      &.lg {
        flex: 0 0 160px;
      }
    }

    &--sort {
      margin-left: 3px;
    }

    &--error {
      color: #3d4463;
      font-size: 24px;
      font-weight: 600;
      margin: 75px 0;
    }
  }

  @include breakpoint('mobile') {
    &__title {
      width: auto;
      text-align: left;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 0;
      margin-bottom: 0;

      .PageTitleBar {
        width: auto;
      }
    }
    &__navbar {
      align-self: center;
      margin-bottom: 2rem;
    }

    &__content {
      padding-top: 0;
      max-width: 100%;
      flex-direction: column;
    }

    &__download {
      &--label {
      }

      &--wrap {

      .Button--medium {
        min-width: auto;
        height: 32px;
      }
      }
    }
    &__chart { 
      padding-left: 0;
      padding-right: 0;

      &--header {
        width: 100%;
        margin-right: 0;
        padding-right: 0;
      }
      &--download {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        gap: 1rem;
      }
      &--container {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

.breakdown-navbar {
  flex: 1;
  min-width: 230px;

  &__title {
    display: flex;
    margin-top: 10px;
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #8a8fb5;
    letter-spacing: 1.65px;
  }
  &__icon {
    height: 20px;
    width: 20px;
    padding: 0 10px;
  }
  &__star {
    height: 20px;
    width: 20px;
    padding: 0 10px;
  }
  &__breakdown {
    padding: 10px;
    display: flex;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    align-items: center;

    &:hover {
      background-color: #191d29;
    }
    &.active {
      background-color: #191d29;
      font-weight: 600;
      color: $color-green;
      border-left: 3px solid #18c99d !important;
    }
  }
}

.arrow-down {
  height: 5px;
  width: 11px;
  background-image: url("../../../assets/icons/ArrowDown.svg");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.arrow-up {
  height: 5px;
  width: 11px;
  background-image: url("../../../assets/icons/ArrowUp.svg");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.arrow-down-up {
  height: 13px;
  width: 11px;
  background-image: url("../../../assets/icons/ArrowDownUp.svg");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
