.RagChatbotDetailPage {
  padding-bottom: 20px;
  flex: 1;

  &.playground {
    max-width: 520px;
    margin-top: -10px;
  }

  &.integration,
  &.knowledge-base-tab {
    max-width: 850px;
    display: flex;
  }

  &.row {
    height: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
  }

  &.knowledge-base-tab {
    flex-direction: column;
  }

  &.settings {
    max-width: 700px;
  }

  &.tools {
    max-width: 850px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__error {
    color: $color-red;
    font-size: 1.4rem;
    margin-bottom: 10px;
    font-weight: $weight-semi;
  }

  &__row {
    width: 100%;
    display: flex;
    margin: 10px 0;
    flex-direction: row;

    @include breakpoint("mobile") {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &.large-gap {
      margin-bottom: 20px;
    }

    &.center {
      justify-content: center;

      .Button--large {
        width: 210px;
        margin: 30px 0;
      }
    }
  }

  &__input-wrap {
    flex: 1;
    padding-right: 20px;

    &:last-child {
      padding-right: 0;
    }

    &.slider {
      flex-basis: 33%;

      .SelectInput {
        width: auto;
      }
    }

    &.slider-empty {
      flex: 40%;
    }

    .ImageInput {
      &__ImageUploaded {
        width: auto;
        height: 100px;
      }
    }
  }

  &__input-label-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }

  &__input-label {
    margin: 10px 0;
    color: #8a8fb5;
    display: flex;
    align-items: center;

    .g-icon {
      margin-left: 5px;
    }

    &--icon {
      cursor: pointer;
    }

    &.collapsible {
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      padding-top: 4rem;

      &:hover {
        color: $color-green;
      }
    }
  }

  &__color-settings-container {
    display: flex;
    flex-direction: column;
    background-color: rgba(#3d4463, 0.2);
    padding: 15px;
    border-radius: 6px;
  }

  &__color-theme-selector {
    width: 100%;
    height: 40px;
    background-color: #191d29;
    border-radius: 6px;
    border: 1px solid #3d4463;
    outline: 0;
    color: #ffffff;
    padding: 0 20px;
    width: 250px;
    font-family: $font-family;

    &::placeholder {
      color: #636b91;
    }

    &:focus {
      border-color: #18c99d;
    }
  }

  &__color-settings {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 15px;
  }

  &__color-picker {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      margin-bottom: 5px;
      color: #8a8fb5;
      font-size: 12px;
      text-transform: capitalize;
    }
  }

  &__color {
    display: flex;
    align-items: center;

    &--swatch {
      padding: 5px;
      background: #fff;
      border-radius: 1px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }

    &--color {
      width: 36px;
      height: 14px;
      border-radius: 2px;
    }

    &--popover {
      position: absolute;
      z-index: 2;
    }

    &--cover {
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }

  &__color-reset {
    margin-top: 15px;
    text-align: right;
  }

  &__color-reset-button {
    background-color: transparent;
    border: 1px solid $color-green;
    color: $color-green;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    transition: all 0.3s ease;

    &:hover {
      background-color: rgba($color-green, 0.1);
    }
  }

  &__input-div {
    min-height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    border: 1px solid #3d4463;
    border-radius: 6px;

    &--info {
      color: #8a8fb5;
      max-width: 520px;
      // box-sizing: border-box;
    }

    &--button {
      color: $color-green;
      width: 80px;
      height: 100%;
      cursor: pointer;
      line-height: 100%;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: rgba($color-green, 0.2);
      }
    }

    &--buttons {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &--icon {
      width: 34px;
      height: 34px;
      cursor: pointer;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: rgba($color-green, 0.1);
      margin-left: 5px;

      &:hover {
        background-color: rgba($color-green, 0.2);
      }
    }

    &--one-line {
      overflow-x: scroll;
      padding: 15px;
    }

    &.token {
      padding: 0;
      padding-left: 15px;
      justify-content: space-between;

      .g-icon {
        fill: $color-green;
      }
    }
  }

  &__playground {
    height: 545px;
    width: 100%;
    border-radius: 12px;
    background-color: #3d4463;
    margin-top: 15px;
  }

  &__tabs {
    width: 13rem;
    display: flex;
    flex-direction: column;

  }

  &__tab {
    cursor: pointer;
    font-size: 1.4rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    border-bottom: 1px solid #3d4463;
    font-weight: $weight-medium;
    color: $color-text-dark;

    &.active {
      color: $color-green;
    }

    &:hover {
      color: $color-green;
    }


    &--spotlight {
      color: $color-green;
      font-size: 9px;
      border: 1px solid $color-green;
      border-radius: 4px;
      padding: 2px 4px;
      margin-left: 2px;
      position: relative;
      top: -8px;
      font-weight: $weight-semi;
      text-transform: uppercase;
    }

  }

  &__tab-content {
    flex: 1;
    padding: 30px 40px;
    max-width: 76rem;
    background: #1A1F2D;
    border-radius: 1.2rem;
    margin-left: 3rem;
  }

  &__tab-content-blank {
    flex: 1;
    max-width: 76rem;
    margin-left: 3rem;
  }

  &__code-content {
    flex: 1;
    padding: 30px 40px;
    border-left: 1px solid #3D4463;
    height: 100%;

    &--title {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
    }

    &--description {
      margin-top: 8px;
      color: #8a8fb5;
      font-size: 14px;
    }

    &--code {
      margin-top: 30px;
      margin-bottom: 40px;
      max-width: 750px;

      &.iframe-code {}
    }
  }

  &__inputs-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
  }

  &__knowledge-base-content {
    flex: 1;
    padding: 24px;
    background-color: #131722;
    min-height: 500px;

    .container {
      height: 100%;
      width: 100%;

      .choose-file-text {
        color: $color-green;
      }
    }
  }

  &__textarea,
  &__input {
    width: 100%;
    height: 111px;
    background-color: #191d29;
    border-radius: 6px;
    border: 1px solid #3d4463;
    outline: 0;
    color: #ffffff;
    padding: 10px;
    resize: none;

    &::placeholder {
      color: #636b91;
    }

    &--large {
      height: 200px;
      resize: vertical;
    }
  }

  &__input {
    height: auto;
  }

  &__slider-wrap {
    height: 40px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    background-color: rgba(#3d4463, 0.2);

    input {
      width: 40px;
      height: 3.4rem;
      text-align: center;
      border-radius: 0.6rem;
      background: transparent;
      border: none;
      color: $color-green;
      font-size: 1.4rem;
      font-family: "Gilroy", -apple-system, system-ui, BlinkMacSystemFont,
        "Helvetica Neue", "Helvetica", sans-serif;
      font-weight: $weight-medium;

      &:focus {
        outline: none;
        border: 1px solid rgba($color-green, 0.6);
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    input[type="range"] {
      -webkit-appearance: none;
      appearance: none;
      background: transparent;
      cursor: pointer;
      flex: 1;

      &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        background: transparent;
        height: 0.2rem;
      }

      &::-moz-range-track {
        -moz-appearance: none;
        background: transparent;
        height: 0.2rem;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        background: $color-green;
        height: 1.5rem;
        width: 1.5rem;
        border: none;
        border-radius: 50%;
        margin-top: -0.6rem;
      }

      &::-moz-range-thumb {
        -moz-appearance: none;
        border: none;
        border-radius: 50%;
        background-color: $color-green;
        height: 1.5rem;
        width: 1.5rem;
      }

      &:focus {
        outline: none;
        border: none;
      }
    }
  }

  &__color {
    display: flex;
    position: relative;

    &--color {
      width: 20px;
      height: 20px;
      border-radius: 10px;
    }

    &--swatch {
      padding: 5px;
      background: transparent;
      border: 1px solid #3d4463;
      border-radius: 15px;
      display: flex;
      align-items: center;
      gap: 5px;
      padding-right: 10px;
      cursor: pointer;
    }

    &--popover {
      position: absolute;
      z-index: 2;
      bottom: 35px;
    }

    &--cover {
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }

  &__color-wrap {
    display: flex;
    align-items: center;
  }

  &__color-reset {
    margin-left: 10px;
    color: #636b91;
    cursor: pointer;

    &:hover {
      color: #8a8fb5;
    }
  }

  &__icon-wrap {
    display: flex;

    .empty-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .g-icon {
      margin-right: 5px;
    }
  }

  &__preset-questions {
    &--wrap {
      // display: flex;
      border: 1px solid #3d4463;
      align-items: center;
      padding: 8px;
      border-radius: 6px;

      .icon-upload-icon {
        height: 20px;
        width: 20px;
        margin-right: 5px;
      }

      input {
        width: 100%;
        height: 40px;
        background-color: #191d29;
        border-radius: 6px;
        border: none;
        outline: 0;
        color: #ffffff;
        padding: 0 20px;
        font-family: $font-family;

        &::placeholder {
          color: #636b91;
        }

        &:disabled {
          cursor: not-allowed;
          color: #636b91;
        }
      }
    }

    &--icon {
      cursor: pointer;
      display: flex;
      color: $color-green;
      align-items: center;
    }
  }

  // New styles for the radio buttons
  &__radio-group {
    display: flex;
    gap: 20px;
    align-items: center;
    height: 20px;

    label {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      color: #8a8fb5;
    }

    input[type="radio"] {
      cursor: pointer;
      accent-color: $color-green;
    }
  }

  &__iframe-integration {
    margin-top: 20px; // Add space between the copy button and inputs

    .RagChatbotDetailPage__input-wrap {
      width: 100%; // Make the input wrapper full width
      padding-right: 0; // Remove right padding

      // Style for TextInput and Select components
      .TextInput {
        input {
          width: 100%; // Make the inputs full width
        }
      }

      // If you want to limit the width of the theme selector
      &:first-child {
        width: 200px; // Adjust this value as needed for the theme selector
      }
    }

    .RagChatbotDetailPage__css-variables {
      margin-top: 20px;

      .RagChatbotDetailPage__input-label {
        margin-bottom: 10px;
      }

      pre {
        margin: 0;
        border-radius: 6px;
      }
    }
  }

  &__questions {
    flex: 1;
  }

  &__question {
    flex: 1;
    margin-bottom: 10px;

    &--wrap {
      width: 100%;
      color: #636b91;
      font-family: $font-family;
      margin-bottom: 10px;
      align-items: center;

      &.warmup {
        display: flex;
        gap: 10px;
        align-items: center;
        height: 30px;

        // Style for the role selector
        .SelectInput {
          width: 150px; // Fixed width for role dropdown
          min-width: 150px;
          margin-bottom: 0;
          height: 30px;
          position: relative;

          input {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            cursor: default;
            caret-color: transparent;
          }
        }

        // Style for the message input
        .RagChatbotDetailPage__question--input {
          flex: 1; // Take remaining space
        }
      }
    }

    &--label {
      width: 100%;
      height: 30px;
      line-height: 30px;
      color: #636b91;
      font-family: $font-family;
    }

    &--input {
      width: 100%;
      height: 40px;
      background-color: #191d29;
      border-radius: 6px;
      border: 1px solid #3d4463;
      outline: 0;
      color: #ffffff;
      padding: 0 20px;
      font-family: $font-family;

      &::placeholder {
        color: #636b91;
      }

      &:focus {
        border-color: #18c99d;
      }
    }
  }

  &__question-container {
    display: flex;
  }

  &__question-icon-container {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__question-delete-icon {
    cursor: pointer;
    height: 26px;
    width: 26px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: rgba($color-red, 0.2);
    }
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin-top: 4rem;
    gap: 2rem;
    color: #636b91;
    font-size: 20px;
    font-weight: 600;
  }

  &__new-document {
    width: 100%;

    &--label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      font-weight: $weight-semi;
      color: #8a8fb5;
    }

    &--button {
      margin-top: 10px;
      min-height: 40px;
    }
  }

  &__documents {}

  &__section {
    margin-bottom: 3rem;
    padding: 2rem;
    background: rgba(61, 68, 99, 0.1); // Slightly visible background
    border: 1px solid rgba(61, 68, 99, 0.3); // Subtle border
    border-radius: 8px;

    &:last-child {
      margin-bottom: 2rem; // Less margin for the last section
    }
  }

  &__section-title {
    font-size: 1.6rem;
    font-weight: 600;
    color: #8a8fb5;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(61, 68, 99, 0.3);
  }

  &__section-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    // Reduce spacing between rows within sections
    .RagChatbotDetailPage__row {
      margin: 5px 0;

      &.large-gap {
        margin-bottom: 15px;
      }
    }
  }

  &__widget-icons {
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
  }

  &__widget-icon {
    flex: 1;
    max-width: 200px; // Add max-width to limit size

    label {
      display: block;
      color: #636b91;
      margin-bottom: 0.5rem;
      font-size: 1.2rem;
    }

    .ImageInput {
      height: 100%;

      &__ImageUploaded {
        height: 80px !important; // Override the default height
        width: auto !important;
      }
    }
  }

  &__widget-extra {
    margin-top: 40px;

    h4 {
      color: $color-text-light;
      margin-bottom: 15px;
      font-size: 15px;
      font-weight: $weight-semi;
    }

    &--command {
      font-size: 14px;
      color: $color-text-dark;
      margin-bottom: 15px;
      margin-top: 25px;
    }

    &--note {
      font-size: 12px;
      font-weight: $weight-medium;
      color: #8a8fb5;
      margin-top: 15px;
    }

    code {
      padding: 2px 6px;
      border-radius: 4px;
      color: $color-green;
      font-family: monospace;
    }
  }

  &__datePicker {
    width: 40%;
    display: flex;
    align-items: center;

    &--label {
      font-size: 16px;
      width: 80px;
    }

    &--button {
      width: 20%;
    }

    .react-datepicker-wrapper {
      width: 70%;
      margin-left: 10px;

      input {
        height: 4rem;
        border-radius: 6px;
        border: 1px solid #3d4463;
        padding: 0 2rem;
        background: #131722;
        font-size: 1.4rem;
        color: white;
        font-weight: $weight-medium;
        width: 100%;

        &::placeholder {
          color: #636b91;
        }

        &:focus {
          outline: 0;
          border-color: $color-input-focus;
        }

        &:disabled {
          opacity: 0.5;
        }
      }

      font-size: 14px;
    }

    @include breakpoint("mobile") {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;

      &--button {
        width: 60%;
      }
    }
  }

  &__prompt-table {
    width: 100%;
    font-size: 14px;
    color: #636b91;
    font-weight: $weight-medium;

    &--header {
      text-align: left;
      font-weight: $weight-medium;
    }

    &--header-row {
      display: flex;
      height: 50px;
      border-bottom: 1px solid #24283b;
      width: 100%;
    }

    &--row {
      display: flex;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #24283b;
      width: 100%;
      transition: height 0.3s ease;

      &.expanded {
        height: auto;
        padding: 15px 0;
        align-items: flex-start;
      }

      & .title {
        color: #ffffff;
      }

      a {
        display: flex;
        align-items: center;
        flex: 1;
        flex-basis: 20%;
      }
    }

    &--cell {
      line-height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 10px;
      cursor: default;

      &.ip {
        flex-basis: 20%;
        white-space: nowrap;
        word-break: break-all;
      }

      &.question,
      &.answer {
        flex-basis: 48%;
        white-space: nowrap;
      }

      &.expand {
        white-space: normal;
        line-height: 20px;
      }
    }

    .expand-button {
      margin: auto;
      padding: 5px 10px;
      // background-color: #24283b;
      color: #ffffff;
      border: none;
      cursor: pointer;
      font-size: 12px;
      border-radius: 4px;
    }
  }

  &__checkbox-wrap {
    label {
      display: flex;
      align-items: center;
      color: #8a8fb5;
      cursor: pointer;
      user-select: none;

      input[type="checkbox"] {
        appearance: none;
        -webkit-appearance: none;
        width: 18px;
        height: 18px;
        border: 1px solid #3d4463;
        border-radius: 4px;
        background: #191d29;
        margin-right: 10px;
        cursor: pointer;
        position: relative;
        transition: all 0.2s ease;

        &:checked {
          background-color: $color-green;
          border-color: $color-green;

          &:after {
            content: '';
            position: absolute;
            left: 5px;
            top: 2px;
            width: 6px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }

        &:hover {
          border-color: $color-green;
        }
      }

      &:hover {
        color: #ffffff;
      }
    }
  }

  &__analytics-disabled {
    background-color: rgba($color-red, 0.1);
    border: 1px solid rgba($color-red, 0.3);
    border-radius: 8px;
    padding: 2rem;
    margin: 2rem 0;
    text-align: center;

    &--title {
      color: $color-red;
      font-size: 1.6rem;
      font-weight: $weight-semi;
      margin-bottom: 1rem;
    }

    &--description {
      color: #8a8fb5;
      font-size: 1.4rem;
    }

    @include breakpoint("mobile") {
      padding: 1.5rem;
      margin: 1.5rem 0;

      &--title {
        font-size: 1.4rem;
      }

      &--description {
        font-size: 1.2rem;
      }
    }
  }

  &__add-custom-tool {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }

  &__tools-loading {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
  }

  &__tools-list {
    flex: 1;
    background: #1A1F2D;
    border-radius: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 1rem;
    min-height: 400px;
    overflow-y: auto;
    scroll-behavior: smooth;

    // Ensure the add button stays at the bottom
    .RagChatbotDetailPage__tools-item--add {
      margin-top: 2rem;
    }
  }

  &__tab-empty {
    text-align: center;
    padding: 40px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    background-color: #1A1F2D;
    border-radius: 1.2rem;

    h3 {
      font-size: 3.4rem;
      font-weight: $weight-bold;
      margin-bottom: 3.4rem;
      color: #3D4463;
    }

    button {
      width: 21rem;
    }

    &-error {
      color: $color-red;
      font-size: 1.4rem;
      font-weight: $weight-semi;
      margin-bottom: 20px;
    }
  }


  &__tools-container {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__tools-header {
    display: flex;
    align-items: center;
    padding: 1rem 3rem;
    height: 40px;
    color: #8a8fb5;
    font-size: 14px;
    font-weight: 500;


    h3 {
      font-size: 3.4rem;
      font-weight: $weight-bold;
      margin-bottom: 3.4rem;
      color: #3D4463;
    }

    p {
      color: #8a8fb5;
      margin-bottom: 20px;
    }
    
    &-name {
      flex: 2;
      margin-right: 1.5rem;
    }

    &-id {
      flex: 1.5;
    }

    &-status {
      flex: 1;
    }

    &-duration {
      flex: 1;
    }

    &-actions {
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .RagChatbotDetailPage__add-button {
        width: 7rem;
        height: 30px;
        min-width: unset;
        padding: 0 2rem;
        border-radius: 1rem;
        font-size: 14px;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__tools-item {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    color: #fff;
    font-size: 14px;
    transition: background-color 0.2s;
    background: #222838;
    border-radius: 0.8rem;
    cursor: pointer;
    color: #8a8fb5;

    &:hover {
      background-color:#3d4463;
    }

    &-name {
      flex: 2;
      font-weight: 500;
      margin-right: 1.5rem;
    }

    &-id {
      flex: 1.5;
    }

    &-status {
      flex: 1;
    }

    &-duration {
      flex: 1;
    }

    &-actions {
      width: 40px;
      display: flex;
      justify-content: center;

      .g-icon {
        fill: #8a8fb5;
      }
    }

    &--add {
      width: 100%;
      background: transparent;
      cursor: default;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: transparent;
      }
      .Button {
        margin: 1rem 0;
      }


    }
  }

  &__toggle-wrapper {
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-left: -2rem;
    margin-right: -2rem;
    margin-top: -1rem;
    height: 50px;
    gap: 2rem;
    color: #fff;
    font-size: 14px;
    transition: background-color 0.2s;
    background: #222838;
    border-radius: 0.8rem;
    cursor: pointer;
    color: #8a8fb5;

  }

  &__tools-pagination {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
  }

  @include breakpoint("mobile") {
    &__input-wrap {
      max-width: 100%;
    }

    &__input-div {
      max-width: 100%;
      width: 100%;

      &--one-line {
        max-width: 100%;
        width: 100%;
        padding: 10px;
        font-size: 1.5rem;
      }
    }

    &.integration {
      flex-direction: column;
    }

    &__section {
      padding: 1.5rem;
      margin-bottom: 2rem;
    }

    &__section-title {
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
    }

    &__widget-icons {
      flex-direction: column;
      gap: 1rem;

      .RagChatbotDetailPage__widget-icon {
        max-width: 100%; // Allow full width on mobile
      }
    }

    &.row {
      flex-direction: column;
      align-items: center;
    }

    &__tabs {
      flex: 1;
      flex-direction: row;
      width: auto;
      overflow-x: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }

    }

    &__tab {
      padding: 1.1rem 1.1rem 2rem 1.1rem;
      border-bottom: none;
      font-size: 1.6rem;
    }

    &__tab-content {
      margin-left: 0;
      flex: 1;
      width: 100%;
    }

    &__tools-item-status, &__tools-header-status, &__tools-item-id, &__tools-header-id {
      display: none;
    }

  }
}