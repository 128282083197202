$color-tooltip: rgba(5, 6, 10, 0.92);
$color-font-main: white;
$layer-tooltip: 200000;

.g-tooltip {
  background-color: $color-tooltip;
  padding: 6px 11px;
  border-radius: 4px;
  font-family: 'Gilroy', 'Gilroy-SemiBold', sans-serif;
  max-width: 40rem;
  color: $color-font-main;
  font-size: 13px;
  font-weight: $weight-bold;

  z-index: $layer-tooltip;

  text-align: center;

  .tooltip-arrow {
    $arrow-width: 18px;
    $arrow-height: 8px;
    position: absolute;
    width: $arrow-width;
    height: $arrow-width;
    &[data-placement*='bottom'] {
      top: 0;
      left: 0;
      margin-top: -$arrow-height;
      width: $arrow-width;
      height: $arrow-height;
      &::before {
        border-width: 0 calc($arrow-width / 2) $arrow-height calc($arrow-width / 2);
        border-color: transparent transparent $color-tooltip transparent;
      }
    }
    &[data-placement*='top'] {
      bottom: 0;
      left: 0;
      margin-bottom: -0.6em;
      width: $arrow-width;
      height: $arrow-height;
      &::before {
        border-width: $arrow-height calc($arrow-width / 2) 0 calc($arrow-width / 2);
        border-color: $color-tooltip transparent transparent transparent;
      }
    }
    &[data-placement*='right'] {
      left: 0;
      margin-left: -0.6em;
      height: $arrow-width;
      width: $arrow-height;
      &::before {
        border-width: calc($arrow-width / 2) $arrow-height calc($arrow-width / 2) 0;
        border-color: transparent $color-tooltip transparent transparent;
      }
    }
    &[data-placement*='left'] {
      right: 0;
      margin-right: -0.6em;
      height: $arrow-width;
      width: $arrow-height;
      &::before {
        border-width: calc($arrow-width / 2) 0 calc($arrow-width / 2) $arrow-height;
        border-color: transparent transparent transparent$color-tooltip;
      }
    }
    &::before {
      content: '';
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
    }
  }
}

.g-tooltip-trigger {
}