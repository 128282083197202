.VolumeModal {
  background-color: #191d29;
  border-radius: 20px;
  padding: 0 4rem 2rem 4rem;

  .modal-header {
    margin-top: 50px;
  }

  .modal-content {
    margin-bottom: 20px;
  }

  &__row {
    width: 100%;
    display: flex;
    margin: 5px 0;
  }

  &__input {
    width: 100%;
    height: 40px;
    background-color: #191d29;
    border-radius: 6px;
    border: 1px solid #3d4463;
    outline: 0;
    color: #ffffff;
    padding: 0 20px;
    font-family: $font-family;

    &::placeholder {
      color: #636b91;
    }

    &:disabled {
      cursor: not-allowed;
      color: #636b91;
      background-color: #1e2231;
    }
  }

  &__select-wrap {
    flex: 1;
    padding-right: 20px;

    &:last-child {
      padding-right: 0;
    }

    &.name,
    &.location,
    &.size {
      flex-basis: 100%;
    }
  }

  &__select-label {
    margin: 10px 0;
    color: #8a8fb5;
    display: flex;
  }

  &__selector {
    width: 100%;
  }

  &__small-selector {
    flex: 0.3 !important;
  }

  &__price-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #8a8fb5;
    font-size: 14px;
    margin-top: 30px;
    margin-bottom: 12px;

    &--price {
      margin-top: 5px;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      font-weight: $weight-semi;
    }

    &--price-sub {
      margin-top: 5px;
      font-size: 14px;
      color: #8a8fb5;
      text-align: center;
    }
  }

  &__error {
    font-size: 16px;
    height: 16px;
    font-weight: $weight-semi;
    color: $color-red;
    margin: 15px 0;
    text-align: center;

    & a {
      color: #18c99d;
      font-weight: $weight-bold;
      cursor: pointer;
    }
  }
}
