.PageTitleBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  width: 100%;

  &__left {
    display: flex;
    justify-content: flex-start;
    flex-basis: 195px;

    @include breakpoint('mobile') {
      flex-basis: 100px;
    }
  }

  &__title {
    font-size: 3.2rem;
    line-height: 4rem;
    font-weight: $weight-bold;
    text-align: center;
    color: #ffffff;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;

    &:first-letter {
      text-transform: uppercase;
    }

    &.alignLeft {
      text-align: left;
    }

    input {
      font-family: $font-family;
      border: none;
      border-bottom: 1px solid $color-input-border;
      font-size: 30px;
      font-weight: $weight-semi;
      color: $color-input-text;
      text-align: center;

      background-color: $color-input-background;
      &:focus {
        outline: 0;
        border-color: $color-input-focus;
      }
      &[disabled] {
        color: rgba($color-input-text, 0.4);
        //background-color: rgba($color-input-background, .60);
      }
      &::placeholder {
        color: $color-input-placeholder;
        font-weight: $weight-semi;
        text-align: center;
        opacity: 0.6;
      }
    }
  }

  &__titleWithIcon {
    display: flex;
    gap: 1rem;

    .g-icon {
      margin-top: -1rem;
    }
    svg {
      width: 3.2rem;
      height: 1.5rem;
      fill: $color-font-sub;
    }
  }


  &__right {
    display: flex;
    justify-content: flex-end;
    flex-basis: 195px;
    align-items: center;

    @include breakpoint('mobile') {
      flex-basis: 100px;
    }

    &--icon {
      cursor: pointer;
      &.edit {
        color: #8A8FB5;
        &:hover {
          color: white;
        }
      }
      &.cancel {
        margin-right: 10px;
        color: rgba(#8A8FB5, 0.5);
        &:hover {
          color: #8A8FB5;
        }
      }
      &.submit {
        color: rgba(#18c99d, 0.5);
        &:hover {
          color: #18C99D;
        }
      }
    }
  }

  @include breakpoint('mobile') {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    justify-content: flex-start;

    &__left {
      flex-basis: auto;
    }

    &__right {
      flex-basis: auto;
    }

    &__title {
      font-size: 2.8rem;
    }
  }
}
