.AutoRechargeModal {
  background-color: #191d29;
  border-radius: 20px;
  padding: 0 4rem 2rem 4rem;
  .modal-header {
    margin-top: 50px;
  }
  .modal-content {
    margin-bottom: 20px;
  }
  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  &__half-section {
    flex: 1;
    border-right: 1px solid #24283b;
    max-width: 60%;
    &.disabled {
      cursor: default;
      opacity: 0.7;
    }
    &:last-child {
      border-right: none;
    }
    &--title {
      font-weight: $weight-bold;
      font-size: 16px;
    }
  }
  &__input-section {
    padding-left: 35px;
    margin-bottom: 30px;
  }
  &__input-wrap {
    margin: 10px 0;
    display: flex;
    border-radius: 6px;
    border: 1px solid #3d4463;
  }
  &__input-label {
    font-size: 14px;
    color: #8a8fb5;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #3d4463;
  }
  &__input-title {
    font-size: 14px;
    color: white;
    &.red {
      color: #ff3056;
    }
  }
  &__input-tooltip {
    font-size: 12px;
    color: #8a8fb5;
  }
  &__row {
    width: 100%;
    display: flex;
    margin: 5px 0;
    &:last-child {
      margin-bottom: 30px;
    }
  }
  &__input {
    width: 100%;
    height: 40px;
    background-color: #191d29;
    border-radius: 6px;
    border: none;
    outline: 0;
    padding: 0 10px;
    font-size: 18px;
    color: #18c99d;
    &::placeholder {
      color: #636b91;
    }
  }

  &__input-selection-wrap {
    margin: 10px 0;
  }

  &__switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 22px;
    &--wrap {
      display: flex;
      margin-top: 35px;
    }
    &--label {
      flex: 1;
      margin: 0 20px;
      line-height: 20px;
      font-size: 16px;
      color: #8a8fb5;
    }
  }

  &__switch-input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1f2331;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  &__switch-slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: #8a8fb5;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  &__switch-input:checked + .AutoRechargeModal__switch-slider {
    background-color: rgba(#18c99d, 0.1);
  }

  &__switch-input:focus + .AutoRechargeModal__switch-slider {
    box-shadow: 0 0 1px rgba(#18c99d, 0.1);
  }

  &__switch-input:checked + .AutoRechargeModal__switch-slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    background-color: #18c99d;
  }

  &__error {
    font-size: 16px;
    height: 16px;
    font-weight: $weight-semi;
    color: $color-red;
    margin-top: 15px;
  }
}
