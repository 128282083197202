.Checkbox {
  label {
    display: flex;
    align-items: center;
    color: #8a8fb5;
    cursor: pointer;
    user-select: none;

    input[type="checkbox"] {
      appearance: none;
      -webkit-appearance: none;
      width: 18px;
      height: 18px;
      border: 1px solid #3d4463;
      border-radius: 4px;
      background: #191d29;
      margin-right: 10px;
      cursor: pointer;
      position: relative;
      transition: all 0.2s ease;

      &:checked {
        background-color: $color-green;
        border-color: $color-green;

        &:after {
          content: '';
          position: absolute;
          left: 5px;
          top: 2px;
          width: 6px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }

      &:hover {
        border-color: $color-green;
      }
    }

    &:hover {
      color: #ffffff;
    }
  }
} 