.SubscribePage {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: calc(1280px + 24px);
    padding-left: 12px;
    padding-right: 12px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    width: 100%;
    padding-bottom: 100px;
  }
}
