.g-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--overlay-bg);
  z-index: 498;
  @include columnCenter();

  .overlay-shade {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &.absolute {
    position: absolute;
  }
  &.under-header {
    top: 6rem;
    .overlay-shade {}
  }
}