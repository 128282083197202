.g-enable-two-fa {
  @include column($align: center);
  justify-self: center;
  font-size: 1.4rem;
  font-weight: $weight-semi;

  .g-settings-box {
    margin-bottom: 3rem;
  }

  .setup-two-fa-wrapper {
    @include column($align: center);

    .setup-app-desc {
      margin-bottom: 2rem;
    }

    .qr-code-container {
      align-self: center;
      margin-bottom: 2rem;

      .qr-code-image {
        width: 14rem;
        height: 14rem;
      }
    }

    .init-code-wrapper {
      @include row($align: center, $justify: center);
      align-self: center;
      margin-bottom: 2rem;

      .init-code {
        @include row($align: center);
        height: 4rem;
        padding: 1rem 2rem;
        border: 2px $color-content-background solid;
        border-right: none;
        border-top-left-radius: 0.8rem;
        border-bottom-left-radius: 0.8rem;
      }

      .copy-code {
        @include row($align: center, $justify: center);
        height: 4rem;
        width: 12rem;
        padding: 1rem 2rem;
        background: $color-content-background;
        border-top-right-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
        cursor: pointer;

        &:hover {
          background: rgba($color-content-background, 0.8);
        }

        &.copied {
          background: $color-green;
          color: white;
        }

        .checkmark-copy-icon {
          fill: white;
          margin-right: 0.6rem;
        }


      }
    }
    .get-code-desc {
      margin-top: 2rem;
    }

    .two-fa-code-input-wrapper {
      margin-top: 2rem;
      align-self: center;
      .two-fa-code-input {
        input {
          text-align: center;
        }
      }
    }

    .submit-setup-2-fa-code {
      margin-top: 4rem;
      align-self: center;
    }
  }

  .manage-two-fa-wrapper {
    @include column();

    .on-done-btn {
      margin-top: 3rem;
    }
  }

  .save-recovery-codes-wrapper {
    @include column($align: center);

    .recovery-codes-description {
      margin-bottom: 3rem;

      &.red {
        color: $color-red;
      }
    }

    .recovery-codes-wrapper {
      @include row();
      align-self: center;
      flex-wrap: wrap;
      margin-bottom: 3rem;
      background: $color-content-background;
      padding: 2rem;
      width: 70%;

      .recovery-code-item {
        width: 50%;
        text-align: center;
        margin-bottom: 0.4rem;
      }
    }

    .submit-setup-2-fa-code {
      margin-top: 2rem;
      align-self: center;
    }
  }

  @include breakpoint('mobile') {
    max-width: none;
    padding-left: 1rem;
    padding-right: 1rem;
  }

}
