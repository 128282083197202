.SelectInput {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  position: relative;
  width: 25rem;

  select {
    appearance: none;
    background-color: $color-background;
    border: 1px solid $color-content-background;
    border-radius: 0.6rem;
    color: $color-font-sub;
    font-size: 1.4rem;
    font-weight: $weight-medium;
    padding: 1.2rem 2rem 1.2rem 2rem;
    width: 100%;
    cursor: pointer;

    &:focus, &:hover {
      border-color: $color-green;
      outline: none;
    }

  }

  .dropdown-icon {
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    height: 100%;
    right: 0.5rem;
    fill: #8A8FB5;
    width: 2.8rem;
  }

  .option-icon {
    position: absolute;
    right: 0.7rem;
    bottom: 0.9rem;
  }
}