.Banner {
  display: flex;
  height: 50px;
  font-size: 16px;
  background-color: #ff3056;
  align-items: center;
  &__icon,
  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
  }
  &__icon {
    margin-right: 3px;
  }
  &__close {
    margin-right: 20px;
    cursor: pointer;
    color: rgba(#ffffff, 0.8);
    &:hover {
      color: #ffffff;
    }
    & .g-icon {
      fill: #ffffff;
      &:hover {
        fill: rgba(#ffffff, 0.8);
      }
    }
  }
  &__content {
    flex: 1;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    & .Button {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
