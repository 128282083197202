.g-side-menu {
  position: fixed;
  top: 6rem;
  left: 0;
  bottom: 0;
  transform: translate(-100%, 0);
  transition: all .2s;
  width: 90%;
  max-width: 30rem;
  background-color: $color-background;
  z-index: 700;
  border-right: 1px solid $color-border;

  @include breakpoint('mobile') {
    width: 100%;
    max-width: 100%;
    top: 0;
    z-index: 801;
  }

  &.open {
    transform: translate(0%, 0);
  }

  .close-icon-wrapper {
    height: 5.5rem;
    position: relative;
  }

}

.g-side-menu-item {
  display: block;
  padding: 2.2rem 0 2rem 0;
  position: relative;
  margin-left: 3rem;
  z-index: 700;

  font-size: 2rem;
  font-weight: $weight-semi;
  text-transform: capitalize;
  
  color: $color-font-sub;
  
  cursor: pointer;
  @include column($align: left);

  &:hover {
    color: white;
  }

  .border {
    position: absolute;
    display: inline-block;
    height: 1px;
    width: 2rem;
    border-bottom: 2px solid $color-green;
    bottom: 0.5rem;
    margin-left: 0.3rem;
  }



  @include breakpoint('mobile') {
    font-size: 2.5rem;
    padding: 2.2rem 0 3rem 0;

    .border {
      bottom: 1.5rem;
    }

  }

  &.current {
    color: white;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.section {
    //border-top: 1px solid $color-line;
    margin-top: 5rem;
    padding-top: 3rem;

  }

  &.secondary {
    font-size: 2rem;
    &:last-child {
      margin-bottom: 3rem;
    }

    @include breakpoint('mobile') {
      font-size: 2.5rem;
    }
  }
  &.title {
    pointer-events: none;

  }
  &.selected {
    pointer-events: none;
    opacity: 0.5;
  }
  .current {
    text-transform: lowercase;
  }

}

.g-side-menu-item-with-bg {
  display: flex;
  padding: 1.7rem 0 1.7rem 0;
  font-size: 2.3rem;
  font-weight: $weight-semi;

  .side-menu-content {
    @include row($align: center, $justify: flex-start);
    padding: 0.8rem 2.2rem 0.8rem 1.7rem;
    border-radius: 0.7rem;

    &.green {
      color: $color-green;
      background: rgba($color-green, 0.2);

      .g-icon {
        fill: $color-green;
      }
    }

    &.selected {
      opacity: 0.85;
    }

    &:hover {
      //color: rgba($color-blue-alt, 0.8);
      //background: rgba($color-blue-alt, 0.18);
      opacity: 0.9;
    }

    .g-icon {
      margin-right: 1.1rem;
      padding: 0;
    }

    .play-alt {
      width: 1.2rem;
      height: 1.4rem;
    }

  }

}

.bottom-section {
  position: relative;
  justify-self: end;
  bottom: 2rem;
  margin-right: 2rem;
  margin-left: 2rem;
  .social-icons {
    @include row();
    margin-bottom: 8rem;
    @include breakpoint('mobile') {
      .g-icon {
        width: 4rem;
        height: 4rem;
      }
    }

    a {
      margin-right: 4rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .g-logo.tn-logo {
    width: 12.4rem;
    height: auto;
    margin-bottom: 3.6rem;

    @include breakpoint('mobile') {
      width: 16rem;
    }
  }

  .theta-legal {
    @include row( $align: flex-end);

    a {
      display: inline-block;
      font-weight: $weight-semi;
      margin-right: 2rem;
      color: $color-font-sub;
    }
  }
  .copyright {
    display: inline-block;
    font-weight: $weight-semi;
    margin-right: 2rem;
    color: $color-font-sub;
  }
}

.sidemenu-wrap {
  @include column($justify: space-between, $align: stretch);
  height: 100%;
}

.sidemenu-header {
  display: flex;
  align-items: center;
  height: 7rem;
  border-bottom: 1px solid $color-border-bis;
  padding-left: 2rem;
  padding-right: 1.5rem;
  
  .close-icon {
    padding-right: 1.6rem;
    cursor: pointer;
    fill: $color-font-sub;

    svg {
      width: 21px;
      height: 21px;
      padding: 2px;
    }
  }

  .Navbar__logo {
    height: 3rem;
    cursor: pointer;
    
  }
}