.ModelExplorerPage {
  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    // & > :nth-child(3n) {
    //   margin-right: 0;
    // }
  }

  &__section {
    flex-basis: 30%;
    background-color: #1f2331;
    border-radius: 14px;
    padding: 0 26px;
    cursor: pointer;
    // margin-right: 5%;
    margin-bottom: 30px;
    position: relative;
    min-width: 300px;
    border: 1px solid #1f2331;

    &:after {
      content: "";
      color: #1f2331;
      font-weight: 600;
      font-size: 11px;
      position: absolute;
      top: 0;
      left: 26px;
      width: 52px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 0 6px 6px;
    }

    &.new {
      border: 1px solid #18c99d;

      &::after {
        content: "NEW";
        background-color: #18c99d;
      }
    }

    &.hot {
      border: 1px solid #ff3056;

      &::after {
        content: "HOT";
        background-color: #ff3056;
      }
    }

    &:hover {
      background-color: #282d3c;
    }

    &--title {
      margin-top: 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70px;
    }

    &--logo {
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;

      &.stable-diffusion {
        background-image: url("../../../../assets/icons/icon-stable-diffusion@2x.png");
        height: 54px;
        width: 54px;
      }

      &.llama-2,
      &.llama-3,
      &.codellama {
        background-image: url("../../../../assets/icons/icon-llama2.png");
        height: 42px;
        width: 62px;
      }

      &.hugginggpt {
        background-image: url("../../../../assets/icons/icon-hugginggpt@2x.png");
        height: 50px;
        width: 54px;
      }

      &.visual-chatgpt,
      &.whisper {
        background-image: url("../../../../assets/icons/icon-visual-chatgpt@2x.png");
        height: 53px;
        width: 53px;
      }

      &.baize-7b {
        background-image: url("../../../../assets/icons/icon-baize-7b@2x.png");
        height: 65px;
        width: 53px;
      }

      &.gemma {
        background-image: url("../../../../assets/icons/icon-gemma.png");
        height: 64px;
        width: 64px;
      }

      &.mistral-7b {
        background-image: url("../../../../assets/icons/icon-mistral.svg");
        height: 64px;
        width: 64px;
      }

      &.sketch-to,
      &.image-to,
      &.talking-head {
        background-image: url("../../../../assets/icons/icon-theta-ai.svg");
        height: 56px;
        width: 56px;
      }

      &.stableviton {
        background-image: url("../../../../assets/icons/icon-kaist.svg");
        height: 64px;
        width: 102px;
      }

      &.gemma-2b {
        background-image: url("../../../../assets/icons/icon-gemma.svg");
        height: 64px;
        width: 64px;
      }

      &.deepseek-r1 {
        background-image: url("../../../../assets/icons/icon-deepseek.svg");
        height: 64px;
        width: 64px;
      }
      
      &.flux-1-schnell {
        background-image: url("../../../../assets/icons/icon-flux.svg");
        height: 64px;
        width: 64px;
      }
    }

    &--name {
      font-size: 20px;
      font-weight: $weight-bold;
      flex: 1;

      &.custom {
        margin-top: 35px;
      }
    }

    &--time {
      font-size: 14px;
      font-weight: $weight-medium;
      color: #8a8fb5;
      margin-top: 8px;
    }

    &--tags {
      margin-top: 20px;
      display: flex;
      min-height: 28px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    &--tag {
      height: 28px;
      padding: 0 15px;
      border-radius: 14px;
      font-size: 13px;
      font-weight: $weight-medium;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      margin-bottom: 10px;
      color: #8a8fb5;
      background-color: rgba(#8a8fb5, 0.1);

      &.text2image {
        color: #18c9c6;
        background-color: rgba(#18c9c6, 0.1);
      }

      &.gradio {
        color: #ff42dc;
        background-color: rgba(#ff42dc, 0.1);
      }

      &.api {
        color: #009dff;
        background-color: rgba(#009dff, 0.1);
      }

      &.webui {
        color: #fcee21;
        background-color: rgba(#fcee21, 0.1);
      }

      &.llm {
        color: #4271fc;
        background-color: rgba(#4271fc, 0.1);
      }

      &.moe {
        color: #a145ff;
        background-color: rgba(#a145ff, 0.1);
      }

      &.codegen {
        color: #ff7d26;
        background-color: rgba(#ff7d26, 0.1);
      }

      &.imagegen {
        color: #ff3030;
        background-color: rgba(#ff3030, 0.1);
      }

      &.speechrec {
        color: #14b52a;
        background-color: rgba(#14b52a, 0.1);
      }

      &.videogen {
        color: #e06ecd;
        background-color: rgba(#e06ecd, 0.1);
      }

      &._3dgen {
        color: #9be622;
        background-color: rgba(#9be622, 0.1);
      }
    }

    &--description {
      margin-top: 20px;
      margin-bottom: 15px;
      color: #8a8fb5;
      height: 200px;
    }

    &--add {
      height: 290px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 100px;
      font-weight: 400;
      color: #18c99d;
    }
  }

  &__pagination {
    margin-bottom: 50px;

    & .pagination .btn {
      font-size: 16px;
    }
  }

  @include breakpoint('mobile') {
    &__row {
      justify-content: center;
      flex-direction: column;
    }
    &__section {
      &--description {
        font-size: 1.6rem;
        line-height: 1.8rem;
        height: auto;
        margin-bottom: 6rem;
      }
    }
  }
}