.LoginPage{
  display: flex;
  flex-direction: column;
  flex: 1;

  &__content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: calc(1280px + 24px);
    padding-left: 12px;
    padding-right: 12px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 90px;
    padding-bottom: 100px;
  }

  &__title{
    font-size: 30px;
    font-weight: bold;
    line-height: 45px;
    text-align: center;
    margin-bottom: 12px;
    color: #ffffff;
  }

  .LoginForm{
    width: 42rem;
    margin-top: 3rem;

    @include breakpoint('mobile') {
      min-width: unset;
      width: calc(100vw - 24px);
    }
    
    .Button {
      margin-left: 2rem;
      margin-right: 2rem;
      margin-top: 2rem;

    }
  }

  .g-recaptcha{
    margin: 8px auto 20px;
  }

  &__already-have{
    font-size: 14px;
    color: white;
    margin-top: 20px;

    a{
      color: $color-green;
      font-weight: 600;
    }
  }

}

.twofa-message {
  color: #8a8fb5;
  font-weight: 600;
  font-size: 17px;
  margin: 20px 0 15px 0;
  text-align: center;
  max-width: 420px;
}

.reset-password-wrap {
  display: flex;
  justify-content: center;

  & span {
    cursor: pointer;
    font-size: 1.3rem;
    text-decoration: underline;
    color: $color-font-sub;
    font-weight: $weight-medium;

    &:hover {
      color: $color-green;
    }
  }
}