.SettingsPage {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: calc(1280px + 24px);
    padding-left: 12px;
    padding-right: 12px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    width: 100%;
    padding-bottom: 100px;
  }

  .SettingsSection{
    display: flex;
    flex-direction: column;
    min-width: 460px;
    background-color: #191D29;
    border-radius: 8px;
    padding: 20px;

    &__title{

    }

    &__subtitle{
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      text-align: center;
      margin-bottom: 34px;
      color: $color-text-dark;
    }
  }

  .SettingsSectionRow{
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    &:last-of-type{
      margin-bottom: 0;
    }

    &__title{
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      margin-bottom: 8px;
      color: $color-text-dark;
    }
  }
}
