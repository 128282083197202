.DashboardPage {
  width: 100%;

  &__Content {
    @include row($justify: center);
    width: 100%;
    margin-top: 13rem;
    flex-wrap: wrap;
  }

  @include breakpoint('mobile') {
    &__Content {
      margin-top: 4rem;
    }
  }
}

.DashboardPageItem {
  @include column($align: center);
  margin: 0 2rem 2rem;
  cursor: pointer;
  text-align: center;
  position: relative;

  &.disabled {
    cursor: default;
  }

  &__IconContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 16.9rem;
    width: 3.8rem;
    height: 3.8rem;
    background: $color-green;
    border-radius: 8px;

    &.disabled {
      background: #1F2331;

      .g-icon {
        fill: #5A6285;
      }
    }

    .icon-ai {
      width: 1.5rem;
      height: 1.2rem;
      svg {
        width: 1.5rem;
        height: 1.2rem;
      }
    }

    .icon-rendering {
      width: 2rem;
      height: 2rem;
      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    .icon-video {
      width: 2.4rem;
      height: 2.4rem;
      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

  }

  &__Title {
    margin-top: 4rem;
    font-size: 1.6rem;
    font-weight: $weight-semi;
  }
}
