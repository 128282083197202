.LandingPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    min-height: 860.5px;
    margin: 1rem 2rem 2rem;
    position: relative;

    .bg-image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 1rem;
      transition: opacity 0.5s linear;
      background: no-repeat center right;
      background-size: cover;
      z-index: -1;
    }

    .badges-list {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 1rem;
      left: 9.3rem;
      right: 9.3rem;
      top: 4.55rem;

      .badge {
        padding: 1.3rem 1.9rem;
        border-radius: 0.8rem;
        background: rgba(white, 0.1);
        font-size: 1.4rem;
        font-weight: $weight-semi;
        color: rgba(white, 0.6);
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          background: rgba(white, 0.2);
        }
      }
    }

    .landing-title {
      font-size: 5.6rem;
      font-weight: $weight-light;
      color: white;
      margin-left: 9.3rem;
      text-transform: uppercase;
      margin-bottom: 3.7rem;
    }

    .landing-desc {
      font-size: 2.6rem;
      font-weight: $weight-semi;
      color: white;
      opacity: 0.6;
      margin-left: 9.3rem;
      margin-bottom: 7.5rem;
    }

    .Button {
      margin-left: 9.3rem;
      width: 23rem;
      height: 5.2rem;
      font-size: 1.8rem;
      margin-bottom: 5rem;

    }


  }

  @include breakpoint('mobile') {

    .main-wrapper {
      align-items: center;
      min-height: auto;
      height: calc(100vh - 10rem);

      .bg-image {
        background: no-repeat center right;
        opacity: 0.3;

        &.home{
          background-image: url('../../../../public/images/dashboard/homepage_05.jpg');
        }
      }

      .badges-list {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        left: 2rem;
        right: 2rem;

        .badge {
          font-size: 1.6rem;
          background: $color-content-background;

          &:hover {
            background: rgba($color-input-background-alt, 1);
        }
      }
        }


      .landing-title {
        font-size: 3.2rem;
        text-align: center;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 2.5rem;
      }

      .landing-desc {
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 2rem;
        text-align: center;

      }

      .Button {
        width: 23rem;
        height: 5.2rem;
        font-size: 1.8rem;
        margin-left: 0;
        margin-bottom: 5rem;

      }
      .warning-title {
        font-size: 3rem;
        line-height: 3.5rem;
        text-align: center;
        margin-bottom: 10rem;
      }
      .warning-link {
        display: flex;
        flex-direction: column;
        font-size: 2.5rem;
        justify-content: center;
        align-items: center;
        .Button {
          margin-top: 3rem;
        }
      }
    }
  }
}